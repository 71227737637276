import React, { useState, useEffect, useContext } from 'react';
import './index.css'
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'
import { SpinnerCircular } from 'spinners-react';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import AmChart from './amhart'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import Amchart1 from './Amchart1'
import { Button, Modal, Tabs, Tab } from 'react-bootstrap'
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleLogin } from 'react-google-login';
import { StoreContext1 } from '../../useContext/pathContext'
import { render } from '@testing-library/react';
import Countdown1 from "react-countdown"
import Vedio from '../../assets/Neon_Globe_ul6jvk.mp4'
import $ from 'jquery'
import dave from './idoimages/dave_r7cvet.png'
import chad from './idoimages/chad_d6ktci.png'
import deepak from './idoimages/deepak_t9hzad.png'
import imagem from './idoimages/image_mnnjbo.png'
import tony from './idoimages/tony-lustig_mzn0k5.jpg'
import abhi from './idoimages/abhi_giggoh.jpg'
import vi from './idoimages/vi_stzeqf.png'
import kim from './idoimages/kim_a6n35n.png'
import erin from './idoimages/erin_amu4yx.png'
import amy from './idoimages/amy_waci4k.png'
import patrick from './idoimages/patrick_aerjoh.png'
import jessica from './idoimages/jessica_jlegyj.jpg'

import p from './idoimages/p_fsbigz.png'
import lan from './idoimages/lan_uwzqow.jpg'
import scott from './idoimages/scott_tc4kgf.jpg'
import homebrew from './idoimages/homebrew_zcyym6.png'

import footerb from './idoimages/footer-bg_nfkiqs.png'
import ReactPlayer from 'react-player'


const eye = <FontAwesomeIcon icon={faEye} />;

function Index() {
    const context1 = useContext(StoreContext1)

    const [show, setShow] = useState(false);
    const [ModalTabs, setModaltabs] = useState('')
    const [toggleModal, SetTogglemodal] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [email1, setEmail1] = useState('')
    const [password1, setPassword1] = useState('')
    const history = useNavigate()
    const location = useLocation()
    const [passwordShownconfirm, setPasswordShownconfirm] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [remember, setRemember] = useState(false)
    const [username, setUsername] = useState('')
    const [fullname, setFullname] = useState('')
    const [referralCode, setReferralCode] = useState('')
    const [prototype, setPrototype] = useState(false)
    const [tmecounter, setTmecounter] = useState('')



    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setModaltabs(id)
        setShow(true)
    }
    const togglePasswordVisiblityconfirm = () => {
        setPasswordShownconfirm(passwordShownconfirm ? false : true);
    };

    useEffect(async () => {

        let userRole

        const email = localStorage.getItem('email')
        const password = localStorage.getItem('password')
        if (location.pathname.includes('referral')) {

            await sessionStorage.setItem('referralcode', location.pathname.split('/').pop())
            window.location.reload()
        }

        userRole = window.sessionStorage.getItem("role")

        if (userRole == 'admin') {
            history('/index')
        } else if (userRole == 'user') {
            history('/home')
        } else {
            history(location.pathname)
        }
        setEmail(email)
        setPassword(password)

        if (sessionStorage.getItem('referralcode')) {
            setReferralCode(sessionStorage.getItem('referralcode'))
        }
        gettimecounter()

        window.$('.popup-video').magnificPopup({
            type: 'iframe',
            midClick: true
        });

    }, [])
    const onsubmitedform = (e) => {
        e.preventDefault()
        const data = {
            email: email,
            password: password
        }
        /*   remember ? (localStorage.setItem('email', email), localStorage.setItem('password', password)) : null
   */
  if(remember){
    localStorage.setItem('email', email)
    localStorage.setItem('password', password)
  }

        setLoading(true)
         axios.post(`${BASE_URL}/api/login-ico`, data).then((res) => {
        //    axios.post('https://api987api7.mambapayments.com/api/login-ico', data).then((res) => {
            if (res?.data?.user?.AccountStatus === "Suspend") {
                NotificationManager.error("Your Account Suspended")
            } else if (res?.data?.user?.role === 'admin') {
                setLoading(false)
                history('/index')
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();
            } else if (res?.data?.user?.TwoAuthEnabled === true) {
                window.sessionStorage.setItem("index", res?.data?.user?._id ?? "")
                setLoading(false)
                history('/Twoauth')
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();

            } else if (res?.data?.user?.AccountVerify === false) {
                window.sessionStorage.setItem("index", res?.data?.user?._id ?? "")
                history('/VerifyEmail')
            } else {
                setLoading(false)

                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/home')
                // window.location.reload();

            }


        }).catch((error) => {

            setLoading(false)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    const onegistration = (e) => {
        e.preventDefault()
        if (referralCode === '') {
            onsubmitedformregistration()

        } else {
            onsubmitedformReferral()

        }
    }

    const onsubmitedformregistration = () => {
        // e.preventDefault()
        const data = {
            userName: username,
            fullName: fullname,
            email: email1,
            password: password1
        }
        /*   remember ? (localStorage.setItem('email', email1), localStorage.setItem('password', password1)) : null */
        setLoading1(true)
        // axios.post(`${BASE_URL}/api/registration-ico`, data).then((res) => {
        axios.post(`${BASE_URL}/api/registration-ico`, data).then((res) => {
            history('/VerifyEmail')
            setLoading1(false)
        }).catch((error) => {

            setLoading1(false)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }


    const onsubmitedformReferral = () => {
        // e.preventDefault()
        const data = {
            userName: username,
            fullName: fullname,
            email: email1,
            password: password1
        }
        setLoading1(true)
        axios.post(`${BASE_URL}/api/registration-referrals/${referralCode}`, data).then((res) => {


            history('/VerifyEmail')
            setLoading1(false)
        }).catch((error) => {

            setLoading1(false)
            NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }

    const responseGoogle = async (response) => {

        const tokendata = {
            tokenId: await response.tokenId
        }


        axios.post(`${BASE_URL}/api/loginwithGoogle`, tokendata).then((res) => {


            if (res?.data?.user?.AccountStatus === "Suspend") {
                NotificationManager.error("Your Account Suspended")
            } else if (res?.data?.user?.role === 'admin') {
                setLoading(false)
                history('/index')
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();
            } else if (res?.data?.user?.TwoAuthEnabled === true) {
                setLoading(false)
                window.sessionStorage.setItem("index", res?.data?.user?._id ?? "")
                history('/Twoauth')
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();

            } else if (res?.data?.user?.KycForm === true) {
                setLoading(false)
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/home')
                // window.location.reload();

            } else {
                setLoading(false)
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/KycApplication')
                window.location.reload();

            }


        }).catch((error) => {
            console.log(error)
            NotificationManager.error(error?.response?.data?.error ?? "Connection Failed")

        })
    }

    const responseGoogleFailure = (error) => {
        console.log("error", error)
    }
    /******************************** timeCounter *************************************** */
    const gettimecounter = () => {
        axios.get(`${BASE_URL}/api/gettimeCouter`).then((res) => {

            setTmecounter(res.data.result[0].timeCounter)
        }).catch((error) => {
            console.log(error)
        })
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {

        if (completed) {
            return (
                <div class="clock-countdown">
                    <div class="site-config" data-date="02/11/2022 08:00:45" data-date-timezone="+0"></div>
                    <div class="coundown-timer d-flex justify-content-center">
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="days count-time gradient-color">00</span></h4><span
                                class="normal">Days</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="hours count-time gradient-color">00</span></h4><span
                                class="normal">Hours</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="minutes count-time gradient-color">00</span></h4><span
                                class="normal">Minutes</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="seconds count-time gradient-color">00</span></h4><span
                                class="normal">Seconds</span>
                        </div>
                    </div>
                </div>

            )

        } else {
            return (
                <div class="clock-countdown">
                    <div class="site-config" data-date="02/11/2022 08:00:01" data-date-timezone="+0"></div>
                    <div class="coundown-timer d-flex justify-content-center">
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="days count-time gradient-color">{days}</span></h4><span
                                class="normal">Days</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="hours count-time gradient-color">{hours}</span></h4><span
                                class="normal">Hours</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="minutes count-time gradient-color">{minutes}</span></h4><span
                                class="normal">Minutes</span>
                        </div>
                        <div class="single-counter  d-flex flex-column">
                            <h4><span class="seconds count-time gradient-color">{seconds}</span></h4><span
                                class="normal">Seconds</span>
                        </div>
                    </div>
                </div>
            )

        }
    }
    /********************************End timeCounter *************************************** */



    return <div>
        <body class="body_06">

            <div id="loader-wrapper">
                <div id="loader"></div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
            <div class="icon-bar">
                {/*  <a href="https://www.facebook.com/MambaSocial/" class="facebook"><i class="fab fa-facebook" style={{ color: "rgb(255, 0, 255)" }}></i></a> */}
                <a href="https://twitter.com/mambasocial" class="twitter"><i class="fab fa-twitter" style={{ color: "rgb(255, 0, 255)" }}></i></a>
                {/* <a href="https://t.me/MambaVerse" class="google"><i class="fab fa-telegram" style={{ color: "rgb(255, 0, 255)" }}></i></a> */}
                <a href="https://www.instagram.com/MambaSocial/" class="linkedin"><i class="fab fa-instagram" style={{ color: "rgb(255, 0, 255)" }}></i></a>
                <a href="https://discord.com/invite/mambaverse" class="youtube"><i class="fab fa-discord" style={{ color: "rgb(255, 0, 255)" }}></i></a>
            </div>
            <header id="header-06" >

                <nav class="navbar navbar-expand-md navbar-light bg-faded cripto_nav navbackground" >
                    <div class="container">
                        {toggleModal ? <><button class="navbar-toggler navbar-toggler-right" type="button" onClick={() => { SetTogglemodal(!toggleModal) }} >
                            <i class="fas fa-times" style={{ color: "white" }} ></i></button></> : <><button class="navbar-toggler navbar-toggler-right" onClick={() => { SetTogglemodal(!toggleModal) }} type="button" ><i class="fas fa-bars" style={{ color: "white" }} ></i></button></>}

                        <a class="navbar-brand" data-scroll href="/"><img src="images/logos/mamba-icon.png" style={{ height: "40px", width: '40px' }} alt="logo" /></a>
{/* style={{ height: "50px", width: '80px' }} */}
                        <div class={`collapse navbar-collapse ${toggleModal ? "show" : ""}`} id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item"><a data-scroll href="#header-06" class="nav-link active">Home</a></li>
                                <li class="nav-item"><a data-scroll href="#howitworks" class="nav-link">How It
                                    Works</a></li>
                                <li class="nav-item"><a data-scroll href="#mambatoken" class="nav-link">Mamba Token</a></li>
                                <li class="nav-item"><a data-scroll href="#distribution" class="nav-link">Distribution</a></li>
                                <li class="nav-item"><a data-scroll href="#roadmap" class="nav-link">Roadmap</a></li>
                                <li class="nav-item"><a data-scroll href="#team_membar" class="nav-link">Team</a></li>
                                <li class="nav-item"><a data-scroll href="#" class="nav-link" style={{ cursor: 'pointer' }} onClick={() => handleShow('Login')}>Login</a></li>
                                <li class="nav-item"><a data-scroll href="#" class="nav-link" onClick={() => handleShow("SignUp")}>Signup</a></li>
                            </ul>

                            <div class="language" >
                                <div class="dropdown"><span style={{ cursor: 'pointer' }} onClick={() => { setPrototype(!prototype) }}>Prototype&nbsp;<i class="fas fa-chevron-down"></i></span>
                                    <ul class={`dropdown-menu ${prototype ? "show" : ""}`}>
                                        <li><a class="dropdown-item" href="https://wallet.mambasea.com/" target="_blank">Wallet</a></li>
                                        <li><a class="dropdown-item" href="https://marketplace.mambasea.com/" target="_blank">NFT Marketplace</a></li>
                                        <li><a class="dropdown-item"
                                            href="https://mambaplatform.com/images/gamezone.mp4" target="_blank">Game Zone</a></li>
                                        {/*   <li>
                                        <a class="dropdown-item"
                                            href="https://ido.mambapayments.com/" target="_blank">MAMBA Token</a>
                                            </li> */}
                                        <li>
                                            <a class="dropdown-item"
                                                href="#" onClick={() => handleShow("SignUp")} >MAMBA Token</a>
                                        </li>
                                        <li><a class="dropdown-item"
                                            href="https://www.mambalabs.io/" target="_blank">MAMBA LABS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* PopUp Modal for login and Singup Modal */}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter "
                    centered

                    show={show} onHide={handleClose}>

                    <Modal.Body>
                        <div class='modalfile' >

                            <div class='modalfirst' >
                                <Tabs defaultActiveKey={ModalTabs} id="uncontrolled-tab-example" className="myClass nav nav-tabs nav-tabs-line"  >
                                    <Tab eventKey="Login" title="Login" class='nav-item' style={{ cursor: 'pointer' }}  >
                                        <form onSubmit={onsubmitedform}>
                                            <div class="input-item">
                                                <input type="text" placeholder="Your Email" class="input-bordered" value={email} onChange={(e) => { setEmail(e.target.value) }} /></div>
                                            <div class="input-item"><input placeholder="Password" type={passwordShownconfirm ? "text" : "password"} class="input-bordered" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                                <i class='input-item-label' onClick={togglePasswordVisiblityconfirm} style={{ marginLeft: '-23px' }}>{eye}</i>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center"><div class="input-item text-left"><input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term" onChange={() => setRemember(true)} />
                                                <label for="agree-term">Remember Me</label></div><div><Link to='/ForgetPassword' href="forgot.html" class='text-white'>Forgot password?</Link><div class="gaps-2x"></div></div></div>
                                            <button class="btn btn-primary btn-block" disabled={loading} >{loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Sign in"}</button></form>
                                    </Tab>
                                    <Tab eventKey="SignUp" title="SignUp" class='nav-item' style={{ background: 'transparent', cursor: 'pointer' }}>
                                        <form onSubmit={onegistration}>
                                            <div class="input-item">
                                                <input type="text" placeholder="Your Username" class="input-bordered" onChange={(e) => { setUsername(e.target.value) }} /></div>
                                            <div class="input-item">
                                                <input type="text" placeholder="Your Fullname" class="input-bordered" onChange={(e) => { setFullname(e.target.value) }} /></div>
                                            <div class="input-item">
                                                <input type="text" placeholder="Your Email" class="input-bordered" onChange={(e) => { setEmail1(e.target.value) }} /></div>
                                            <div class="input-item" style={{ paddingBottom: '10px' }}><input placeholder="Password" type={passwordShownconfirm ? "text" : "password"} class="input-bordered" onChange={(e) => { setPassword1(e.target.value) }} />
                                                <i onClick={togglePasswordVisiblityconfirm} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                            </div>
                                            <div class="input-item">
                                                <span class='text-white' style={{ fontSize: '11px' }}>Do you have a referral code?</span>
                                                <input placeholder="Referral Code" class="input-bordered" value={referralCode} onChange={(e) => { setReferralCode(e.target.value) }} />

                                            </div>
                                            <div class="d-flex justify-content-between align-items-center"><div class="input-item text-left"><input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term" onChange={() => setRemember(true)} />
                                                <label for="agree-term">Remember Me</label></div><div><Link to='/ForgetPassword' href="forgot.html" class='text-white'>Forgot password?</Link><div class="gaps-2x"></div></div></div>
                                            <button class="btn btn-primary btn-block" disabled={loading1} >{loading1 ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Create Account"}</button></form>
                                    </Tab>

                                </Tabs>
                            </div>
                            <div class='modalsecondpart'>
                                <div class="sap-text" style={{ marginTop: '-30px' }}><span>Or Sign Up With</span></div>
                                <ul class="row guttar-20px guttar-vr-20px">

                                    <li class="col" > <GoogleLogin
                                        clientId="472022756240-2u388hij51qu39shuo8gm0tqs0699htd.apps.googleusercontent.com"
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} class='btn btn-outline btn-dark btn-google btn-block' ><em class="fab fa-google"></em><span class='text-white'>Google</span></button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogleFailure}

                                    />
                                    </li>
                                </ul>

                            </div>

                        </div>


                    </Modal.Body>

                </Modal>

                {/* End PopUp Modal for login and Singup Modal */}
                <div class="container">
                    <div class="row">
                        <video autoPlay muted loop id="myVideo">
                            <source
                                src={Vedio}
                                type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                        <div class="col-12 col-md-12 col-lg-6">
                            <div class="intro-text">
                                <h2 class="mambawhite" className="deepak">
                                    {/* MambaVerse an ecosystem built for Creators */}
                                    MambaVerse an ecosystem built for creators, influencers, and entrepreneurs.
                                </h2>
                                <p class="aman">
                                    {/* NFT marketplace, Gaming, and Virtual Experience.
                                MambaVerse NFT passport holders earn $MAMBA token rewards from marketplace transactions. */}
                                    NFT Marketplace & Metaverse built on Polygon, UE5, and Epic Online Services.

                                </p>

                                <div>
                                    <a href="http://linktr.ee/creatortrendi" target="_blank" style={{color:'#f879b6'}} >Linktr.ee/creatortrendi</a>
                                </div>


{/* https://docs.google.com/forms/d/1am8qrkj4eF187IEfmRlRHHV-FJAhVgabBtRPqWs33-8/alreadyresponded?edit_requested=true */}
                                <div class="btn_video_wrapper">
                                    <a href="https://docs.google.com/forms/d/1am8qrkj4eF187IEfmRlRHHV-FJAhVgabBtRPqWs33-8/alreadyresponded?edit_requested=true" target="_blank" class="btn btn-default btn-default-style">Get Whitelisted</a>
                                 
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-12 col-lg-6">
                            <div class="countdown_time">
                                {/* <h3>Token Pre-Sale Ends</h3>
                                <Countdown1 date={new Date(tmecounter)} renderer={renderer} /> */}
                                <div class="count-time_btn" >
                                  <a onClick={() => handleShow("SignUp")} title="" class="gradient-color" style={{ cursor: 'pointer' }}><span style={{color:'white !important'}} >Buy Token</span></a>
                                    {/* <a href="https://7eu2sjchkgr.typeform.com/to/GbuHbfv0" target="_blank" class="btn btn-default btn-default-style">Get Whitelisted</a> */}
                                    {/* class="gradient-color" */}
                                </div>
                            </div>


                            <div>
                            <ReactPlayer
                                style={{ border: '1px solid black', borderRadius: '5px!important', marginTop: "40px" }}
                                url='https://www.youtube.com/watch?v=0HVvYoYkUpY'
                                className='react-player-video'
                                width='100%'
                                height='320px'
                                controls={true}

                            />
                            </div>
                        </div>
                    </div>
                </div>


            </header>
            <section id="companis_supported_061" class="companis_supported_061 kan">
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="companis_supported_logos">
                                <div class="sub-title">
                                    <h6>PRODUCTS</h6>
                                    <h2 class="mambawhite">Mamba Economy</h2>
                                </div>
                                <div class="row feature_items d-flex justify-content-between">
                                    <a class="col-sm-12 col-md-6 col-lg-3 single-wrapper" href="https://mambaplatform.com/images/gamezone.mp4" target="_blank">
                                        <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                            <p class="text-center">Gaming & Virtual World</p>
                                        </div>
                                    </a>
                                    <div class="col-sm-12 col-md-6 col-lg-3 single-wrapper"
                                        onClick={() => handleShow("SignUp")}>
                                        <div class="card" style={{ backgroundolor: " #00000040", padding: "20px" }}>
                                            <p class="text-center">Data Analytics & DeFi</p>
                                        </div>
                                    </div>
                                    <a class="col-sm-12 col-md-6 col-lg-3 single-wrapper" href="https://marketplace.mambasea.com/" target="_blank">
                                        <div class="card" style={{ color: "rgb(255, 0, 255)", padding: "20px" }}>
                                            <p class="text-center">NFT Marketplace</p>
                                        </div>
                                    </a>
                                    <a class="col-sm-12 col-md-6 col-lg-3 single-wrapper" href="https://wallet.mambasea.com/" target="_blank">
                                        <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                            <p class="text-center">Web2 & Web3 payments</p>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="howitworks">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="sub-title">
                                <h6>HOW IT WORKS</h6>
                                <h2 class="mambawhite" className='mambawhite'>MAMBA is the native token of MambaVerse & will be required to purchase certain items within
                                    the Mamba Platform.</h2>

                                <p>Mamba users will be able to purchase items from the OpenMamba marketplace.
                                    Our marketplace will showcase items to personalize Mamba galleries in the metaverse and
                                    custom avatars. MAMBA is a utility token and offers holders a wide range of features within
                                    the Mamba Platform. Examples include, accessing exclusive NFT drops, pre-sale events,
                                    Staking, and liquidity pools.</p>
                            </div>
                            <br />



                            <div class="styles__Diagram-sc-i8unrf-0 iFFWjD">
                                <div class="styles__Container-sc-1hwtnny-1 fqJnap">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-md-2 col-lg-1 d-none d-md-flex align-items-center">
                                                    <div class="styles__BlockLegend-sc-1hwtnny-0 heMxGb">Platform</div>
                                                </div>
                                                <div class="col-12 col-md-10 col-lg-11">
                                                    <div
                                                        class="block-sc-1jbgm4z-0 iHhhRy justify-content-center justify-content-md-between">
                                                        <div class="d-flex align-items-center justify-content-center"><img
                                                            src="images/logos/mamba-icon.png" alt="/" width="25"
                                                            class="me-2" /><span>MAMBA Platform</span></div>
                                                        <div
                                                            class="d-none d-md-flex align-items-center justify-content-center">
                                                            <div class="block-sc-1jbgm4z-0 cMkVsO me-2 d-none d-sm-block">
                                                                <span>Payments</span></div>
                                                            <div class="block-sc-1jbgm4z-0 cMkVsO me-2 d-none d-sm-block">
                                                                <span>Wallet</span></div>
                                                            <div class="block-sc-1jbgm4z-0 cMkVsO me-2 d-none d-lg-flex">
                                                                <span>NFT</span></div>
                                                            <div class="block-sc-1jbgm4z-0 cMkVsO me-2 d-none d-lg-flex">
                                                                <span>Metaverse</span></div>
                                                            <div class="block-sc-1jbgm4z-0 cMkVsO d-none d-sm-block">
                                                                <span>Web Dev</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-lg-1 d-none d-md-flex align-items-center">
                                                    <div class="styles__BlockLegend-sc-1hwtnny-0 heMxGb">Contract<br /> Address</div>
                                                </div>
                                                <div class="col-12 col-md-10 col-lg-11">
                                                    <div class="block-sc-1jbgm4z-0 iHhhRy"><span className='mobileFont'>
                                                        <a target="_blank" href='https://polygonscan.com/token/0xfc49642cabdb87587c84fa21decade6d8b33ab42' className='' >
                                                            0xFC49642cAbdb87587C84FA21deCAdE6D8b33Ab42
                                                        </a>
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-lg-1 d-none d-md-flex align-items-center">
                                                    <div class="styles__BlockLegend-sc-1hwtnny-0 heMxGb">Meta</div>
                                                </div>
                                                <div class="col-12 col-md-10 col-lg-11">
                                                    <div class="row">
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 fXasrr mr-2">
                                                                <span>Arbitrage</span></div>
                                                        </div>
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 fXasrr mr-2"><span>Staking</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 fXasrr mr-2"><span>Gaming</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 fXasrr mr-2"><span
                                                                class="d-sm-block">Lending</span><span
                                                                    class="d-block d-sm-none">DeFi</span></div>
                                                        </div>
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 hhOwLs mr-2">
                                                                <span>Payments</span></div>
                                                        </div>
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 hhOwLs mr-2"><span>Staking</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 hhOwLs mr-2"><span>Gaming</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 hhOwLs"><span
                                                                class="d-sm-block">Low Fee</span><span
                                                                    class="d-block d-sm-none">DeFi</span></div>
                                                        </div>
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 gLWHBz"><span>Arbitrage</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-1 d-none d-lg-block">
                                                            <div class="block-sc-1jbgm4z-0 gLWHBz"><span>Staking</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 gLWHBz"><span>Gaming</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-sm-2 col-lg-1 d-none d-sm-block">
                                                            <div class="block-sc-1jbgm4z-0 gLWHBz"><span
                                                                class="d-sm-block">Voting</span><span
                                                                    class="d-block d-sm-none">DeFi</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-lg-1 d-none d-md-flex align-items-center">
                                                    <div class="styles__BlockLegend-sc-1hwtnny-0 heMxGb">Currency</div>
                                                </div>
                                                <div class="col-12 col-md-10 col-lg-11">
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="block-sc-1jbgm4z-0 fXasrr">$MAMBA</div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="block-sc-1jbgm4z-0 hhOwLs">Stablecoin</div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="block-sc-1jbgm4z-0 gLWHBz">DAO</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </section>

            <br />

            <section id="best_feature_06">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="sub-title">
                                <h6>ADVANTAGES</h6>
                                <h2 class='mambawhite'>DeFi & Payment On-and-Off Ramps</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row feature_items d-flex justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/client_focus_neon.svg" alt="" />
                                <h3>Customer-First-Strategy</h3>
                                <p>Our primary goal is to provide clean user interfaces and very simple processes that fully
                                    meet customer requirements. Empowering everyone to use the benefits of the MambaVerse
                                    without any prior knowledge of using NFT’s & cryptocurrencies.</p>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/plus_hand_neon.svg" alt="" />
                                <h3>Highest-Interest Rates</h3>
                                <p>Mamba will combine multiple tools to generate a stable interest rate of up to 10%+ for the
                                    client ‘s holdings through staking and liquidity pools.</p>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/list.svg" alt="" />
                                <h3>Risk Management</h3>
                                <p>Mamba will evaluate product risks and smart contract code before investing any assets. We
                                    will also automate multiple risk checks to shift investments if defined signals are
                                    negative.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row feature_items d-flex justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/cross_chain_neon.svg" alt="" />
                                <h3>Cross Chain Interoperability</h3>
                                <p>Gain access with interoperability across multiple blockchain ecosystems in one place. Cross
                                    chain bridge for ETH, DEX’s, NFT’s, Dapps. Interoperability is paramount to the sustainable
                                    success of the Mamba platform.</p>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/bank_neon2.svg" alt="" />
                                <h3>Financial</h3>
                                <p>Mamba Platform will offer different financial services like a crypto wallet, flexible payment
                                    rails, lending, liquidity pools, & earning high staking rates.</p>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/scale_neon.svg" alt="" />
                                <h3>Legal</h3>
                                <p>Mamba platform will follow rights and regulations and will operate from a headquarter in
                                    Texas. We will have partnerships with financial entities that are sanctioned Transfer Agents
                                    and ISO Banks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="mambatoken">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="sub-title">
                                <h6>MAMBA TOKEN</h6>
                                <h2 class='mambawhite'>Token Economy with Value</h2>

                                <div class="styles__BulletWrapper-sc-1097ksb-1 hRPknx">
                                    <div class="block-sc-1jbgm4z-0 iHhhRy d-flex align-items-start flex-column">
                                        <div class="styles__Label-sc-tll767-1 iEuKFt">Token</div>
                                        <div class="styles__Text-sc-tll767-2 htHwlR">MAMBA (ERC20 on Polygon)</div>
                                    </div>
                                    <div class="block-sc-1jbgm4z-0 iHhhRy d-flex align-items-start flex-column">
                                        <div class="styles__Label-sc-tll767-1 iEuKFt">Type</div>
                                        <div class="styles__Text-sc-tll767-2 htHwlR">Utility Token</div>
                                    </div>
                                    <div class="block-sc-1jbgm4z-0 iHhhRy d-flex align-items-start flex-column">
                                        <div class="styles__Label-sc-tll767-1 iEuKFt">Use Case</div>
                                        <div class="styles__Text-sc-tll767-2 htHwlR">Loyalty Programs, Voting Rights and Platform Incentives</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="row feature_items d-flex justify-content-between vivek kan">
                        <div class="col-sm-12 col-md-6 col-lg-6 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/plus_hand_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Token Value</h3>
                                <p class="light">Mamba will distribute a strong utility token that creates real value for our users and
                                    community. Therefore, the Mamba token has several benefits and incentives on the Mamba
                                    Platform, this also includes voting rights.</p>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/buyback_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Token-Burn</h3>
                                <p class="light">We will be burning Mamba tokens. These will ensure a stable token price, thereby ensuring
                                    token value throughout all ecosystem transactions.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row feature_items d-flex justify-content-between vivek">
                        <div class="col-sm-12 col-md-6 col-lg-6 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/percentage_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">NFT Mamba Rewards</h3>
                                <p class="light">NFT rewards Depending on the amount of Mamba token held you can boost your staking rewards
                                    and receive a discount on ecosystem fees.</p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 single-wrapper">
                            <div class="single-items">
                                <img src="/mamba-icons/coin_stack_2.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Staking Rewards</h3>
                                <p class="light">By holding Mamba tokens in your Mamba wallet, you will earn staking rewards in Mamba tokens.
                                    The more MAMBA you stake the more rewards you can earn.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />

            <div id="anchor-LoyaltyProgram" class="styles__SectionHoc-sc-dlr1e-0 dRQEWz">
                <div class="container">
                    <h6>Loyalty Program</h6>
                </div>
                <div class="styles__Section-sc-1n5auen-0 fQrxgE">
                    <div class="container">
                        <div class="row">
                            <div class="col col-md-6">
                                <h2 class="mb-4 mambawhite" >Loyalty Tier Types</h2>
                                <p class="mb-5">Loyalty tiers are based on the number of $MAMBA Tokens in your MAMBA wallet.</p>

                            </div>
                            <div class="col-12 col-md-6">
                                <div class="d-flex">
                                    <div class="styles__TypeLegend-sc-1n5auen-1 itXFEV">
                                        <div type="associate" class="styles__TypeLegendItem-sc-1n5auen-2 ieGEDQ"><strong>5,000</strong> or more MAMBA Tokens</div>
                                        <div type="senior" class="styles__TypeLegendItem-sc-1n5auen-2 keTHiT"><strong>15,000</strong> or more MAMBA Tokens</div>
                                        <div type="manager" class="styles__TypeLegendItem-sc-1n5auen-2 fXsXa-D"><strong>35,000</strong> or more MAMBA Tokens</div>
                                        <div type="partner" class="styles__TypeLegendItem-sc-1n5auen-2 jEvfgQ"><strong>100,000</strong> or more MAMBA Tokens</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="styles__LoyaltyTiers-sc-1d2eqkv-0 gUcFIF">
                            <div class="styles__Item-sc-1d2eqkv-3 KiYjS">
                                <div class="styles__ItemHeader-sc-1d2eqkv-2 bGZImK">&nbsp;</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs">$MAMBA Staking Rewards ¹</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs">NFT Staking ²</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs">NFT passport discount ³</div>
                            </div>
                            <div class="styles__Item-sc-1d2eqkv-3 ijtYKH">
                                <div type="associate" class="styles__ItemHeader-sc-1d2eqkv-2 eARTbm">APE<span><strong>5.000</strong> or more MAMBA Tokens</span></div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Staking Rewards ¹</span> 10%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Earn more Interests ²</span>  + 1.5%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Discount on Loan Fees ³</span> 2.5%</div>
                            </div>
                            <div class="styles__Item-sc-1d2eqkv-3 ijtYKH">
                                <div type="senior" class="styles__ItemHeader-sc-1d2eqkv-2 kzZzSJ">HERO<span><strong>15.000</strong> or more MAMBA Tokens</span></div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Staking Rewards ¹</span> 12%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Earn more Interests ²</span>  + 2.5%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Discount on Loan Fees ³</span> 5%</div>
                            </div>
                            <div class="styles__Item-sc-1d2eqkv-3 ijtYKH">
                                <div type="manager" class="styles__ItemHeader-sc-1d2eqkv-2 cczFxx">DIAMOND<span><strong>35.000</strong> or more MAMBA Tokens</span></div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Staking Rewards ¹</span> 15%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Earn more Interests ²</span> + 3.5%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Discount on Loan Fees ³</span> 10%</div>
                            </div>
                            <div class="styles__Item-sc-1d2eqkv-3 ijtYKH">
                                <div type="partner" class="styles__ItemHeader-sc-1d2eqkv-2 iegOeC">LEGENDARY<span><strong>100.000</strong> or more MAMBA Tokens</span></div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Staking Rewards ¹</span> 20%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Earn more Interests ²</span> + 4.5%</div>
                                <div class="styles__ItemRow-sc-1d2eqkv-1 enaRYs"><span>Discount on Loan Fees ³</span> 15%</div>
                            </div>
                        </div>
                        <div class="styles__LabelLegend-sc-1d2eqkv-4 lkwMrJ">
                            <div class="styles__LabelLegendItem-sc-1d2eqkv-5 CZRcl"><span>¹</span> Earn MAMBA Tokens for holding MAMBA Tokens.</div>
                            <div class="styles__LabelLegendItem-sc-1d2eqkv-5 CZRcl"><span>²</span> By Staking your NFT you earn more interest on your deposit.</div>
                            <div class="styles__LabelLegendItem-sc-1d2eqkv-5 CZRcl"><span>³</span> MambaVerse discount + Access.</div>
                        </div>
                    </div>
                </div>
            </div>



            <section id="distribution">
                <div class="container">
                    <div class="sub-title">
                        <h6>DISTRIBUTION</h6>
                        <h2 class="mambawhite">Token Allocation</h2>
                    </div>
                    <div class="row vivek">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <AmChart />
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 padding-none">
                            <div class="single-items">
                                <img src="/mamba-icons/seed_hand_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Private Sale</h3>
                                <p class="light">10% of the MAMBA tokens will be offered in a seed/private sale with a high discount. These
                                    tokens will have a vesting period of 12 months with a gradual burn of 1/30,000 of total bought
                                    MAMBA tokens per day.</p>
                            </div>
                            <div class="single-items">
                                <img src="/mamba-icons/public_sale_coin_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Pre-Sale</h3>
                                <p class="light">4% of the MAMBA tokens will be offered in a pre-sale with little discount. </p>
                            </div>
                            <div class="single-items">
                                <img src="/mamba-icons/public_sale_coin_neon_2.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Public Sale</h3>
                                <p class="light">50% of the MAMBA tokens will be offered in a public sale. </p>
                            </div>
                            <div class="single-items">
                                <img src="/mamba-icons/liquidy_pool_neon.svg" alt="" style={{ margin: "10px" }} />
                                <h3 class="deepak">Liquidity Pool Event</h3>
                                <p class="light">Liquidity pools will be created in which MAMBA tokens are distributed through liquidity
                                    mining. In total 20% of the MAMBA tokens will be allocated to the liquidity mining pools.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-7 col-lg-5 padding-none vivek">
                            <div class="about_cryptonic-content">
                                <h6>UTILIZATION OF FUNDS</h6>
                                <h2 class="mambawhite" class="deepak">Funding Allocation</h2>
                                <p class="light">Capital collected from seed funding, public sale A and public sale B will be
                                    used for the further development of the MambaVerse company and all related operations. The
                                    largest part of the investment will be allocated to technical development, including the
                                    Mamba Blockchain and platform. Another important point is marketing and all related
                                    activities to reach the end customers. Legal and regulatory aspects are also crucial, for
                                    instance, obtaining a banking license. Continuous business development and exploration of
                                    new business areas is also essential. The last major item is operations, this includes
                                    infrastructure costs, rents, office equipment, and salaries that do not relate to any of the
                                    first-mentioned areas.</p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-7">
                            <Amchart1 />
                        </div>
                    </div>
                </div>
            </section>

            <section id="roadmap" class="roadmap">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="sub-title">
                                <h6>ROADMAP</h6>
                                <h2 class="mambawhite">Our Journey and Vision</h2>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-12 roadmap-area">
                            <div class="roadmap-tree">

                                <div class="row roadmap-cloumn pn_reverse">
                                    <div class="roadmap-marker"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc  roadmap-dese-left">
                                        <div class="doc-wraper">
                                            <p>IDO Dashboard ✅ <br />
                                                Wallet Prototype ✅<br />
                                                Data Analytics Dashboard ✅<br />
                                            </p>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-right">
                                        <span>2021</span><span>Nov</span>
                                    </div>
                                </div>

                                <div class="row roadmap-cloumn wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                                    <div class="roadmap-marker marker-off"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-left">
                                        <span>2022</span><span> March </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right">
                                        <div class="doc-wraper">
                                            <p>Token Presale ✅<br />
                                                Marketplace Prototype ✅</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row roadmap-cloumn pn_reverse  wow fadeInUp" data-wow-duration="2s"
                                    data-wow-delay=".4s">
                                    <div class="roadmap-marker"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-left">
                                        <div class="doc-wraper">
                                            <p>NFT Marketplace <br />
                                                MambaVerse NFT Passports<br />

                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-right">
                                        <span>2022</span><span> May </span>
                                    </div>
                                </div>

                                <div class="row roadmap-cloumn  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                    <div class="roadmap-marker marker-off"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-left">
                                        <span>2022</span><span> August </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right">
                                        <div class="doc-wraper">
                                            <p>MambaVerse Land Sales<br />
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div class="row roadmap-cloumn pn_reverse  wow fadeInUp" data-wow-duration="2s"
                                    data-wow-delay=".4s">
                                    <div class="roadmap-marker"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-left">
                                        <div class="doc-wraper">
                                            <p>Mobile App Prototype<br />
                                                Custodial Wallet
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-right">
                                        <span>2022</span><span> Nov  </span>
                                    </div>
                                </div>

                                <div class="row roadmap-cloumn  wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                    <div class="roadmap-marker marker-off"></div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-status roadmap-left">
                                        <span>2022</span><span> Dec </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right">
                                        <div class="doc-wraper">
                                            <p>MambaVerse - Gaming Alpha</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="team_membar" class="team_membar">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="sub-title">
                                <h6>TEAM</h6>
                                <h2 class="mambawhite">Meet Our MAMBA Team</h2>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row single-row">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">

                            <img src={dave} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Dave Augusto</h5>
                            <h6 class="deepak">Co-Founder & CEO</h6>
                            <a href="https://www.linkedin.com/in/dave-augusto/" target="_blank"><i class="fab fa-linkedin-in"
                                style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Creator of the MambaVerse ecosystem. Web developer, artist, and advocate of blockchain/nft technology. Member of the Texas Blockchain Council. Serial Entrepreneur & Investor. </p>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={chad} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Chad Gordon</h5>
                            <h6 class="deepak">Co-Founder & President</h6>
                            <a href="https://www.linkedin.com/in/chad-gordon-5b120b166/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Co-founder of Mamba Network & President of Mamba NFT’s, which provides a vast
                                portfolio ranging from Entertainment, Real Estate, Art, and Music.</p>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={deepak} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Deepak Batra</h5>
                            <h6 class="deepak">Co-Founder & CTO</h6>
                            <a href="https://www.linkedin.com/in/deepak2200051/" target="_blank"><i class="fab fa-linkedin-in"
                                style={{ margin: "10px" }}></i></a>
                            <p class="deepak mt-1">A seasoned software visionary, coupled with a history of being a C-level
                                executive and team leader. Has created multiple software products in the past which are doing
                                over$10B in revenue.</p>

                        </div>
                    </div>


                    <div class="row single-row">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={imagem} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">D’anna Olsen Zito</h5>
                            <h6 class="deepak">{/* Creator & Solutions Designer */}
                                Co-Founder Epic Engineer & Starchitect
                            </h6>
                            <a href="https://www.linkedin.com/in/caitlinskulley" target="_blank"><i class="fab fa-linkedin-in"
                                style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Mamba virtual world UE5 creator. SCI ARC Alum, UX leader, design thinker, product manager, experience strategist, generative artist, & human-loving introvert.</p>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={tony} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Tony Lustig</h5>
                            <h6 class="deepak">CFO</h6>
                            <a href="https://www.linkedin.com/in/tony-lustig-110b03/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Director and Head of Mergers & Acquisitions at Lockheed Martin Space</p>

                        </div>
                        {/* <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={abhi} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Abhimanyu Dilliwal</h5>
                            <h6 class="deepak">Blockchain Developer</h6>
                            <a href="https://www.linkedin.com/in/abhimanyu-dilliwal-775936189/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">A Blockchain and Web development enthusiast passionate about ground-breaking
                                technology and solving real world problems.</p>

                        </div> */}

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={vi} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Vivek kumar</h5>
                            <h6 class="deepak">Blockchain Developer</h6>
                            <a href="https://www.linkedin.com/in/vivek-kumar-5bb792182/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Experienced Full Stack Engineer with a demonstrated history of working in the
                                blockchain industry. Skilled in Web.3 Js,Artificial Engineering, Front-end Development, and
                                Training. Strong engineering professional with a BTech focused in Computer Science from Manav
                                Rachna International University, Faridabad.</p>

                        </div>
                    </div>

                    <div class="row single-row">

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={kim} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Kim Agustin</h5>
                            <h6 class="deepak">Chief Business Development Officer</h6>
                            <a href="https://www.linkedin.com/in/kimberly-agustin-40429a38/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Managed dozens of multinational company accounts including Mazda, Coca Cola,
                                Quest Diagnos-tics, Alcon & many more Fortune 500 Companies. Has helped build the organization
                                into a global consulting firm, specializing in blockchain & web services.</p>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={erin} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Erin Brown</h5>
                            <h6 class="deepak">{/* Creator & Social Media */}Business Development & SMM</h6>
                            <a href="https://www.linkedin.com/in/erin-brown-2b671a216/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Overall art enthusiast.
                                GFX designer, paint pouring, and social media content creator.
                            </p>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={amy} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Amy Snowden</h5>
                            <h6 class="deepak">Illustrator & Social Media</h6>
                            <a href="https://www.linkedin.com/in/amy-snowden-8b5b6a59/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Liaison to real world assets and entertainment. Los Angeles comedian from Oakdale, Louisiana As an awkward, shy kid she found she had a knack for making harsh realities hilarious..</p>

                        </div>
                    </div>
                    <div class="row single-row">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={patrick} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Patrick Agustin</h5>
                            <h6 class="deepak">Entertainment Director</h6>
                            <a href="https://www.linkedin.com/in/patrick-agustin-wccp-09b0a183/"><i class="fab fa-linkedin-in"
                                style={{ color: "white" }} target="_blank"></i></a>
                            <p class="deepak mt-1">A prolific DJ, director of radio on-air mixshows. Music Promotions and
                                Distribution company owner with a foundation of working with independent labels.</p>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={jessica} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Jessica Son</h5>
                            <h6 class="deepak">Escrow Officer at Time Escrow and Consultant</h6>
                            <a href="https://www.linkedin.com/company/time-escrow-inc/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Residential & Commercial Time Escrow Officer in the SoCal region with extensive closing experience, infectious attitude, and a highly respected list of loyal customers.</p>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={p} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Pattie Pruitt</h5>
                            <h6 class="deepak">Creator & Community</h6>
                            <a href="https://www.linkedin.com/in/pattie-pruitt-a62b94184/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Web developer, data analyst, and business development specialist. Early adopter of emerging technology. Ambassador to the Mamba community.</p>

                        </div>
                    </div>
                    <div class="row single-row">
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={lan} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Lan Nguyen</h5>
                            <a href="https://linkedin.com/in/lan-nguyen-89391b5" target="_blank"><i class="fab fa-linkedin-in"
                                style={{ color: "white" }}></i></a>
                            <h6 class="deepak">Chief Legal Officer and General Counsel</h6>
                            <p class="deepak mt-1">Juris Doctor from Chapman University, School of Law in 2002, with a
                                specialization Certificate in Advocacy & Dispute Resolution. Ms. Nguyen was admitted to the
                                State Bar of California in 2002. In 2006, she became a member of the State Bar of New York and
                                the District of Columbia, and was admitted to practice before the United States Court of
                                International Trade.</p>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={scott} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Scott Furuta</h5>

                            <h6 class="deepak">Founder/Broker at PartnersCRE.com Commercial Brokerage Consultant</h6>
                            <a href="https://www.linkedin.com/in/scott-furuta-834a124/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">Scott Furuta has been in commercial real estate since 2005. He is a graduate
                                from UC Riverside with a Bachelor's degree in Economics.</p>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 single-wrapper">
                            <img src={homebrew} alt="Avatar"
                                style={{ width: "125px", height: "125px", borderRadius: "50%", border: "2px solid #e324a3" }} />
                            <h5 class="deepak mt-2">Home Brew Agency</h5>
                            <h6 class="deepak">Marketing Partner</h6>
                            <a href="https://www.linkedin.com/company/home-brew/" target="_blank"><i
                                class="fab fa-linkedin-in" style={{ color: "white" }}></i></a>
                            <p class="deepak mt-1">A team of designers, developers, marketers, and strategists whose skills come together to produce successful digital campaigns of all sizes and scopes.</p>

                        </div>
                    </div>



                </div>
            </section>

            {/* 
            <footer id="footer-06" class="footer" style={{ backgroundImage: "url('https://res.cloudinary.com/mambaverse/image/upload/v1642257633/MAMBA%20Website%20Videos/footer-bg_nfkiqs.png') " }}> */}

            <footer id="footer-06" class="footer"
                style={{ backgroundImage: `url(${footerb})` }}>
                <div class="whiteDiagonal example"></div>
                <section id="companis_supported_06" class="companis_supported_06">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="companis_supported_logos">
                                    <div class="sub-title">
                                        <h2 class="mambawhite">Built with Leading Technology</h2>
                                    </div>
                                    <div class="row feature_items d-flex justify-content-between">
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">SOLIDITY</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">POLYGON</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">ETHEREUM</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">CHAINLINK</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">STRIKE</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">TIERON</p>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row feature_items d-flex justify-content-between">
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">UE5</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">TRUFFLE</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">MULTICHAIN</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">MIST</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">REMIX</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-2 single-wrapper">
                                            <div class="card" style={{ backgroundColor: "#00000040", padding: "20px" }}>
                                                <p class="text-center">RHINO3D</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="container">
                    <div class="row footer-btm-wrapper">
                        <div class="col-md-12 col-lg-12">
                            <div class="footer_items">
                                <div class="footer-single-col footer_single_list_1">
                                    <h3 class="subtitle_1">&nbsp;<img src="images/logos/mamba-icon.png" alt="" style={{width:'80%'}} /></h3>
                                </div>

                                <div class="footer-single-col footer_single_list_2">
                                    <h3 class="subtitle_1">Company</h3>
                                    <ul class="list-unstyled">
                                        <li><a href="#header-06">Home</a></li>
                                        <li><a href="#howitworks">How It
                                            Works</a></li>
                                        <li><a href="#mambatoken">Mamba Token</a></li>
                                        <li><a href="#distribution">Distribution</a></li>
                                    </ul>
                                </div>

                                <div class="footer-single-col footer_single_list_3">
                                    <h3 class="subtitle_1">Information</h3>
                                    <ul class="list-unstyled">
                                        <li><a href="#team_membar">Team</a></li>
                                        <li><a href="#roadmap">Roadmap</a></li>
                                        <li><a onClick={() => handleShow("SignUp")}>Signup</a></li>
                                        <li><a onClick={() => handleShow('Login')}>Login</a></li>
                                    </ul>
                                </div>

                                <div class="footer-single-col footer_single_list_4">
                                    <h3 class="subtitle_1">Social</h3>
                                    <ul class="social-links list-unstyled ml-auto">
                                       {/*  <li class="nav-item"><a href="https://www.facebook.com/MambaSocial/"><i class="fab fa-facebook-f"></i></a></li> */}
                                        <li class="nav-item"><a href="https://twitter.com/mambasocial"><i class="fab fa-twitter"></i></a></li>
                                      {/*   <li class="nav-item"><a href="https://t.me/MambaVerse"><i class="fab fa-telegram"></i></a></li> */}
                                        <li class="nav-item"><a href="https://www.instagram.com/MambaSocial/"><i class="fab fa-instagram"></i></a></li>
                                        <li class="nav-item"><a href="https://discord.com/invite/mambaverse"><i class="fab fa-discord"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <p> Copyright &copy; 2022, All Rights Reserved by <span>MAMBAVERSE INC</span></p>
                </div>
            </footer>


        </body>
    </div>;
}

export default Index;
