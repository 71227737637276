import React, { useState } from 'react'
import Img1 from '../../images/ath-gfx.png'
import { Link } from 'react-router-dom'
import MambaPay from '../../images/mamba.png'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { BASE_URL } from '../../useContext/walletContext'
import './index.css'

import SocailMedia from '../Socialbar/index'

function Index() {


    const [email, setEmail] = useState('')

    const forgetsendlink = async (e) => {
        e.preventDefault()
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            email: email
        }
        axios.post(`${BASE_URL}/api/reset-password`, data).then((res) => {
            
            NotificationManager.success("send Link your Email")
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "something Wrong")

        })
    }



    return (
        <div>
            <SocailMedia />
            <body class="page-ath">
                <div class="forgetclass">
                    <div class="page-ath-content">
                        <div class="page-ath-header">
                            <Link to='/' href="index.html" class="page-ath-logo">
                                <img src='images/logos/mamba-icon.png' style={{ height: "100px", width: '100px' }} alt="logo" />
                            </Link>
                        </div><div class="page-ath-form"><h2 class="page-ath-heading">Reset password <span>If you forgot your password, well, then we’ll email you instructions to reset your password.</span></h2><form onSubmit={forgetsendlink}><div class="input-item"><input type="text" placeholder="Your Email" class="input-bordered" onChange={(e) => setEmail(e.target.value)} /></div><div class="d-flex justify-content-between align-items-center"><div><button class="btn btn-primary btn-block">Send Reset Link</button></div><div><Link
                            to='/'>Return to login</Link></div></div><div class="gaps-2x"></div></form></div><div class="page-ath-footer"><ul class="footer-links"><li><Link to='/Regularpage'>Privacy Policy</Link></li><li><Link to='/Regularpage' >Terms</Link></li><li>&copy; 2021 MambaVerse Pay LLC.</li></ul></div>
                    </div></div><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
