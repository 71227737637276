import React, { useState, useEffect } from 'react'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import Header from '../header/index'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { BASE_URL } from '../../useContext/walletContext'
import ReactPaginate from 'react-paginate'
import Footer from '../adminFooter/index'



function Index() {

    const [userdetails, setUserdetails] = useState([])
    const [front, setFront] = useState([])
    const [backPart, setBackPart] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [filters, setFilter] = useState("")


    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getKYCformDetailsadmin`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
          
            setUserdetails(res?.data?.results.reverse())
            setFront(res?.data?.results?.document)
            setBackPart(res?.data?.results?.document)

        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }

    
    useEffect(() => {
        gettrancation()
    }, [])

    /* *******frontdownload******************** */
    const downloadfront = () => {
        var element = document.createElement("a");
        var file = new Blob(
            [
                `${BASE_URL}/${front}`
            ],
            { type: "image/*" }
        );
        element.href = URL.createObjectURL(file);
        element.download = "image.jepg";
        element.click();
    };

    /* *******frontdownload******************** */

    /* *******back -download******************** */
    const downloadback = () => {
        var element = document.createElement("a");
        var file = new Blob(
            [
                `${BASE_URL}/${backPart}`
            ],
            { type: "image/*" }
        );
        element.href = URL.createObjectURL(file);
        element.download = "image.jepg";
        element.click();
    };

    /* *******Back -download******************** */

    /* *************************Kym Rejected************************ */
    const transcationdetailsAdminRejected = async (ele) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
       
        // const date = new Date()

        const data = {
            KycFormstatus: "Rejected",
            _id: ele
        }
        axios.put(`${BASE_URL}/api/KycAdminupdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success(`Successfully Rejected OD${ele}`)

            window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* ************************* END Kym Rejected************************ */

    /* ************************* Kym Accepted************************ */
    const transcationdetailsAdminAccepted = async (ele) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
      
        const date = new Date()

        const data = {
            KycFormstatus: "Accepted",
            _id: ele
        }
        axios.put(`${BASE_URL}/api/KycAdminupdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
          
            NotificationManager.success(`Successfully Accepted OD${ele}`)

            window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* *************************End Kym Accepted************************ */

    /* ************************* Kym Missing************************ */
    const transcationdetailsAdminMissing = async (ele) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
      
        // const date = new Date()

        const data = {
            KycFormstatus: "Missing",
            _id: ele
        }
        axios.put(`${BASE_URL}/api/KycAdminupdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
          
            NotificationManager.error(`Successfully Missing OD${ele}`)

            window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* *************************End Kym Missing************************ */

    const userPages = 10
    const pageVisited = pageNumber * userPages

    const DislayKycList = userdetails.filter((val) => {
       
        if (filters === '') {
            return val
        } else if (val?.firstName?.toLowerCase().includes(filters?.toLowerCase()) || val?.email?.toLowerCase().includes(filters?.toLowerCase())) {
            return val
        }
    }).slice(pageVisited, pageVisited + userPages).map((ele, index) => {

        return (

            <>

                <tr class="data-item">
                    <td class="data-col dt-user"><span class="lead user-name">{ele.firstName}&nbsp;{ele.lastName}</span><span class="sub user-id">{ele._id}</span></td>
                    <td class="data-col dt-user"><span class="lead user-name">{ele.email}</span><span class="sub user-id">{ele._id}</span></td>
                    <td class="data-col dt-doc-type"><span class="sub sub-s2 sub-dtype">{ele.documenttype ?? "N/A"} </span></td>

                    <td class="data-col dt-doc-front"><a href={`${BASE_URL}/${ele?.document[0]?.path}`} class="image-popup" download target='_blank'>Front Part</a> &nbsp; &nbsp; <a href={`${BASE_URL}/${ele?.document[0]?.path}`} class="image-popup" download target='_blank'><em class="fas fa-download"></em></a></td>

                    <td class="data-col dt-doc-back">{ele?.document[1]?.path ? <><a href={`${BASE_URL}/${ele?.document[1]?.path}`} class="image-popup" download target='_blank'>Back Part</a> &nbsp; &nbsp; <a href={`${BASE_URL}/${ele?.document[1]?.path}`} class="image-popup" download target='_blank'><em class="fas fa-download"></em></a></> : null}</td>

                    {/* <td class="data-col dt-status">
                    <span class="dt-status-md badge badge-outline badge-success badge-md">Approved</span><span class="dt-status-sm badge badge-sq badge-outline badge-success badge-md">A</span>
                    </td> */}
                    <td class="data-col dt-status">  {ele.KycFormstatus === 'Pending' ? <><span class="dt-status-md badge badge-outline badge-info badge-md abhimanyu">Pending</span><span class="dt-status-sm badge badge-sq badge-outline badge-info badge-md">P</span></> : ele.KycFormstatus === 'Rejected' ? <><span class="dt-status-md badge badge-outline badge-danger badge-md abhimanyu">Rejected</span><span class="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">R</span></> : ele.KycFormstatus === 'Accepted' ? <>
                        <span class="dt-status-md badge badge-outline badge-success badge-md abhimanyu">Approved</span><span class="dt-status-sm badge badge-sq badge-outline badge-success badge-md">A</span>
                    </> : ele.KycFormstatus === 'Update' ? <><span class="dt-status-md badge badge-outline badge-danger badge-md abhimanyu">Update</span><span class="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">U</span></> : <><span class="dt-status-md badge badge-outline badge-warning badge-md abhimanyu">Missing</span><span class="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">M</span> </>}</td>
                    <td class="data-col text-right">
                        <div class="relative d-inline-block">
                            <a href="#" class="btn btn-light-alt btn-xs btn-icon toggle-tigger"><em class="ti ti-more-alt"></em></a>
                            <div class="toggle-class dropdown-content dropdown-content-top-left">
                                <ul class="dropdown-list">
                                    <li>
                                        <Link to='/KycListDetails' state={ele}>
                                            <em class="ti ti-eye"></em> View Details</Link>
                                    </li>
                                    <li onClick={() => transcationdetailsAdminAccepted(ele._id)}><a href="#"><em class="ti ti-check"></em> Approve</a></li>
                                    <li onClick={() => transcationdetailsAdminRejected(ele._id)}><a href="#"><em class="ti ti-na"></em> Rejected</a></li>
                                    <li onClick={() => transcationdetailsAdminMissing(ele._id)}><a href="#"><em class="ti ti-trash"></em> Missing</a></li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </>)

    })

    const pageCount = Math.ceil(userdetails.length / userPages)
    const changePage = (texted) => {
        setPageNumber(texted)
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <body class="page-user"><div class="topbar-wrap"><div class="topbar is-sticky"><div class="container">
                <Menu />


            </div></div>
                <Header />


            </div>
                <div class="page-content">
                    <div class="container">
                        <div class="content-area card">
                            <div class="card-innr">
                                <div class="card-head">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 class="card-title" >KYC List</h4>
                                        <input type='text' class='input-bordered w-25' placeholder='Search by Name' onChange={(e) => setFilter(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ overflowX: "auto" }}>
                                    <table class="data-table kyc-list">
                                        <thead>
                                            <tr class="data-item data-head">
                                                <th class="data-col dt-user">User</th>
                                                <th class="data-col dt-user">Email</th>
                                                <th class="data-col dt-doc-type">Doc Type</th>
                                                <th class="data-col dt-doc-front">Documents</th>
                                                <th class="data-col dt-doc-back">&nbsp;</th>
                                                <th class="data-col dt-status">Status</th>
                                                <th class="data-col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {DislayKycList}
                                        </tbody>

                                    </table>
                                </div>
                                <div>
                                    <div><ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}

                                        onPageChange={page => setPageNumber(page.selected)}

                                        pageCount={pageCount}
                                        breakLabel={'...'}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        nextClassName={'page-item next'}
                                        previousClassName={'page-item prev'}
                                        previousLinkClassName={'page-link'}
                                        pageLinkClassName={'page-link'}
                                        breakClassName='page-item'
                                        breakLinkClassName='page-link'
                                        containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1'}
                                    /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer /><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
