import React, { useLayoutEffect, useState, useEffect } from 'react'
import SocialMediaBar from 'react-social-media-bar'
import './social.css'

function Index() {
    const [cssStyle, setCssStyle] = useState(false)

    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
        if (window.innerWidth > 600) {
            setCssStyle(false)
        } else {
            setCssStyle(true)
        }
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);

        return () => window.removeEventListener("resize", resizeWindow);
    }, []);



    // useEffect(() => {
    //     useWindowSize()
    // }, [])

    console.log('sizeofwindow', windowWidth)

    return (

        <div class="icon-bar">
           {/*  <a href="https://www.facebook.com/MambaSocial/" target='_blank' class="facebook"><i class="fab fa-facebook-f" ></i></a> */}
            <a href="https://twitter.com/mambasocial" target='_blank' class="twitter"><i class="fab fa-twitter" ></i></a>
           {/*  <a href="https://t.me/MambaVerse" target='_blank' class="google"><i class="fab fa-telegram" ></i></a> */}
            <a href="https://www.instagram.com/MambaSocial/" target='_blank' class="linkedin"><i class="fab fa-instagram" ></i></a>
             <a href="https://discord.gg/mambaverse" target='_blank' class="youtube"><i class="fab fa-discord" ></i></a> 
        </div>

    )
}

export default Index
