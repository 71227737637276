import React from 'react'
import Footer from '../Footer/index'
import Header from '../header/index'
import Menu from '../menu/index'
import SocailMedia from '../Socialbar/index'

function Index() {
    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky" >
                        <div class="container">
                            <Menu />
                        </div>
                    </div>
                    <Header />
                </div>
                <SocailMedia />



                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="main-content col-lg-12">
                                <div class="content-area card">
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <h4 class="card-title card-title-lg">Section 1</h4>
                                            <h4 class="card-title card-title-lg mt-1">General</h4>
                                            <p>What is MambaVerse?</p>
                                            <p>MambaVerse brings together imminent rise of unbridled creativity with a Metaverse guided by all content creators.  </p>
                                            <p>To lead the way, MambaVerse, with the marketplace under its wings, stands ready to lead each and every one of you to your unique vantage point within the Metaverse.</p>
                                        </div>
                                        <div class="content">
                                            <h4 class="deepak">General</h4>
                                            <div class="accordion-simple" id="faqList-1">
                                                <div class="accordion-item">
                                                    <h6 class="accordion-heading collapsed" data-toggle="collapse" data-target="#collapse-1-1"> Artists and Creators  </h6>
                                                    <div id="collapse-1-1" class="collapse" data-parent="#faqList-1">
                                                        <div class="accordion-content">
                                                            <p>We’ve created a virtual landscape for talented artists like you to do the work they love to do while being motivated and inspired by the collaborative aspects of a movement much larger than themselves. Wilder World is building an infrastructure for creative pursuits elevating digital artistry to the 3D Metaverse. We cherish creators and have built a cyber economy just for you. Our powerful, viral “Drops” propel you into the stratosphere of financial opportunity.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item">
                                                    <h6 class="accordion-heading collapsed" data-toggle="collapse" data-target="#collapse-1-2">Collectors and Investors </h6>
                                                    <div id="collapse-1-2" class="collapse" data-parent="#faqList-1">
                                                        <div class="accordion-content">
                                                            <p>What drives you is the unquenchable thirst to hold what is highly valued by you and your peers. We embrace, curate and nurture the most prestigious 3D art available on the planet while providing a web3-enabled, liquid NFT market. Financialization of art co-exists with (and not against) artist abundance in our Metaverse through the tokenization of digital art and the capitalization of vibrant crypto markets.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item">
                                                    <h6 class="accordion-heading collapsed" data-toggle="collapse" data-target="#collapse-1-3">Community and Fanbase </h6>
                                                    <div id="collapse-1-3" class="collapse" data-parent="#faqList-1">
                                                        <div class="accordion-content">
                                                            <p>You want belonging in a galactic new world and a place of standing within the cyber revolution. Wilder World invites you to build expressive connections with your favorite artists in a community platform built from the ground up to ignite the creator-community bond.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="gaps-2x"></div>
                                            <h4 class="deepak">What cryptocurrencies can I use to purchase?</h4>
                                            <p>At the moment you can use Ethereum and Polygon (MATIC) tokens to buy MAMBA</p>
                                            <p>How can I participate in the ICO Token sale?</p>
                                            <p>Visit -- ido.mambapayments.com</p>
                                            <h4 class="deepak">How do I benefit from the ICO Token?</h4>
                                            <ul class='content'>
                                                <li style={{ listStyle: 'disc' }}>Mamba is the native token of MambaVerse. MAMBA will be required to purchase certain items within the Mamba Network</li>
                                                <li style={{ listStyle: 'disc' }}>Mamba users will be able to purchase items from the MambaSea marketplace, via NFT protocols. Our marketplace will showcase items to personalize Mamba galleries in the metaverse and custom avatars.</li>
                                                <li style={{ listStyle: 'disc' }}>MAMBA is a utility token and offers holders a wide range of features within the Mamba Platform. Example includes, accessing exclusive NFT drops, pre-sale events, and liquidity pools.</li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Buy, sell, lend – Each piece of land, apartment or house will be a blockchain NFT and as a result of that can be sold, bought or lent in a secure and trustless way. Depending on district, location, size it will have its own market value which as we believe will increase as popularity of platform grows.
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>3D virtual NFT shops and galleries – As a full-fledged habitant of our metaverse you will be able to place your art in our marketplace. In further stages of metaverse development your space can be uniquely designed, and 3D rendered in a VR way. You can place your NFT’s art there and invite potential buyers to visit. This feature may take time for us to build.</li>
                                            </ul>
                                            <div class="gaps-2x"></div>
                                            <div class="card-head">
                                                <h4 class="card-title card-title-lg">Section 2
                                                </h4>
                                                <h4 class="card-title card-title-lg mt-1">Corporate KYC</h4>
                                                <p class='deepak'>What jurisdictions does your solution for Corporate KYC cover?</p>
                                                <p >Know Your Customer provides real-time connections to official company registries worldwide. Our coverage is continuously expanding. Please email us at contact@mambaverse.io to enquire about our full jurisdictional coverage.
                                                </p>
                                                <p class='deepak'>What documents are available on corporate entities?
                                                </p>
                                                <p>The documents vary depending upon the jurisdiction in which the investigation is being undertaken. Many jurisdictions provide a summary of information relating to the company, which includes many of the details required to complete KYC checks, along with copies of constitutional/ incorporation documents and financial information.</p><p>Our in-house regulatory experts have mapped our solution to automatically retrieve the documents that – in each jurisdiction – are required in order to complete the KYC process.</p>
                                                <p>All our time-stamped documents are sourced directly from the relevant company registry and provide full documentary evidence as required by regulators.
                                                </p><p>If you’d like to find out more about which documents we are able to automatically retrieve in different jurisdictions, please contact us at contact@mambaverse.io.</p>
                                                <p class='deepak'>
                                                    Can I request additional documents?
                                                </p>
                                                <p>By default, Know Your Customer will return all available documents needed to complete your KYC case for each jurisdiction, where possible.  If other documents are available for the relevant company, you may request other documents.</p>
                                                <p class='deepak'>Why are all documents not provided in English or the language of my choice?</p>
                                                <p>All company registries provide documents in the language of that particular country. Some company registries, as an added service, provide documents in other languages, but please note this is a minority of registries.</p>
                                                <p class='deepak'>
                                                    Is the information for every country the same?
                                                </p>
                                                <p>No. Documents vary on a country-by-country basis depending upon the laws and regulations of that specific country in relation to public access to such information and the requirements to file such information with the company registry.
                                                </p>
                                                <p class='deepak'>Why is some information not provided by certain company registries?
                                                </p>
                                                <p>Information is usually not provided by some company registries due to local laws and regulations which restrict the public disclosure to certain sensitive information.
                                                </p><p class='deepak'>What AML checks do you perform on corporate entities?
                                                </p><p>The checks conducted will vary depending upon the AML legislation of the relevant jurisdiction for which the search is being undertaken. However, as a guide, checks are usually undertaken as follows:</p>
                                                <ul class='content'>
                                                    <li style={{ listStyle: 'disc' }}>That the company exists and is not in liquidation</li>
                                                    <li style={{ listStyle: 'disc' }}>The directors and shareholders</li>
                                                    <li style={{ listStyle: 'disc' }}>The constitutional/ incorporation documentation</li>
                                                    <li style={{ listStyle: 'disc' }}>The latest annual return
                                                    </li>
                                                    <li style={{ listStyle: 'disc' }}>The latest financial statements
                                                    </li>
                                                    <li style={{ listStyle: 'disc' }}>Data bases we use to verify information</li>

                                                </ul>
                                                <p>Some registries will not provide sensitive information, like financial information or shareholder data, and this will have to be provided by your client. However, once you have obtained the documents you require, Know Your Customer is able to extract shareholder information and compare them to several standard AML sanctions lists.</p>
                                            </div>
                                            <div class="card-head">
                                                <h4 class="card-title card-title-lg">Section 3
                                                </h4>
                                                <h3 class='deepak'>Individual KYC</h3>
                                                <p class='deepak'>Can you help verify the identity of my remote individual clients?</p>
                                                <p>In today’s highly digital world, it is not uncommon to complete transactions and build business relationships with individuals you have never met in person.</p>
                                                <p>
                                                    Using mobile and web-based technology together with international and local data sources, we provide you with the tools you need to verify your clients’ identities accurately. At the same time, we provide your clients with the ability to complete their ID Verification process from the palm of their hands, wherever they are and whenever it suits them best.
                                                </p>
                                                <p class='deepak'>On top of ID photos and selfies, can you add related documents to an individual case?</p>
                                                <p>We understand that, in addition to proof of identity documents, our clients might like to make full use of our robust document management module. This is why our document collection tools (including our mobile app and web portal) can be configured on a per-client basis to request additional documentation, allowing end customers to upload additional documents during their online journey.</p>
                                                <p>Alternatively, your compliance team can also manually upload any documents they wish to include in a case and we will time stamp the upload and allow you to retrieve it whenever and wherever you need.</p>
                                                <p class='deepak'>Will Know Your Customer flag to me sanctioned or Politically Exposed Persons?</p>
                                                <p>Yes, we initially run all identified directors and shareholders through several international sanction lists as they are identified and notify you of any adverse results. Our solution also runs weekly AML checks by default and allows administrators to set event or date triggers to carry out ongoing due diligence in line with internal or regulatory obligations.</p>
                                                <p class='deepak'>How long does it take to see documents that a client has uploaded through the mobile app or the web portal?</p>
                                                <p>The upload of the collected documents through the app and the web portal takes only seconds, subject to the speed of the end user’s internet connection, and your compliance staff will be alerted of each new upload immediately. From there it takes only a couple of clicks to the full view of those documents and all entered details.</p>
                                                <p class='deepak'>Can you add related documents to an individual case?</p>
                                                <p>We understand that in addition to proof of identity documents our clients might like to make full use of our robust document management module. This is why you can easily upload any document you wish to include in a case and we will time stamp the upload and allow you to retrieve it whenever and wherever you need.</p>
                                                <p>Can we have the app with our own logo and under our own name in the App Store?</p>
                                                <p>Absolutely! We offer both a white-labelled and company labelled option. Please contact us on contact@mambaverse.io for more details.</p>
                                                <p class='deepak'>Do you offer any additional layers of verification to passports/national identity cards?</p>
                                                <p>As an extra layer of security, Know Your Customer partners with third-party providers to run real-time checks on your clients’ documents and information.</p>
                                                <p>Similar to the technology used in airports worldwide, our solution checks hundreds of photo ID documents from around the world for authenticity based on a number of key indicators. Our solution provides you with detailed information about the findings to help you make informed decisions and keep your business safe from potential fraud.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="aside sidebar-right col-lg-4">
                                <div class="token-statistics card card-token height-auto">
                                    <div class="card-innr">
                                        <div class="token-balance">
                                            <div class="token-balance-text"><h6 class="card-sub-title" style={{ textAlign: "center" }}>Tokens Balance</h6><div style={{ textAlign: "center" }}><span class="lead">120,000,000 <span>MambaVerse</span></span></div>
                                            </div>
                                        </div>
                                        <div class="token-balance token-balance-s2">
                                            <h6 class="card-sub-title" style={{ textAlign: "center" }}>Your Contribution</h6><ul class="token-balance-list" style={{ textAlign: "center" }}>
                                                <li class="token-balance-sub"><span class="lead">2.646</span><span class="sub">ETH</span></li>
                                                <li class="token-balance-sub"><span class="lead">1.265</span><span class="sub">BTC</span></li>
                                                <li class="token-balance-sub"><span class="lead">6.506</span><span class="sub">LTC</span></li>
                                            </ul><hr style={{ height: "2px", borderWidth: "0px", backgroundColor: "white", textAlign: "center" }} /><ul class="token-balance-list" style={{ textAlign: "center" }}>
                                                <li class="token-balance-sub"><span class="lead">2.646</span><span class="sub">ETH</span></li>
                                                <li class="token-balance-sub"><span class="lead">1.265</span><span class="sub">BTC</span></li>
                                                <li class="token-balance-sub"><span class="lead">6.506</span><span class="sub">LTC</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="token-sales card">
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <h5 class="card-title card-title-sm">Pre-Sale Token Sales</h5>
                                        </div>
                                        <div class="token-rate-wrap row">
                                            <div class="token-rate col-md-6 col-lg-12">
                                                <span class="card-sub-title">MAMBAToken Price</span>
                                                <h4 class="font-mid text-dark">1 ETH = <span>12500 MambaVerse</span></h4>
                                            </div>
                    
                                        </div>
                                        <div class="token-bonus-current">
                                            <div class="fake-class">
                                                <span class="card-sub-title">Current Bonus</span>
                                                <div class="h3 mb-0">20 %</div>
                                            </div>
                                            <div class="token-bonus-date">End at <br /> 10 Jan, 2021</div>
                                        </div>
                                    </div>
                                    <div class="sap"></div>
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <h5 class="card-title card-title-sm">Token Sales Progress</h5>
                                        </div>
                                        <ul class="progress-info">
                                            <li><span>Raised</span> 2,758 MambaVerse</li>
                                            <li class="text-right"><span>TOTAL</span> 1,500,000 MambaVerse</li>
                                        </ul>
                                        <div class="progress-bar">
                                            <div class="progress-hcap" data-percent="83">
                                                <div>Hard cap <span>1,400,000</span></div>
                                            </div>
                                            <div class="progress-scap" data-percent="24">
                                                <div>Soft cap <span>40,000</span></div>
                                            </div>
                                            <div class="progress-percent" data-percent="28"></div>
                                        </div>
                                        <span class="card-sub-title mgb-0-5x">Sales END IN</span>
                                        <div class="countdown-clock" data-date="2021/11/06"></div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
                {/* <div class="footer-bar"><div class="container"><div class="row align-items-center justify-content-center"><div class="col-md-8"><ul class="footer-links"><li><a href="#">Whitepaper</a></li><li><a href="faq-page.html">FAQs</a></li><li><a href="regular-page.html">Privacy Policy</a></li><li><a href="regular-page.html">Terms of Condition</a></li></ul></div><div class="col-md-4 mt-2 mt-sm-0"><div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x"><div class="copyright-text">&copy; 2021 MambaVerse Pay LLC.</div><div class="lang-switch relative"><a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a><div class="toggle-class dropdown-content dropdown-content-up"><ul class="lang-list"><li><a href="#">Fr</a></li><li><a href="#">Bn</a></li><li><a href="#">Lt</a></li></ul></div></div></div></div></div></div></div> */}

                {/* <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script> */}</body>
        </div>
    )
}

export default Index
