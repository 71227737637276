import { SpinnerCircular } from 'spinners-react';

import React, { useState, useEffect } from 'react'
import { AccordionItem, Accordion, AccordionHeader } from 'reactstrap';
import Header from '../header/index'
import Footer from '../Footer/index'
import Menu from '../menu/index'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import { constants } from 'ethers'
import Avatar from 'react-avatar';
import './avater.css'
import { BASE_URL } from '../../useContext/walletContext'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/dark.css";
import { Link } from 'react-router-dom';
import './Modal.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet"
import Azuki1 from '../../images/Azuki1.jpg'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Row,
    Col,
    Input,
    Form,
    Button,
    Label,
    CustomInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap'
import { User, Mail, Smartphone, Lock, Search } from 'react-feather'

import Main from './Main'
import Accordian from './Components/Accordion'
import { Modal } from 'react-bootstrap'

const Azuki = [
    {
        id: "1",
        imageUrl: 'Azuki1.jpg',
        name: 'Azuki',
        AzukiNumber: '1094'
    },
    {
        id: "2",
        imageUrl: 'Azuki2.jpg',
        name: 'Azuki',
        AzukiNumber: '1095'
    },
    {
        id: "3",
        imageUrl: 'Azuki3.jpg',
        name: 'Azuki',
        AzukiNumber: '1096'
    },
    {
        id: "4",
        imageUrl: 'Azuki4.jpg',
        name: 'Azuki',
        AzukiNumber: '1097'
    },
    {
        id: "5",
        imageUrl: 'Azuki5.jpg',
        name: 'Azuki',
        AzukiNumber: '1098'
    },
    {
        id: "6",
        imageUrl: 'Azuki6.jpg',
        name: 'Azuki',
        AzukiNumber: '1099'
    },
    {
        id: "7",
        imageUrl: 'Azuki7.jpg',
        name: 'Azuki',
        AzukiNumber: '1100'
    },
    {
        id: "8",
        imageUrl: 'Azuki8.jpg',
        name: 'Azuki',
        AzukiNumber: '1101'
    }


]



function Index() {
    const [show, setShow] = useState(false);
    const [dataModal, setDataModal] = useState({ image: "", name: "", AzukiNumber: "" })

    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        setShow(true)
        setDataModal({ image: value.imageUrl, name: value.name, AzukiNumber: value.AzukiNumber })
    }

    return (
        <>
            <Helmet>
                <title>Profile | MambaVerse - Bridge between physical and virtual worlds within the decentralized and open-source virtual world</title>
                <meta name="description" content="MambaVerse is a multi-faceted economy with P2P, B2C, & B2B marketplace which include collectibles, gaming items, and other virtual goods backed by blockchain technology." />
            </Helmet>

            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />
                        </div>
                    </div>

                    <Header />

                </div>

                <div class="page-content">
                    <div className='row'>
                        <div class="main-content col-lg-3">
                            <div className='ml-2'>
                                <h1 className='goldentext t' >FILTER</h1>
                            </div>
                            <hr style={{ borderTop: "1px solid" }} />

                            <div>

                                <label for="toogleA" class="flex w-full py-3 border-b  border-opacity-10  items-center cursor-pointer border-black">
                                    <div class="font-600 text-yellow-600 flex items-center relative text-xs uppercase text-black">
                                        <div class="grid grid-cols-1 w-7 ml-2 mr-2">
                                            <img class="overlay-item duration-300 opacity-0" src="https://www.azuki.com/goldbean.png" />

                                        </div>
                                        <span class="goldentext">Golden Mode</span>
                                    </div>

                                    <div class="relative ml-auto pr-1">
                                        <input id="toogleA" type="checkbox" class="sr-only" />
                                        <div class="w-10 h-4 duration-300 rounded-full shadow-inner bg-yellow-200"></div>
                                        <div class="dot bg-azukired absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                                    </div>
                                </label>

                                <hr style={{ borderTop: "1px solid" }} />
                                <Col sm='12'>
                                    <div className='icon-search-wrapper my-3 mx-auto'>
                                        <InputGroup className='input-group-merge' tag={FormGroup}>

                                            <Input
                                                placeholder='Sort by Serial...' />
                                        </InputGroup>
                                    </div>
                                </Col>


                                <hr style={{ borderTop: "1px solid" }} />
                                <Accordian />
                            </div>
                        </div>

                        <div class="main-content col-lg-9">

                            <div>
                                <div>
                                    <Row className='match-height' style={{ display: "flex" }}>
                                        <Col xl='6' md='6' xs='12'>
                                            <h1 className='goldentext t'>AZUKIS // 1000</h1>
                                        </Col>
                                        <Col xl='6' md='6' xs='12' style={{ display: "flex", justifyContent: 'end' }}>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <div class=' mb-2'>

                                                    <a class="btn btn-primary">MY AZUKIS</a>
                                                </div>
                                            </div>


                                            <div style={{ paddingLeft: '10px' }}>
                                                <div class=' mb-2'>
                                                    <a class="btn btn-primary">SHUFFLE</a>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <hr style={{ borderTop: "1px solid" }} />

                                <div className='mt-3 fw-600 fs-12'>
                                    <h3>Filters: 0</h3>
                                </div>

                                <Row>
                                    {Azuki.map((value, index) => (

                                        <Col md='6' xl='3' key={index} className='mt-2' style={{cursor:'pointer'}} onClick={
                                            () => { handleShow(value) }
                                        }>
                                            <img className='zoom' src={`assets/images/${value.imageUrl}`} style={{ padding: "10px", borderRadius: '15px', maxWidth: "100%" }} alt="Card image cap" />
                                            {console.log("viv", value.imageUrl)}
                                            <div className='text-center'>
                                                <p class="opacity-50  mt-3 uppercase font-mono tracking-widest text-3xs text-center">{value.name}</p>
                                                <h3 class="font-400  pb-2 -mt-1 text-2xs tracking-wider text-center uppercase goldentext ">No. {value.AzukiNumber}</h3>
                                            </div>
                                        </Col>

                                    ))}
                                </Row>


                            </div>
                        </div>
                    </div>

                </div>



                <Modal

                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show} onHide={handleClose}>

                    <Modal.Body>
                  

                        <Row>
                            <Col xl='6' md='6' xs='12' >
                                <img src={`assets/images/${dataModal.image}`} style={{maxWidth:'100%'}} />
                            </Col>
                            <Col xl='6' md='6' xs='12'>
                                <div style={{marginTop:"40px"}}>
                                    <div className='text-black'>{dataModal.name}</div>
                                    <h1 className='text-black ' style={{fontWeight:'800'}}>
                                    #{dataModal.AzukiNumber} //</h1>
                                </div>
                                <div>
                                    <Row className='mt-2 b-2'>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Type.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">Type:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase">Human</h4>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Hair.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">HAIR:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase"> BROWN LONG</h4>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 b-2'>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Clothing.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">CLOTHING:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase"> TECH JACKET</h4>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Eyes.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">EYES:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase">DETERMINED</h4>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 b-2'>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Mouth.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">MOUTH:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase">POUT</h4>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Offhand.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">OFFHAND:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase">GOLDEN KATANA</h4>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 b-2'>
                                        <Col xl='6' md='6' xs='12'>
                                            <div class="text-xs items-center py-2 px-3 flex rounded bg-white bg-opacity-10 w-full border-black">
                                                <img class="w-8 pr-2" src="https://www.azuki.com/filtericons/Black/Background.png" />
                                                <div>
                                                    <h4 class="flex items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px text-black">BACKGROUND:</span></h4>
                                                    <h4 class="ml-auto text-xs font-600 text-black uppercase">OFF WHITE C</h4>
                                                </div>
                                            </div>
                                        </Col>
                                      
                                    </Row>
                                </div>
                              {/*   <h1>{dataModal.name}</h1>
                                <h1>n</h1>
                                <h1>k</h1> */}
                            </Col>
                        </Row>

                    </Modal.Body>

                </Modal>

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script>
            </body>
        </>
    )
}

export default Index
