import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'
import { NotificationContainer, NotificationManager } from 'react-notifications'


function Emailwarning() {
    const [index, setIndex] = useState('')

     const UserAdminEmailResend = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        // console.log("index", )


       /*  axios.get(`${BASE_URL}/api/AdminEmailVerifyEmailSend/${index}`, { */
       axios.get(`${BASE_URL}/api/AdminEmailVerifyEmailSend/${index}`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            NotificationManager.success(` Successfully Email Confirmation Send `)
            // window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Something Wrong")
        })
    }

    useEffect(() => {
        const token = window.sessionStorage.getItem('index')
        setIndex(token)
    }, [])
    return (
        <div>
            <div class="page-header page-header-kyc">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-7 text-center">
                            <h2 class="page-title"> Welcome to MambaVerse</h2>
                            {/* <p class="large">Verify your Email - Welcome to MambaVerse.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-9">
                            <div class="kyc-status card mx-lg-4">
                                <div class="card-innr">
                                    <div class="status status-empty">
                                        <div class="status-icon"><em class="ti ti-email"></em></div>
                                        <span class="status-text text-dark">We have sent you an email to verify your email address. Please check your mail. Please allow 1-2 mins for email to arrive before you request for another email..</span>
                                        <span>Note: Please check the SPAM folder in case you do not find our email in your inbox. To receive future emails in your inbox, please make sure to mark it as NOT SPAM.</span>
                                        <div>
                                        <button class='btn bg-transparent' onClick={UserAdminEmailResend}>Resend Email</button>
                                        </div>

                                        {/* <a
                      onClick={Onredirect} class="btn btn-primary">Click here to complete your KYC</a> */}
                                    </div>
                                    
                                </div>
                            </div>
                            <p class="text-light text-center mgmt-1x">If you have any questions, please contact our customer support team at  <a href="#">contact@mambaverse.io</a>.</p>
                            <div class="gaps-1x"></div>
                            <div class="gaps-3x d-none d-sm-block"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Emailwarning
