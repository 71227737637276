import React, { useContext, useEffect } from 'react'
import Menu from '../menu/index'
import Header from '../header/index'
import Footer from '../Footer/index'
import { Link } from 'react-router-dom'
import SocailMedia from '../Socialbar/index'
import { useLocation } from 'react-router-dom'
import { StoreContext1 } from '../../useContext/pathContext'

function Index() {
    const context = useContext(StoreContext1)
    const location = useLocation()

    useEffect(() => {
        context.setPath({ ...context.path, kycform: location.pathname })
    }, [])
    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>

                    </div>

                    <Header />


                </div>
                <SocailMedia />

                <div class="page-header page-header-kyc"><div class="container"><div class="row justify-content-center"><div class="col-lg-8 col-xl-7 text-center"><h2 class="page-title">Begin your ID-Verification</h2><p class="large">Verify your identity to participate in tokensale.</p></div></div></div></div><div class="page-content"><div class="container"><div class="row justify-content-center"><div class="col-lg-10 col-xl-9"><div class="kyc-status card mx-lg-4"><div class="card-innr"><div class="status status-thank px-md-5"><div class="status-icon"><em class="ti ti-check"></em></div><span class="status-text large text-dark">You have completed the process of KYC</span><p class="px-md-5">We are still waiting for your identity verification. Once our team verified your indentity, you will be notified by email. You can also check your KYC  compliance status from your profile page.</p><Link to={{ pathname: '/BuyToken', state: { prevPath: location.pathname } }}
                    href="profile.html" class="btn btn-primary">Back to BuyToken</Link></div></div></div><p class="text-light text-center mgmt-1x">If you have any question, please contact our support team  <a href="#">contact@mambaverse.io</a>.</p><div class="gaps-1x"></div><div class="gaps-3x d-none d-sm-block"></div></div></div></div></div>
                <Footer />

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
