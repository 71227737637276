import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.css'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { BASE_URL } from '../../useContext/walletContext'
import moment from 'moment'

function Index() {
    const history = useLocation()
    const nevigator = useNavigate()
    const userId = history.pathname.split('/').pop()
    const tokenArray = history.pathname.split('/')
    const [message, setMessage] = useState('')
    const token = tokenArray[2]
    console.log("token", token)
    const [buttonActive, setButtonActive] = useState(false)
    const [newpath, setnewpath] = useState()
    console.log("userId", userId)
    const [otp, setotp] = useState()
    let splitedata = []
    splitedata = history.pathname.split('/')
    window.sessionStorage.setItem("userresend", splitedata[3] ?? "")
    const user = splitedata[3]

    const otpsend = (e) => {
        // e.preventDefault()
        const data = {
            userId: userId,
            token: otp
        }
        axios.get(`${BASE_URL}/api/twoauthverifys/${userId}/${token}`).then((res) => {
            console.log(res.data)
            setMessage('successfully done')
            // NotificationManager.success(" successfully done")
            setButtonActive(true)
            setnewpath(res.data.result.userId)

        }).catch((error) => {
            console.log(error)
            NotificationManager.error(error.response.data.message ?? "something wrong")
            setMessage(error.response.data.message ?? "something wrong")
        })
    }

    const VerifyEmailAccound = async () => {
        // const newpath = await history.pathname.split('/').pop()
        await axios.get(`${BASE_URL}/api/AfterEmailverify/${newpath}`).then((res) => {
            console.log("res", res.data)
            window.sessionStorage.setItem("Token", res?.data?.result?._id ?? "")
            window.sessionStorage.setItem("userName", res?.data?.result?.userName ?? "")
            window.sessionStorage.setItem("fullName", res?.data?.result?.fullName ?? "")
            window.sessionStorage.setItem("email", res?.data?.result?.email ?? "")
            window.sessionStorage.setItem("image", res?.data?.result?.image ?? "")
            window.sessionStorage.setItem("role", res?.data?.result?.role ?? "")
            // NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
            window.sessionStorage.setItem("WalletAddress", res?.data?.result?.WalletAddress ?? "")
            nevigator('/home')
        }).catch((error) => {
            console.log("error", error?.response?.data?.message)
        })
    }

    const ReSendLink = async () => {
        const sessiontoken = await window.sessionStorage.getItem('userresend')
        axios.post(`${BASE_URL}/api/ResendSecurityCode/${sessiontoken}`).then((res) => {
            console.log(res.data)
            NotificationManager.success(" Security Code Send")


        }).catch((error) => {
            console.log(error)
            NotificationManager.success(error.response.data.message ?? "something wrong")
        })

    }
    useEffect(() => {
        //    const a=moment()
        //    const b=moment(a).add(20,"minutes")
        //    console.log("name",a,b)
        //    console.log(b.diff(a,"minutes"))
        otpsend()
    }, [])


    return (
        <div>
            <div class="page-header page-header-kyc">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-7 text-center">
                            <h2 class="page-title"> MambaVerse</h2>
                            {/* <p class="large">Verify your Two Factor for the process.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-9">
                            <div class="kyc-status card mx-lg-4">
                                <div class="card-innr">
                                    <div class="status status-thank px-md-5">
                                        {/* <div class="status-icon"><em class="ti ti-check"></em></div> */}
                                        {/* <span class="status-text large text-dark">{token === '' ? null : token}</span> */}
                                        {/* <p class="px-md-5">A verification code has been sent to you via E-Mail. Please verify that 6 digit code below to continue.</p> */}

                                        {buttonActive ? <><div class="status-icon"><em class="ti ti-check"></em></div> <div class="">
                                            Your identity is verified. Please click on Dashboard button to access Mamba Dashboard.
                                        </div> <button class='btn btn-primary' onClick={VerifyEmailAccound}>Dashboard</button></> : <>
                                            <div class="titleverifyno">
                                                Security Code
                                            </div>
                                            <form class="mt-3" onSubmit={otpsend}>
                                                <input class="otp" type="text" value={token} disable />

                                            </form>
                                            <button class='btn btn-primary mt-4 mb-4 customBtn' onClick={otpsend}>Submit</button>
                                            <p onClick={ReSendLink} style={{ cursor: 'pointer', color: 'red' }}>Resend Code</p>
                                        </>}

                                    </div>
                                </div>
                            </div>
                            <p class="text-light text-center mgmt-1x">If you have any question, please contact our support team <a href="#">contact@mambaverse.io</a>.</p>
                            <div class="gaps-1x"></div>
                            <div class="gaps-3x d-none d-sm-block"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
