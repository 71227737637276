import Home from '../home/index'
import KycApplication from '../kycApplication/index'
import Distribution from '../distribution/index'
import Profile from '../Profile/index'
import BuyNfts from '../BuyNfts/index'
import BuyToken from '../BuyToken/Index'
import Transactions from '../Transactions.js/index'
import Regularpage from '../regularpage/index'
import Faqpage from '../faqpage/index'
import SingIn from '../singin/index'
import SingUp from '../singup/index'
import Singupreferral from '../singupreferral/index'
import Activity from '../Activity/index'
import AdminActivity from '../ActivityAdmin/index'
import ForgetPassword from '../ForgetPassword/index'
import KycForm from '../KycForm/index'
import Kycthnaks from '../kycthnaku/index'
import Error from '../404Page/index'
import Index from '../AdminIndex/index'
import UserList from '../AdminuserList/index'
import UserDetails from '../AdminUserdetails/index'
import Transcation from '../AdminTanscation/index'
import TranscationDetails from '../AdminTranscationDetails/index'
import KycList from '../AdminKyclist/index'
import KycListDetails from '../AdminKycDetails/index'
import ManualPayment from '../manualpayment/index.js'
import Plaid from '../plaidtry/index'
import Resetpassword from '../forgetpasswordreset/index'
import Activate from '../Emailverfication/index'
import TwoFactorauthentication from '../TwoFactorauthentication/index'
import Template from '../Templates/index'
import Emailwarning from '../Emailverfication/emailwarning'
import Twoauth from '../Emailverfication/Twoauth'
import TermsandCondition from '../TermsandCondition/index'
import MambaWebsite from '../mambaWebsite/index'
import MainAccorodian from '../BuyNfts/Main'
const RouterConfig = [
    {
        path: "/home",
        component: <Home />,
        isPublic: false,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/KycApplication",
        component: <KycApplication />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/TwoAuth",
        component: <Twoauth />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    },
    // {
    //     path: "/Distribution",
    //     component: <Distribution />,
    //     isPublic: true,
    //     isProtected: true,
    //     isRestricted: false,
    //     isExact: true
    // },
    {
        path: "/Profile",
        component: <Profile />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },

    {
        path: "/MainAccorodian",
        component: <MainAccorodian/>,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },

    {
        path: "/BuyNfts",
        component: <BuyNfts/>,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },

    {
        path: "/BuyToken",
        component: <BuyToken />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/Transactions",
        component: <Transactions />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/privacypolicy",
        component: <Regularpage />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    }, {
        path: "/Faqpage",
        component: <Faqpage />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/Term_and_Condition",
        component: <TermsandCondition />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/SignUp",
        component: <SingUp />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/referral/:id",
        component: <Singupreferral />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/template",
        component: <Template />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/VerifyEmail",
        component: <Emailwarning />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/",
        component: <MambaWebsite />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/Activity",
        component: <Activity />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    }, {
        path: "/AdminActivity",
        component: <AdminActivity />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    },
    {
        path: "/ForgetPassword",
        component: <ForgetPassword />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/reset-password/:id/:id",
        component: <Resetpassword />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/activate/:id",
        component: <Activate />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/KycForm",
        component: <KycForm />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    }, {
        path: "/Kycthnaks",
        component: <Kycthnaks />,
        isPublic: true,
        isProtected: true,
        isRestricted: false,
        isExact: true
    }, {
        path: "/Index",
        component: <Index />,
        isPublic: false,
        isProtected: true,
        isRestricted: true,
        isExact: true
    }, {
        path: "/UserList",
        component: <UserList />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    }, {
        path: "/UserDetails",
        component: <UserDetails />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    }, {
        path: "/Transcation",
        component: <Transcation />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/TranscationDetails",
        component: <TranscationDetails />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    }, {
        path: "/KycList",
        component: <KycList />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    }, {
        path: "/KycListDetails",
        component: <KycListDetails />,
        isPublic: true,
        isProtected: false,
        isRestricted: true,
        isExact: true
    }, {
        path: "/payment/:id",
        component: <ManualPayment />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    }, {
        path: "/plaid",
        component: <Plaid />,
        isPublic: true,
        isProtected: true,
        isRestricted: true,
        isExact: true
    },
    {
        path: "/TwoFactAuthentication/:id/:id/:id",
        component: <TwoFactorauthentication />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    },
    {
        path: "/404",
        component: <TwoFactorauthentication />,
        isPublic: true,
        isProtected: false,
        isRestricted: false,
        isExact: true
    },
]

export { RouterConfig }
