export let userRole = false
export const login = () => {
    const sessionrole = window.sessionStorage.getItem('role')
    if (sessionrole) {
        if (sessionrole === 'admin') {
            userRole = true
        } else {
            userRole = false
        }
        return true
    } else {
        return false
    }
}