import React, { useState, useEffect } from 'react'
import Countdown from "react-countdown";
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'
import { NotificationContainer, NotificationManager } from 'react-notifications'

function TwoAuth() {

    const Completionist = () => <span>{0}:{0}</span>;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
                    {minutes}:{seconds}
                </span>
            );
        }
    };

    const [index, setIndex] = useState('')

    const UserAdminEmailResend = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        // console.log("index", )


        axios.get(`${BASE_URL}/api/AdminTwoAuthVerify/${index}`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            console.log(res.data)
            NotificationManager.success(` Successfully Email Conformation Send `)
            // window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }

    useEffect(() => {
        const token = window.sessionStorage.getItem('index')
        setIndex(token)
    }, [])
    return (
        <div>
            <div class="page-header page-header-kyc">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-7 text-center">
                            <h5 class="page-title deepak">Verify your Email</h5>
                            {/* <p class="large">To comply with regulation each participant will have to go through indentity verification (KYC/AML) to prevent fraud. Please, complete our fast and secure verification process to participate in our token sale.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-9">
                            <div class="kyc-status card mx-lg-4">
                                <div class="card-innr">
                                    <div class="status status-empty">
                                        <div class="status-icon"><em class="ti ti-email"></em></div>
                                        <span class="status-text text-dark">As you have enabled 2-Auth under Settings, we have send you an email to verify your identity.</span>
                                        <button class='btn bg-transparent' onClick={UserAdminEmailResend}>Resend Email</button>
                                        {/* <span>Note: Please check the SPAM folder in case you do not find our email in your inbox. To receive future emails in your inbox, please make sure to mark it as NOT SPAM.</span> */}
                                        {/* <Countdown date={Date.now() + 58000} renderer={renderer} />
                                        <p>{renderer}</p> */}

                                        {/* <a
                      onClick={Onredirect} class="btn btn-primary">Click here to complete your KYC</a> */}
                                    </div>
                                </div>
                            </div>
                            <p class="text-light text-center"> If you have any questions, please contact our customer support team at <a href="#">contact@mambaverse.io</a>.</p>
                            <div class="gaps-1x"></div>
                            <div class="gaps-3x d-none d-sm-block"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoAuth
