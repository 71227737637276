

 import React from 'react'
import Footer from '../Footer/index'
import Header from '../header/index'
import Menu from '../menu/index'
import {Table } from 'react-bootstrap'

function index() {
    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />
                        </div>
                    </div>
                    <Header />

                </div>

                <div class="page-content">
                    <div class="container">
                        <div class="card content-area">
                            <div class="card-innr">
                                <div class="card-head">
                                    {/*  <h4 class="card-title card-title-lg">Privacy Policy</h4> */}</div>
                                <div class="card-text">
                                    <p>
                                        <strong>
                                            Mamba Labs has a Data Handling Standard policy for Mamba Labs that specifies how long customer data is retained after deletion. There are generally two scenarios in which customer data is deleted:
                                        </strong>
                                    </p>

                                    <ul>
                                        <li>•	Active Deletion: The tenant has an active subscription, and a user or administrator deletes data, or administrators delete a user.</li>
                                        <li>•	Passive Deletion: The tenant subscription ends.</li>
                                    </ul>
                                    <h4 class="card-title card-title-lg">Data Retention</h4>

                                    <p>For each of these deletion scenarios, the following table shows the maximum data retention period, by data category and classification:</p>
{/* 
                                    <div class="col-xl-8 col-lg-7">
                                <div class="token-transaction card card-full-height">
                                <div class="card-innr"> */}
                                    <div class="card-head has-aside">
                                        <h4 class="card-title">DATA RETENTION</h4>
                                       {/*  <div class="card-opt">
                                            <a href="transactions.html" class="link ucap">View ALL <em class="fas fa-angle-right ml-2"></em></a>
                                        </div> */}
                                    </div>
                                    
<Table responsive striped bordered hover>
  <thead>
    <tr>
      <th>Data Category</th>
      <th>Data Classification</th>
      <th>Description</th>
      <th>Examples</th>
       <th>Retention Period</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Customer Data </td>
      <td>Customer Content</td>
      <td><p>Content directly provided/created <br />by admins and users</p><p>Includes all text, sound,<br /> video, image files, and software <br />created and stored in Mamba <br />Labs data centers when using the<br /> services in Mamba Labs</p></td>
      <td>Examples of the most commonly<br /> used Microsoft 365 applications that<br /> allow users to author data <br />include MambaSea, MambaVerse,<br /> MambaSocial and MambaNetwork.<br />
Customer content also<br /> includes customer-owned/provided<br /> secrets (passwords, certificates,<br /> encryption keys, storage keys)</td>
 <td><p>Active Deletion <br/>Scenario: at most<br/> 30 days</p>
    <p>Passive Deletion <br/>Scenario: at most<br/> 180 days</p>
    </td>
    </tr>
    
    <tr>
      <td>Customer Data</td>
      <td>End User Identifiable<br/> Information (EUII)</td>
      <td>Data that identifies or<br/> could be used to identify <br/> the user of a Mamba Labs service.<br/>  EUII does not contain Customer<br/>  content</td>
      <td><p>User name or display name<br/> (DOMAIN\UserName)</p><p>User principal name (name@domain)</p><p>User-specific IP addresses</p></td>
      <td><p>Active Deletion<br/> Scenario: at most<br/> 180 days (only a<br/> tenant administrator<br/> action)
</p>
<p>Passive Deletion<br/> Scenario: at most<br/> 180 days</p></td>
    </tr>
    <tr>
      <td>Personal Data<br />
(data not included<br/> in Customer Data</td>
     <td>End User Pseudonymous<br/> Identifiers (EUPI)</td>
     <td><p>An identifier created by Mamba<br/> Labs tied to the user of a<br/> Mamba Labs service. When<br/> combined with other information,<br/> such as a mapping table, EUPI <br/>identifies the end user</p></td>
     <td><p>User GUIDs, PUIDs,<br/> or SIDs</p>

<p>Session IDs</p></td>
      <td><p>Active Deletion <br/>Scenario: at most<br/> 30 days</p>
    <p>Passive Deletion <br/>Scenario: at most<br/> 180 days</p>
    </td>
    </tr>
  </tbody>
</Table>
<br/>

                                    <h5><strong>Subscription Retention</strong></h5>
                                    <p>In the term of an active subscription, a subscriber can access, extract, or delete customer data stored in Mamba Labs. If a paid subscription ends or is terminated, Microsoft retains customer data stored in Mamba Labs in a limited-function account for 90 days to enable the subscriber to extract the data. After the 90-day retention period ends, Mamba Labs disables the account and deletes the customer data. No more than 180 days after expiration or termination of a subscription to Mamba Labs, Mamba Labs disables the account and deletes all customer data from the account. Once the maximum retention period for any data has elapsed, the data is rendered commercially unrecoverable.</p>
                                    <p>For a free trial, your account moves into a grace status for 30 days in most countries and regions. During this grace period, you can purchase Mamba Labs. If you decide not to buy Mamba Labs, you can either cancel your trial or let the grace period expire, and your trial account information and data is deleted.</p>
                                    <h5><strong>Expedited Deletion</strong></h5>
                                    <p>For any subscription, a subscriber can contact Mamba Labs Support and request expedited subscription de-provisioning. In this process, all user data is deleted three days after the administrator enters the lockout code provided by Mamba Labs. </p>
                                    <h5><strong>Scope</strong></h5>
                                    <p>This Privacy & Data Usage Policy covers our treatment of information gathered from you when you are using or accessing Mamba Labs websites, email lists and other services. By using our website and services you are accepting the terms of this policy.</p>
                                    <h5><strong>Information We Collect From You</strong></h5>
                                    <p>We collect non-personally identifiable information when you visit our site. We also collect personal information from you when you:</p>

                                    <ul>
                                        <li>•	Subscribe to our email list.</li>
                                        <li>•	Attend or participate in online and/or in-person events.</li>
                                        <li>•	Contact us directly by email, phone, social media or other communications methods.</li>
                                    </ul>

                                    <h5><strong>Cookies</strong></h5>
                                    <p>We use cookies on the Mamba Labs websites to enhance the services you request and collect aggregate statistics on website usage. We do not view, analyze, or seek to collect personally identifiable analytics data.</p>

                                    <h5><strong>Privacy of Children and Sensitive Information
</strong></h5>
                                    <p>Mamba Labs does not knowingly collect information from children. We further do not knowingly collect and store sensitive consumer information such as Social Security Numbers. If we are made aware we have received such information we will delete it from our servers.</p>


                                    <h5><strong>Email List Privacy</strong></h5>
                                    <p>Mamba Labs maintains one or more email lists. When you subscribe to a Mamba Labs email list you will receive a confirmation message.</p>
                                    <p>Mamba Labs uses your email address to send you requested emails. We do not share your email address, name or other subscriber data with outside organizations. We use a third party (MailChimp or a similar service) to provide email list hosting and management services. This party collects and stores personally identifiable information including but not limited to email address, name and other information you may provide. This party also collects information about emails that are opened, and links clicked. We may use this information to provide and improve email content.</p>
                                    <p>
                                    You may remove your name from our email list at any time using the unsubscribe link in a message or sending a request to contact@mambasea.com

                                    </p>

                                    <h5><strong>Use of Your Information</strong></h5>
                                    <p>
                                    
We use your personal information to provide requested information, services and support.

                                    </p>

                                    <h5><strong>Disclosure of Your Information</strong></h5>
                                    <p>
                                    
There may be limited times when we disclose your personal information to third parties. This disclosure may be required for us to operate or provide your access to our Service, comply with our legal obligations, enforce our policies, or prevent fraud or illegal activity perpetrated through our services. We do not disclose your personally identifiable information to third parties for advertising or promotional purposes.


                                    </p>

                                    
                                    <p>
                                    
If we are required by law to disclose information you submitted, we will attempt to provide you with notice (unless we are prohibited) that a request for your information has been made in order to give you an opportunity to object to the disclosure. We will attempt to provide this notice by email, if you have given us an email address, or by postal mail if you have entered a postal address. We will independently object to overly broad requests for access to information about users of our site. If you do not challenge the disclosure request, we may be legally required to turn over your information.




                                    </p>

                                     <h5><strong>Security</strong></h5>
                                     <p>
                                     We protect your information using reasonable security measures taking into account the types of information we collect and store and the types of risks we face.

                                     </p>

                                      <h5><strong>Questions</strong></h5>
                                     <p>
                                     
For questions or concerns regarding this Privacy & Data Usage Policy, please contact contact@mambasea.com. 


                                     </p>
                                     

                                      <h5><strong>Changes to This Statement</strong></h5>
                                     <p>
                                    We may amend this Privacy and Data Usage Policy at any time. If we change this policy in any substantial way, we will notify you by posting a notice of the change on our website. We will make an effort to send notice of the change to our users via email, provided that we have your email address on record. If you disagree with the terms of the amended policy, you may choose to discontinue subscribing to or using our services.

                                     </p>
                                     





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* <div class="footer-bar"><div class="container"><div class="row align-items-center justify-content-center"><div class="col-md-8"><ul class="footer-links"><li><a href="#">Whitepaper</a></li><li><a href="faq-page.html">FAQs</a></li><li><a href="regular-page.html">Privacy Policy</a></li><li><a href="regular-page.html">Terms of Condition</a></li></ul></div><div class="col-md-4 mt-2 mt-sm-0"><div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x"><div class="copyright-text">&copy; 2021 MambaVerse Pay LLC.</div><div class="lang-switch relative"><a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a><div class="toggle-class dropdown-content dropdown-content-up"><ul class="lang-list"><li><a href="#">Fr</a></li><li><a href="#">Bn</a></li><li><a href="#">Lt</a></li></ul></div></div></div></div></div></div></div> */}
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default index
