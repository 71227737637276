import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './ScrollUp';
import {
  BrowserRouter
} from "react-router-dom";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { MetaMaskProvider } from './metamask'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { StoreProvider } from './useContext/walletContext'
import { StoreProvider1 } from './useContext/pathContext'
// import 'bootstrap/dist/css/bootstrap.css';



function getLibrary(provider, connector) {
  return new Web3(provider)
}



ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop >
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
          <StoreProvider1 >
            <StoreProvider>
              <NotificationContainer />
              <App />
            </StoreProvider>
          </StoreProvider1>
        </MetaMaskProvider>
      </Web3ReactProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
