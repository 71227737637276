import React from 'react'
import { Navigate, Route } from 'react-router'
import { login } from './loginSession'

const isRestricted = false

function Protected(props) {

    // const Component = () => {
    //     if (isRestricted)
    //         return (
    //             <Element />
    //         )
    //     return (
    //         // <Navigate to="/home" />
    //         <Element />
    //     )
    // }
    // if (isRestricted) {
    //     return (
    //         <Navigate to="/home" />

    //     )
    // }

   
    if (login()) {
        return props.children
    } else {
        return <Navigate to="/" />
    }



    // return (
    //     <Route path={path} exact={exact} element={element} />

    // )
}

export default Protected
