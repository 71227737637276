import React, { useState, useEffect } from 'react'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import Header from '../header/index'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import ReactPaginate from 'react-paginate'
import Footer from '../adminFooter/index'


function Index() {
    const [userdetails, setUserdetails] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [filters, setFilter] = useState("")

    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getadminuser`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            setUserdetails(res?.data?.result.reverse())

        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    useEffect(() => {
        gettrancation()
    }, [])

    /* ****************************DELETE ACCOUNT****************************** */
    const UserAdmindelete = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        axios.delete(`${BASE_URL}/api/deleteaccount/${index}`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            NotificationManager.success(`User removed.`)

            window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* **************************** END DELETE ACCOUNT****************************** */

    /* ***************************Email verify************************************** */
    const UserAdminEmailverify = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            AccountVerify: true
        }
        axios.put(`${BASE_URL}/api/AdminVerify/${index}`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            NotificationManager.success(` Email Verified `)
            // window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* ****************************End Email Verify********************************* */

    /* ***************************Email Resend************************************** */
    const UserAdminEmailResend = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        axios.get(`${BASE_URL}/api/AdminEmailVerifyEmailSend/${index}`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            NotificationManager.success(`Mail sent to user successfully`)
            // window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* ****************************End Email Resend********************************* */

    /* ***************************KYC Resend************************************** */
    const UserAdminKyCResend = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        axios.get(`${BASE_URL}/api/KyCForm/${index}`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success(`Mail sent to user successfully`)
            // window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* ****************************End KYC Resend********************************* */

    /* ****************************Suspend ACCOUNT****************************** */
    const UserAdminSuspend = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            AccountStatus: index.AccountStatus === 'Suspend' ? "Active" : "Suspend",
            userID: index._id
        }
        axios.put(`${BASE_URL}/api/userAccountSuspend`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success(` Successfully Suspend User `)
            window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }
    /* **************************** END Suspend ACCOUNT****************************** */
    const userPages = 10
    const pageVisited = pageNumber * userPages
    const userdminDetails = userdetails.filter((val) => {
        // console.log("val", val)
        if (filters === '') {
            return val
        } else if (val.email.toLowerCase().includes(filters?.toLowerCase()) || val.fullName.toLowerCase().includes(filters?.toLowerCase())) {
            return val
        }
    }).slice(pageVisited, pageVisited + userPages).map((ele, index) => {
       
        return (
            <>
                <tr class="data-item">
                    <td class="data-col dt-user"><span class="lead user-name">{ele.fullName}</span><span class="sub user-id">{ele.userId}</span></td>
                    <td class="data-col dt-email"><span class="sub sub-s2 sub-email">{ele.email}</span></td>
                    <td class="data-col dt-token"><span class="lead lead-btoken">{parseFloat(ele.quizTotal).toFixed(2)}</span></td>
                    <td class="data-col dt-verify">
                        <ul class="data-vr-list">

                            {ele.AccountVerify ? <li>  <div class="data-state data-state-sm data-state-approved"></div>
                                Email
                            </li> : <li>  <div class="data-state data-state-sm data-state-canceled"></div>
                                Email
                            </li>}
                            {ele.KycForm ? <li>
                                <div class="data-state data-state-sm data-state-approved"></div>
                                KYC
                            </li> : <li>
                                <div class="data-state data-state-sm data-state-canceled"></div>
                                KYC
                            </li>}
                        </ul>
                    </td>
                    {/* <td class="data-col dt-login"><span class="sub sub-s2 sub-time">2018-08-24 10:20 PM</span></td> */}
                    <td class="data-col dt-status">{ele?.AccountStatus === "Suspend" ? <> <span class="dt-status-md badge badge-outline badge-danger badge-md">Deactive</span><span class="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">A</span></> : <> <span class="dt-status-md badge badge-outline badge-success badge-md">active</span><span class="dt-status-sm badge badge-sq badge-outline badge-success badge-md">A</span></>}</td>
                    <td class="data-col text-right">
                        <div class="relative d-inline-block">
                            <a href="#" class="btn btn-light-alt btn-xs btn-icon toggle-tigger"><em class="ti ti-more-alt"></em></a>
                            <div class="toggle-class dropdown-content dropdown-content-top-left">
                                <ul class="dropdown-list">
                                    <li>
                                        <Link to='/UserDetails' state={ele} >
                                            <em class="ti ti-eye"></em> View Details</Link>
                                    </li>
                                    <li onClick={() => UserAdminSuspend(ele)}><a href="#"><em class="ti ti-na"></em> {ele.AccountStatus === "Suspend" ? "Active" : "Suspend"}</a></li>
                                    <li onClick={() => UserAdmindelete(ele._id)}><a href="#"><em class="ti ti-trash"></em> Delete</a></li>
                                    <li onClick={() => UserAdminEmailverify(ele._id)}><a href="#"><em class="ti ti-email"></em> Email verify</a></li>
                                    <li onClick={() => UserAdminEmailResend(ele._id)}><a href="#"><em class="ti ti-email"></em>Resend EmailConformation</a></li>
                                    <li onClick={() => UserAdminKyCResend(ele._id)}><a href="#"><em class="ti ti-email"></em>KycForm</a></li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </>
        )
    })

    const pageCount = Math.ceil(userdetails.length / userPages)
    const changePage = (texted) => {
        setPageNumber(texted)
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <body class="page-user"><div class="topbar-wrap"><div class="topbar is-sticky"><div class="container">
                <Menu />
                {/* <div class="d-flex justify-content-between align-items-center"><ul class="topbar-nav d-lg-none"><li class="topbar-nav-item relative"><a class="toggle-nav" href="#"><div class="toggle-icon"><span class="toggle-line"></span><span class="toggle-line"></span><span class="toggle-line"></span><span class="toggle-line"></span></div></a></li></ul><a class="topbar-logo" href="index.html"><img src="images/logo-light2x.png" srcset="images/logo-light2x.png 2x" alt="logo" /></a><ul class="topbar-nav"><li class="topbar-nav-item relative"><span class="user-welcome d-none d-lg-inline-block">Welcome! Stefan Harary</span><a class="toggle-tigger user-thumb" href="#"><em class="ti ti-user"></em></a><div class="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"><div class="user-status"><h6 class="user-status-title">Token balance</h6><div class="user-status-balance">12,000,000 <small>TWZ</small></div></div><ul class="user-links"><li><a href="profile.html"><i class="ti ti-id-badge"></i>My Profile</a></li><li><a href="#"><i class="ti ti-infinite"></i>Referral</a></li><li><a href="activity.html"><i class="ti ti-eye"></i>Activity</a></li></ul><ul class="user-links bg-light"><li><a href="sign-in.html"><i class="ti ti-power-off"></i>Logout</a></li></ul></div></li></ul></div> */}

            </div></div>
                <Header />

            </div>
                <div class="page-content">
                    <div class="container">
                        <div class="card content-area">
                            <div class="card-innr">
                                <div class="card-head">
                                    <div>

                                        <h4 class="card-title" style={{ float: 'left', marginBottom: '10px' }} >User List</h4>
                                        <input type='text' class='input-bordered w-25' style={{ float: 'right', marginBottom: '10px' }} placeholder='Search by Email, Name' onChange={(e) => setFilter(e.target.value)} />
                                    </div>
                                </div>
                                <table class="data-table  user-list">
                                    <thead>
                                        <tr class="data-item data-head">
                                            <th class="data-col dt-user">User</th>
                                            <th class="data-col dt-email">Email</th>
                                            <th class="data-col dt-token">Tokens</th>
                                            <th class="data-col dt-verify">Verified Status</th>
                                            {/* <th class="data-col dt-login">Last Login</th> */}
                                            <th class="data-col dt-status">
                                                <div class="dt-status-text">Status</div>
                                            </th>
                                            <th class="data-col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userdminDetails}
                                    </tbody>
                                </table>
                                <div><ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}

                                    onPageChange={page => setPageNumber(page.selected)}

                                    pageCount={pageCount}
                                    breakLabel={'...'}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1'}
                                /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* <div class="footer-bar"><div class="container"><div class="row align-items-center justify-content-center"><div class="col-md-8"><ul class="footer-links"><li><a href="#">Whitepaper</a></li><li><a href="faq-page.html">FAQs</a></li><li><a href="regular-page.html">Privacy Policy</a></li><li><a href="regular-page.html">Terms of Condition</a></li></ul></div><div class="col-md-4 mt-2 mt-sm-0"><div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x"><div class="copyright-text">© 2021 MambaVerse LLC.</div><div class="lang-switch relative"><a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a><div class="toggle-class dropdown-content dropdown-content-up"><ul class="lang-list"><li><a href="#">Fr</a></li><li><a href="#">Bn</a></li><li><a href="#">Lt</a></li></ul></div></div></div></div></div></div></div> */}
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
