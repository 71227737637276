import React, { useState, useEffect } from 'react'
import Header from '../header/index'
import Footer from '../Footer/index'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import axios from 'axios'
import Moment from 'react-moment'
import { BASE_URL } from '../../useContext/walletContext'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Button, Modal } from 'react-bootstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import SocailMedia from '../Socialbar/index'


function Index() {
    const [data, setData] = useState([])
    const [transcation, setTransaction] = useState({})
    const [pageNumber, setPageNumber] = useState(0)
    const history = useNavigate()
    const [walletAddress, setWalletAddress] = useState('')
    const [show, setShow] = useState(false);
    const [tsnxmanual, setTsnxmanual] = useState('')
    const [sendermanual, setSendermanual] = useState('')
    const handleClose = () => setShow(false);
    const [orderid, setOrderid] = useState('')
    const handleShow = (ele) => {
        console.log("ele", ele)
        setOrderid(ele)
        setShow(true);
    }

    useEffect(() => {
        gettrancation()
        getwalletAddressforuser()
    }, [])
    const getwalletAddressforuser = async () => {
        const sessionrole = await window.sessionStorage.getItem('WalletAddress')
        setWalletAddress(sessionrole)

    }

    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/tsxndetailsfor`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            console.log("hello", res.data)
            setData(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    const showdetails = (ele) => {
        console.log("ele", ele)
    }
    // console.log(data)
    console.log(transcation)

    const userPages = 10
    const pageVisited = pageNumber * userPages

    const displayTransactionList = data.slice(pageVisited, pageVisited + userPages).map((ele, index) => {
        const fromfirst = ele?.transactionHash?.toString().substring(0, 4)
        const fromlast = ele?.transactionHash?.toString().substring(ele.transactionHash.length - 4)
        const hash = ele?.orderID?.toString()?.substring(0, 4)
        const lasthst = ele?.orderID?.toString()?.substring(ele.orderID.toString().length - 4)
        console.log("lasthst", lasthst)
        return (
            <>
                <tr class="data-item">
                    <td class="data-col dt-tnxno"><div class="d-flex align-items-center">{ele.transactionStats === 'Pending' && ele.transactionHash !== '' ? <div class="data-state data-state-progress"><span class="d-none">Progress</span></div> : ele.transactionStats === 'Accepted' ? <div class="data-state data-state-approved"><span class="d-none">Approved</span></div> : ele.transactionHash === '' ? <div class="data-state data-state-pending"><span class="d-none">Pending</span></div> : <div class="data-state data-state-canceled"><span class="d-none">Canceled</span></div>}<div class="fake-class"><span class="lead tnx-id">OD{hash}...{lasthst}</span><span class="sub sub-date"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.createdAt)} /></span></div></div></td>

                    <td class="data-col dt-token">
                        <span class="lead token-amount"><NumberFormat
                            thousandsGroupStyle="thousand"
                            // format="$#########0"
                            value={ele?.mambaToken == null ? 0 : ele.mambaToken}
                            prefix=""
                            decimalSeparator="."
                            displayType="text"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={true}
                            suffix={0} /></span><span class="sub sub-symbol">MAMBA</span></td>

                    <td class="data-col dt-amount"><span class="lead amount-pay">{ele.ethAmount != null ? ele.ethAmount : ele.maticAmount != null ? ele.maticAmount : ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? '...' : ele.usdAmount}</span><span class="sub sub-symbol">{ele.ethAmount != null ? "ETH" : ele.maticAmount != null ? "MATIC" : ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "" : "USD"} <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 ${ele.transactionHash == 'Referral Bonus' ? "referral Bonus" : ele.ethAmount != null ? "ETH" : ele.maticAmount != null ? "MATIC" : "USD"} = ${ele.transactionHash == 'Referral Bonus' ? "..." : ele.ethAmount != null ? ele.usdCrrentAmount ?? 0 : ele.maticAmount != null ? ele.usdMaticCrrentAmount ?? 0 : '20000'} USD`}></em></span></td>

                    {/* <td class="data-col dt-amount"><span class="lead amount-pay">{ele.maticAmount === null ? "..." : ele.maticAmount}</span><span class="sub sub-symbol">MATIC<em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 MATIC = ${ele.usdMaticCrrentAmount} USD`}></em></span></td> */}

                    {/* <td class="data-col dt-amount"><span class="lead amount-pay">{ele.usdAmount === null ? "..." : ele.usdAmount}</span><span class="sub sub-symbol">USD <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 USD = 20000 MAMBA`}></em></span></td> */}

                    {/* <td class="data-col dt-usd-amount"><span class="lead amount-pay">245.52</span><span class="sub sub-symbol">USD <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="1 ETH = 350.54 USD"></em></span></td> */}
                    <td class="data-col dt-account"><a href={ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "/" : ele.ethAmount === null && ele.usdAmount === null ? `https://polygonscan.com/tx/${ele.transactionHash}` : ele.maticAmount === null && ele.usdAmount === null ? `https://etherscan.io/tx/${ele.transactionHash}` : "/"} class="lead user-info linkactive" target="_blank" >{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "Referral " : fromfirst}{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "" : "..."}{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "Bonus" : fromlast}</a>
                        <span class="sub sub-date copy-wrap mgb-0-5x"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.createdAt)} /><em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px' }} onClick={() => { navigator.clipboard.writeText(ele.transactionHash) }}
                        ></em></span>
                        <span></span></td>

                    <td class="data-col dt-type"><span class="dt-type-md badge badge-outline badge-success badge-md abhimanyu">{ele.transactionHash === "Referral Bonus" ? "Bonus" : "Purchase"}</span><span class="dt-type-sm badge badge-sq badge-outline badge-success badge-md">P</span></td>
                    <td class="data-col "><a onClick={() => { setTransaction(ele) }} data-toggle="modal" data-target="#transaction-details" class="btn btn-xs btn-icon bg-dark" ><em class="ti ti-eye text-success"></em></a> </td>
                    <td class="data-col ">{ele.transactionHash === '' ? <><div class="relative d-inline-block d-md-none"><a href="#" class="btn btn-light-alt btn-xs btn-icon toggle-tigger"><em class="ti ti-more-alt"></em></a><div class="toggle-class dropdown-content dropdown-content-center-left pd-2x"><ul class="data-action-list"><li><a href="#" class="btn btn-auto btn-primary btn-xs"><span>Pay <span class="d-none d-xl-inline-block">Now</span></span>
                        <em class="ti ti-wallet"></em></a></li>
                        <li><a href="#" class="btn btn-danger-alt btn-xs btn-icon"><em class="ti ti-trash"></em></a></li>
                    </ul></div></div>
                        <ul class="data-action-list d-none d-md-inline-flex" onClick={() => handleShow(ele.orderID)}><li><a class="btn btn-auto btn-primary btn-xs"><span>Add  <span class="d-none d-xl-inline-block">Details</span></span><em class="ti ti-wallet"></em></a></li>

                        </ul>
                    </> : null}</td>

                </tr>
            </>
        )
    })

    const pageCount = Math.ceil(data.length / userPages)
    const changePage = (texted) => {
        setPageNumber(texted)
    }

    const transcationdetailsManualupdate = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            transactionHash: tsnxmanual,
            senderID: sendermanual
        }
        axios.put(`${BASE_URL}/api/transactionmaunalupdate/${orderid}`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            console.log(res.data)
            NotificationManager.success(" Successfully payment")
            // history('/Transactions')
            handleClose()
            gettrancation()
            // window.location.reload()
            // console.log('hello')

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }



    return (
        <div>
      
            <Helmet>
        <title>Transactions | MambaVerse - Bridge between physical and virtual worlds within the decentralized and open-source virtual world</title>
        <meta name="description" content="MambaVerse is a multi-faceted economy with P2P, B2C, & B2B marketplace which include collectibles, gaming items, and other virtual goods backed by blockchain technology." />
    </Helmet> 
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />
                           
                        </div>

                    </div>

                    <Header />
                   

                </div>
               
                <div class="page-content"><div class="container"><div class="card content-area"><div class="card-innr">
                    <div class="card-head">
                        </div>
                    {data.length !== 0 ? <><table class="data-table dt-init user-tnx"><thead>
                        <tr class="data-item data-head">
                            <th class="data-col dt-tnxno">Order ID</th>
                            <th class="data-col dt-token">MAMBA</th>
                            <th class="data-col dt-amount">Amount</th>
                            
                            <th class="data-col dt-account">Txns</th>
                            <th class="data-col dt-type">
                                <div class="dt-type-text">Type</div></th>
                            <th class="data-col">Details </th>
                            <th class="data-col"> </th></tr></thead>
                        <tbody>
                            {displayTransactionList}
                        </tbody></table>
                        <div><ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}

                            onPageChange={page => setPageNumber(page.selected)}

                            pageCount={pageCount}
                            breakLabel={'...'}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            nextClassName={'page-item next'}
                            previousClassName={'page-item prev'}
                            previousLinkClassName={'page-link'}
                            pageLinkClassName={'page-link'}
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-center pr-1'}
                        /></div>
                    </> :
                        <div class='text-center'>
                            <h2>No Data  Transaction  Available </h2>
                        </div>
                    }  </div></div></div></div>

                {/*********************  transaction***********************  */}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter "

                    show={show} onHide={handleClose}>

                    <Modal.Body>
                       
                        <div>
                            <h4 class="popup-title">Confirm Your Payment</h4><p class="lead abhimanyu">Your Order no. <strong class='deepak'>OD{orderid}</strong> has been placed successfully. </p><p>The tokens will be send to your wallet only after your transaction gets confirmed and approved by our team.</p><p>To <strong>speed up verifcation</strong> process please enter <strong>your wallet address</strong> from where you’ll transferring your ethereum to our address.</p><div class="input-item input-with-label">
                                <label for="token-address" class="input-item-label deepak">Enter your wallet address</label>
                                <input type='text' name='sender' id='sender' class="input-bordered" onChange={(e) => setSendermanual(e.target.value)} />

                                <label for="token-address " class="input-item-label deepak  mt-2">Your Txn Hash(Etherscan or Polygonscan)
                                </label>
                                <input type='text' name='tsnx' id='tsnx' class="input-bordered" type='text' onChange={(e) => setTsnxmanual(e.target.value)} />
                                {console.log(tsnxmanual)}
                            </div>
                            <ul class="d-flex flex-wrap align-items-center guttar-30px">
                                <li><a href="#"  onClick={transcationdetailsManualupdate} class="btn btn-primary">Confirm Payment</a></li>
                                    </ul>
                            <div class="gaps-2x"></div><div class="gaps-1x d-none d-sm-block"></div><div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>In case you send a different amount, number of MAMBAtokens will update accordingly.</p></div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/********************* END  transaction***********************  */}
                <SocailMedia />
                <Footer />

                <div class="modal fade" id="transaction-details" tabindex="-1"><div class="modal-dialog modal-dialog-lg modal-dialog-centered"><div class="modal-content"><a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a><div class="popup-body popup-body-lg"><div class="content-area"><div class="card-head d-flex justify-content-between align-items-center"><h4 class="card-title mb-0">Transaction Details</h4></div><div class="gaps-1-5x"></div><div class="data-details d-md-flex"><div class="fake-class"><span class="data-details-title deepak">Txn Date</span><span class="data-details-info abhimanyu"><Moment format="MM-DD-YYYY HH:mm" date={new Date(transcation.createdAt)} /></span></div><div class="fake-class"><span class="data-details-title deepak">Txn Status</span>{transcation.transactionStats === 'Pending' && transcation.transactionHash !== "" ? <span class="badge badge-success ucap">Pending</span> : transcation.transactionStats === 'Accepted' ? <span class="badge badge-success ucap">Accepted</span> : transcation.transactionHash === '' ? <span class="badge badge-success ucap">N/A</span> : <span class="badge badge-success ucap">Rejected</span>}</div><div class="fake-class"><span class="data-details-title deepak">Txn Approved Note</span>{transcation?.transactionadmindate ? <Moment format="MM-DD-YYYY HH:mm" date={new Date(transcation?.transactionadmindate)} /> : "...."}</div></div><div class="gaps-3x"></div><h6 class="card-sub-title deepak">Transaction Info</h6>
                    <ul class="data-details-list">
                        <li><div class="data-details-head deepak">Transaction Type</div><div class="data-details-des"><strong class='abhimanyu'>Purchase</strong></div></li>
                        <li>
                            <div class="data-details-head deepak">Gateway</div>
                            <div class="data-details-des">{transcation.transactionHash == 'Referral Bonus' ? "Referral Bonus" : transcation.ethAmount == null && transcation.usdAmount == null && transcation.transactiontype == "online" ? <strong class='abhimanyu'>Matic <small class='abhimanyu'>-  MetaMask</small></strong> : transcation.maticAmount == null && transcation.usdAmount == null && transcation.transactiontype == "online" ? <strong class='abhimanyu'>Ethereum <small class='abhimanyu'>- MetaMask</small></strong> : transcation.ethAmount == null && transcation.transactiontype == "Manual" ? <strong class='abhimanyu'>Matic <small class='abhimanyu'>-  Offline</small></strong> : transcation.ethAmount == null && transcation.maticAmount == null ? <strong class='abhimanyu'>USD <small class='abhimanyu'>-{transcation?.transactionHash?.substring(0, 3) === 'txn' ? "Stripe" : "paypal"} </small></strong> : <strong class='abhimanyu'>Ethereum <small class='abhimanyu'>-  Offline</small></strong>}</div>
                        </li>
                        <li><div class="data-details-head deepak">{transcation?.ethAmount == null && transcation?.maticAmount == null ? "Email" : "Deposit From"}</div><div class="data-details-des"><a href={transcation.ethAmount === null && transcation.ethAmount === null && transcation.ethAmount === null ? "...." : transcation.ethAmount === null ? `https://polygonscan.com/address/${transcation.senderID}` : `https://etherscan.io/address/${transcation.senderID}`} class='abhimanyu' target='_blank'>{transcation.senderID}  </a><span><em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px', color: '#74fffa' }} onClick={() => { navigator.clipboard.writeText(transcation.senderID) }}
                        ></em></span></div></li>
                        <li>
                            <div class="data-details-head deepak">{transcation?.ethAmount == null && transcation?.maticAmount == null ? "Txn ID" : "Txn Hash"}</div><div class="data-details-des"><a href={transcation?.transactionHash == 'Referral Bonus' ? "/" : transcation.ethAmount === null ? `https://polygonscan.com/tx/${transcation.transactionHash}` : `https://etherscan.io/tx/${transcation.transactionHash}`} class='abhimanyu' target='_blank'>{transcation.transactionHash}</a> <span><em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px', color: '#74fffa' }} onClick={() => { navigator.clipboard.writeText(transcation.transactionHash) }}
                            ></em></span></div></li>

                        {transcation.ethAmount == null && transcation.maticAmount == null ? null : <><li><div class="data-details-head deepak">Deposit To</div><div class="data-details-des abhimanyu"><a href={transcation.ethAmount === null ? `https://polygonscan.com/address/0x9a418F346B26df2FEA522E955F641F3Cc70456d9` : `https://etherscan.io/address/0x9a418F346B26df2FEA522E955F641F3Cc70456d9`} target='_blank'>{transcation.transactionHash == 'Referral Bonus' ? "...." : "0x9a418F346B26df2FEA522E955F641F3Cc70456d9"}</a> <span><span>{transcation.transactionHash == 'Referral Bonus' ? "" : <em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px', color: '#74fffa' }} onClick={() => { navigator.clipboard.writeText('0x9a418F346B26df2FEA522E955F641F3Cc70456d9') }}
                        ></em>}</span></span></div></li> </>}

                        <li><div class="data-details-head deepak">Token</div><div class="data-details-des abhimanyu"><NumberFormat
                            thousandsGroupStyle="thousand"
                            // format="$#########0"
                            value={transcation.mambaToken}
                            prefix=""
                            decimalSeparator="."
                            displayType="text"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={true}
                            suffix={0} />MAMBA</div></li>
                        <li>
                            <div class="data-details-head deepak">Polygon Wallet Address <span class="sub sub-symbol"> <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`User's wallet address to receive ERC20 polygon token.`}></em></span></div>
                            <div class="data-details-des abhimanyu">{walletAddress !== "" ? walletAddress : ""}
                            </div>
                        </li>
                    </ul>

                    {transcation.AdminTransactionWallet ? <><h6 class="card-sub-title deepak mt-2">Admin Approved</h6> <ul class="data-details-list">
                        <li><div class="data-details-head deepak">AdminTransactionWallet</div><div class="data-details-des"><strong class='abhimanyu'>{transcation.AdminTransactionWallet}</strong></div></li>
                        <li>
                            <div class="data-details-head deepak">AdminTransactionHash</div>
                            <div class="data-details-des abhimanyu">{transcation.AdminTransactionHash}</div>
                        </li>
                    </ul></> : null}
                    
                </div></div></div></div></div><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
