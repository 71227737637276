import { SpinnerCircular } from 'spinners-react';
import React, { useState, useEffect, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Img1 from '../../images/ath-gfx.png'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'
import MambaPay from '../../images/mamba.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleLogin } from 'react-google-login';
import { FacebookProvider, Login } from 'react-facebook';
import { faEye } from "@fortawesome/free-solid-svg-icons";

import SocailMedia from '../Socialbar/index'
import { StoreContext1 } from '../../useContext/pathContext'
import './singin.css'
const eye = <FontAwesomeIcon icon={faEye} />;


function Index(props) {
    const [email, setEmail] = useState('')
    // const context = useContext()
    const [password, setPassword] = useState('')
    const history = useNavigate()
    const location = useLocation()
    const [passwordShownconfirm, setPasswordShownconfirm] = useState(false);
    const [loading, setLoading] = useState(false)
    const [remember, setRemember] = useState(false)
    const togglePasswordVisiblityconfirm = () => {
        setPasswordShownconfirm(passwordShownconfirm ? false : true);
    };
    const customStyle = {
        background: 'transparent',
        padding: '8px 20px 8px 20px',
        border: "1px solid #d2dde9",
        fontSize: '14px',
        boxShadow: 'none',
        borderRadius: '4px',
        position: 'rerelativel',
        fontWeight: '500'

    }

    useEffect(() => {

        let userRole
        // context.setPath({newdata:props?.location?.state?.prevPath})
        // consle.log("hello")
        // console.log("path",props?.location?.state?.prevPath)
        const email = localStorage.getItem('email')
        const password = localStorage.getItem('password')

        userRole = window.sessionStorage.getItem("role")
      
        if (userRole == 'admin') {
            history('/index')
        } else if (userRole == 'user') {
            history('/home')
        } else {
            history(location.pathname)
        }
        setEmail(email)
        setPassword(password)

    }, [])
    const onsubmitedform = (e) => {
        e.preventDefault()
        const data = {
            email: email,
            password: password
        }
        //  remember ? (localStorage.setItem('email', email), localStorage.setItem('password', password)) : null
       
        setLoading(true)
        axios.post(`${BASE_URL}/api/login-ico`, data).then((res) => {
           

            if (res?.data?.user?.AccountStatus === "Suspend") {
                NotificationManager.error("Your Account Suspended")
            } else if (res?.data?.user?.role === 'admin') {
                setLoading(false)
                history('/index')
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();
            } else if (res?.data?.user?.TwoAuthEnabled === true) {
                window.sessionStorage.setItem("index", res?.data?.user?._id ?? "")
                setLoading(false)
                history('/Twoauth')
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();

            } else if (res?.data?.user?.AccountVerify === false) {
                window.sessionStorage.setItem("index", res?.data?.user?._id ?? "")
                history('/VerifyEmail')
            } else {
                setLoading(false)

                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/home')
                // window.location.reload();

            }


        }).catch((error) => {
            
            setLoading(false)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }


    const googleLogin = () => {
        axios.get(`${BASE_URL}/api/google`).then((res) => {
        }).catch((error) => {
            console.log(error)
        })
    }

    const responseGoogle = async (response) => {
       
        const tokendata = {
            tokenId: await response.tokenId
        }
       

        axios.post(`${BASE_URL}/api/loginwithGoogle`, tokendata).then((res) => {

            if (res?.data?.user?.AccountStatus === "Suspend") {
                NotificationManager.error("Your Account Suspended")
            } else if (res?.data?.user?.role === 'admin') {
                setLoading(false)
                history('/index')
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();
            } else if (res?.data?.user?.TwoAuthEnabled === true) {
                setLoading(false)
                history('/Twoauth')
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();

            } else if (res?.data?.user?.KycForm === true) {
                setLoading(false)
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/home')
                // window.location.reload();

            } else {
                setLoading(false)
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/KycApplication')
                window.location.reload();

            }


        }).catch((error) => {
            console.log(error)
            NotificationManager.error(error?.response?.data?.error ?? "Connection Failed")

        })
    }

    const responseGoogleFailure = (error) => {
        console.log("error", error)
    }


    const handleResponse = async (response) => {
        const tokendata = {
            accessToken: await response.tokenDetail.accessToken,
            userID: await response.tokenDetail.userID
        }

        axios.post(`${BASE_URL}/api/facebooklogin`, tokendata).then((res) => {
           
            if (res?.data?.user?.AccountStatus === "Suspend") {
                NotificationManager.error("Your Account Suspended")
            } else if (res?.data?.user?.role === 'admin') {
                setLoading(false)
                history('/index')
                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                window.location.reload();
            } else {
                setLoading(false)

                window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
                window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
                window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
                window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
                window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
                window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
                window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
                NotificationManager.success(res?.data?.message ?? "Credentials Verified.")
                history('/home')
                window.location.reload();

            }

        }).catch((error) => {
            console.log(error)
            NotificationManager.error(error?.response?.data?.error ?? "Connection Failed")

        })
    }

    const handleError = (error) => {
        console.log('error', error)
    }


    return (
        <div>
            {/* <SocailMedia /> */}
            <body class="page-ath">
                <div class="page-ath-wrap">
                    <div class="page-ath-content">
                        <div class="page-ath-header">
                            <Link to='/' class="page-ath-logo"><img src={MambaPay} alt="logo" /></Link></div><div class="page-ath-form"><h2 class="page-ath-heading deepak">Sign in <small class='text-white'>with your MambaVerse Account</small></h2>
                            <form onSubmit={onsubmitedform}>
                                <div class="input-item">
                                    <input type="text" placeholder="Your Email" class="input-bordered" value={email} onChange={(e) => { setEmail(e.target.value) }} /></div>
                                <div class="input-item"><input type={passwordShownconfirm ? "text" : "password"} placeholder="Password" class="input-bordered" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                    <i onClick={togglePasswordVisiblityconfirm} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                </div>
                                <div class="d-flex justify-content-between align-items-center"><div class="input-item text-left"><input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term" onChange={() => setRemember(true)} />
                                    <label for="agree-term">Remember Me</label>{/* <label for="remember-me text-white">Remember Me</label> */}</div><div><Link to='/ForgetPassword' href="forgot.html" class='text-white'>Forgot password?</Link><div class="gaps-2x"></div></div></div>
                                <button class="btn btn-primary btn-block" disabled={loading}>{loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Sign in"}</button></form>
                            <div class="sap-text"><span>Or Sign In With</span></div>
                            <ul class="row guttar-20px guttar-vr-20px">
                              
                                <li class="col" > <GoogleLogin
                                    clientId="472022756240-2u388hij51qu39shuo8gm0tqs0699htd.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} class='btn btn-outline btn-dark btn-google btn-block' ><em class="fab fa-google"></em><span class='text-white'>Google</span></button>
                                    )}
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogleFailure}
                                />
                                </li>
                            </ul>
                            <div class="gaps-2x"></div>
                            <div class="gaps-2x"></div><div class="form-note text-white">Don’t have an account? <Link exact to='/SignUp'> <strong class='deepak' >Sign up here</strong></Link></div></div>
                      
                    </div>

                    <div class="page-ath-gfx">
                        <div class="w-100 d-flex justify-content-center">
                            <div class="col-md-12 col-xl-12">
                                <div class='text-center'><a href='https://mambaplatform.com/' class='btn btn-primary mb-3' target='_blank' >Visit MambaVerse</a></div>


                                <div class='text-center'>

                                    <iframe class='iframewidthheight' src="https://mambaplatform.com/" frameborder="" >
                                    </iframe>

                                </div>

                            </div></div>            </div></div><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
