import React, { useState, useEffect, useContext } from 'react'
import '../../assets/css/vendor.bundle49f7.css?ver=104.css'
import '../../assets/css/style49f7.css'
import { NavLink, Link, useLocation } from 'react-router-dom'
import './navlink.css'
import '../MaiCustom.css'
import useMetaMask from '../../metamask';
import Web3 from 'web3'
import { StoreContext } from '../../useContext/walletContext'
import { StoreContext1 } from '../../useContext/pathContext'

import { Button, Modal } from 'react-bootstrap'
import metamask from '../../images/metamask.svg'
import ErrorMessage from "./Errormessage";

function Index(props) {
    const { data } = props
    // console.log("headerdata",props)
    const context = useContext(StoreContext)
    const contextpath = useContext(StoreContext1)

    const { chainId, connect, disconnect, isActive, account } = useMetaMask()
    const [inputvalue, setInputValue] = useState()
    const [inputvaluetoken, setInputValuetoken] = useState()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    let location = useLocation();
    const [balance, setBalance] = useState()
    const [adminrole, setAdminrole] = useState('initialState')
    const [address, setAddress] = useState(account)
    const [chainname, setchainname] = useState(chainId)
    const [chainnetwork, setChaninNetwork] = useState()
    console.log("history", location.pathname)

    const [error, setError] = useState();
    const [getbalance, setGetbalance] = useState(false)


    const Activitytoggle = context.headerTitle ? '' : 'navbar1-mobile active';
    const getadminrole = async () => {
        const sessionrole = await window.sessionStorage.getItem('role')
        setAdminrole(sessionrole)
        // setAddress(account)
    }
    console.log("chainId", chainId)

    useEffect(() => {
        if (data === '/Kycthnaks') {
            setShow(true)
            contextpath.setPath({ ...contextpath.path, kycform: "" })
        }
        getadminrole()
    }, [])
    useEffect(() => {
        isactiveBalance()
        context.setHeaderTitle({ walletbalnce: inputvalue })
        context.setHeaderTitle({ wallettoken: inputvaluetoken })
    }, [connect])
    const isactiveBalance = () => {
        isActive ? getBalanceUsingWeb3(account) : console.log("notactive")
        isActive ? chnainname() : console.log("notactive")
    }

    async function getBalanceUsingWeb3(account) {
        var web3 = window.web3;
        try {
            if (typeof web3 !== 'undefined') {
                web3 = new Web3(web3.currentProvider);
                web3.eth.getBalance(account).then((balance) => {
                    // alert("Balance: " + web3.utils.fromWei(balance, 'ether'));
                    setBalance(+ web3.utils.fromWei(balance, 'ether'))
                    console.log("Balance: " + balance);
                });
            }
        } catch (error) {
            console.log('Error on connecting: ', error)
        }
    }
    let network;
    const chnainname = () => {
        if (chainId == 3) {
            setChaninNetwork('ETH')
        } else if (chainId == 80001) {
            setChaninNetwork('MATIC')
        } else if (chainId == 137) {
            setChaninNetwork('MATIC')
        } else if (chainId == 1)
            setChaninNetwork('ETH')
        else {
            setChaninNetwork('defulte Network')
        }
    }

    useEffect(() => {
        isactiveBalance()
       
    }, [connect])
    return (
        <div>
            <div class={`navbar1 ${Activitytoggle}`} >
                <div class="container">
                    <div class="navbar1-innr">
                        {adminrole === 'admin' ? <><ul class="navbar1-menu">
                            <li><NavLink exact to='/Index' className={location.pathname === '/Index' ? 'navLinkActive' : ''}><em class="ikon ikon-dashboard"></em> Dashboard</NavLink></li>
                            <li ><NavLink exact to='/UserList' href="buy-token.html" className={location.pathname === '/UserList' ? 'navLinkActive' : ''}><em class="ikon ikon-coins"></em> UserList</NavLink></li>
                            <li ><NavLink exact to='/Transcation' href="ico-distribution.html" className={location.pathname === '/Transcation' ? 'navLinkActive' : ''}><em class="ikon ikon-distribution"></em>Transaction</NavLink></li>
                            <li ><NavLink exact to='/KycList' href="transactions.html" className={location.pathname === '/KycList' ? 'navLinkActive' : ''}><em class="ikon ikon-transactions"></em> KycList</NavLink></li>
                            {/* <li ><NavLink exact to='/Profile' href="profile.html" className={location.pathname === '/Profile' ? 'navLinkActive' : ''}><em class="ikon ikon-user"></em> Profile</NavLink></li> */}
                        </ul>
                            {/* <ul class="navbar1-btns">
                                <li><NavLink exact to='/KycApplication' href="kyc-application.html" className="btn btn-sm btn-outline btn-light"><em class=" ti ti-files"></em><span>KYC Application</span></NavLink></li>
                                <li className="d-none"><span class="badge badge-outline badge-success badge-lg"><em className="text-success ti ti-files mgr-1x"></em><span class="text-success">KYC Approved</span></span></li>
                            </ul> */}
                        </> : <><ul class="navbar1-menu">
                            <li><NavLink exact to='/home' className={location.pathname === '/home' ? 'navLinkActive' : ''}><em class="ikon ikon-dashboard"></em> Dashboard</NavLink></li>
                            <li ><NavLink exact to='/BuyToken' href="buy-token.html" className={location.pathname === '/BuyToken' ? 'navLinkActive' : ''}><em class="ikon ikon-coins"></em> Buy Tokens</NavLink></li>

                            <li ><NavLink exact to='/Transactions' href="transactions.html" className={location.pathname === '/Transactions' ? 'navLinkActive' : ''}><em class="ikon ikon-transactions"></em> Transactions</NavLink></li>
                            <li ><NavLink exact to='/Profile' href="profile.html" className={location.pathname === '/Profile' ? 'navLinkActive' : ''}><em class="ikon ikon-user"></em> Profile</NavLink></li>
                           {/*  <li ><NavLink exact to='/BuyNfts' href="profile.html" className={location.pathname === '/BuyNfts' ? 'navLinkActive' : ''}><em class="ikon ikon-user"></em> BuyNfts</NavLink></li> */}
                            {/* <li>
                                    <span>{isActive ? balance : console.log("jsdgjhkgdjhgsd")}</span><span>{isActive ? chainnetwork : console.log("jsdgjhkgdjhgsd")}</span>
                                </li> */}
                        </ul>
                            {!isActive ? <ul class="navbar1-btns">

                                <button class='btn btn-primary' onClick={handleShow}><img src={metamask} alt="MetaMask" width="20" height="20" />&nbsp;MetaMask</button>


                                <Modal
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter "
                                    centered
                                    show={show} onHide={handleClose}>
                                    <Modal.Body>
                                        <div class='text-right'>

                                        </div>
                                        <p> Connect With MetaMask</p>
                                        {isActive ? <button class="btn btn-primary btn-start w-100" onClick={disconnect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Disconnect With MetaMask</button> : <button class="btn btn-primary btn-start w-100" onClick={connect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Connect With MetaMask</button>}

                                        {isActive ? <> <div class='row'> <div class='col-6'>
                                            <div class='mt-3'>
                                                <h6 class="card-sub-title deepak">Wallet Balance</h6>
                                                <span class='abhimanyu'>{chainnetwork} = {balance}</span>
                                            </div>
                                        </div>
                                            <div class='col-6'>
                                                <div class='mt-3'>
                                                    <h6 class="card-sub-title deepak">Wallet Address</h6>
                                                    <span class='abhimanyu'>{account.substring(0, 6)}...{account.substring(account.length - 6)}<span></span></span>

                                                </div>

                                            </div></div></> : null}

                                        <div className="mt-4">
                                        </div>


                                    </Modal.Body>

                                </Modal>

                            </ul> :
                                <ul class="navbar1-menu">
                                    <li><div class="MuiBox-root jss1204 jss1199">{chainnetwork}</div> </li>
                                    <li><div class="MuiBox-root jss1204 jss1120">{getbalance ? balance : (account.substring(0, 10))}<span>&nbsp;<em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px', color: '#74fffa' }} onClick={() => { navigator.clipboard.writeText(account) }}
                                    ></em></span></div> <div class="token-pay-currency" style={{ top: '10px', right: '15px' }}>
                                            <a href="#" class="link ucap link-light toggle-tigger"><i class="fas fa-chevron-down"></i></a>
                                            <div class="toggle-class dropdown-content">
                                                <ul class="dropdown-list toggle-tigger ">
                                                    <li><a onClick={disconnect} >Disconnect</a></li>
                                                    <li><a onClick={() => setGetbalance(!getbalance)}>{getbalance ? "Address" : "Balance"}</a></li>

                                                </ul>
                                            </div>
                                        </div> </li>
                                </ul>} </>}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Index
