import React, { useState, useCallback, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import qs from "qs";
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'

const tokenURL = `${BASE_URL}/api/create_link_token`;
const sendTokenURL = `${BASE_URL}/api/set_access_token`;

const Link = (props) => {
    const [data, setData] = useState("");
    const [token, setToken] = useState('')
    const [access, setAccess] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useNavigate()
    const [orderID, setOrderID] = useState('')
    const { usd, mamba, maticAmountcurrent, usdAmountcurrent } = props
    // const fetchToken = useCallback(async () => {
    //     const config = {
    //         method: "post",
    //         url: tokenURL,
    //     };
    //     const res = await axios(config);
    //     console.log(res)
    //     setData(res.data.link_token);
    // }, []);

    // useEffect(() => {
    //     fetchToken();
    // }, [fetchToken]);

    // const onSuccess = useCallback(async (token, metadata) => {

    //     // send token to server
    //     const config = {
    //         method: "post",
    //         url: sendTokenURL,
    //         data: qs.stringify({ public_token: token }),
    //         headers: { "content-type": "application/json" },
    //     };
    //     try {
    //         const response = await axios(config);
    //         console.log(response)
    //     } catch (error) {
    //         console.error(error);
    //     }

    // }, []);

    // const config = {
    //     token: data,
    //     onSuccess,
    // };

    const gettoken = async () => {
        const res = await axios.post(`${BASE_URL}/api/create_link_token`);
        const data = res.data.link_token
        setToken(data)
    }

    useEffect(() => {
        gettoken()
    }, [])

    // const { open, ready, err } = usePlaidLink(config);

    // if (err) return <p>Error!</p>;

    const onSuccess = (token, metadata) => {
        

        // **props.getAccessToken(token)**
        const data = {
            public_token: token
        }
        axios.post(`${BASE_URL}/api/exchange_public_token`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
           
            setAccess(res?.data?.resulte?.access_token)
            // getAccountDetails(res?.data?.resulte?.access_token)
            transcationdetailssave(res.data.resulte)
        }).catch((error) => {
            console.log("error", error)
        })
    }



    const config = {
        clientName: 'Your app name',
        env: 'production',
        product: ['auth', 'transactions'],
        token: token,
        onSuccess,
        // ...
    };

    const transcationdetailssave = async (order) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            mambaToken: mamba,
            ethAmount: "",
            maticAmount: "",
            usdAmount: usd,
            transactionHash: order.request_id,
            usdCrrentAmount: usdAmountcurrent,
            usdMaticCrrentAmount: maticAmountcurrent,
            senderID: order.accounts[0].account_id
        }
        axios.post(`${BASE_URL}/api/transaction-ico`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
         
            setOrderID(res.data.result.orderID)
           
            window.$('#pay-online').modal('hide');

            window.$('#pay-online').on('hidden.bs.modal', function () {
                window.$(".modal-backdrop").remove();
            })
            setTimeout(function () { handleShow() }, 1000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }


    function Transactionsme() {
        history('/Transactions')
        window.location.reload()
    }

    const { open, ready, error } = usePlaidLink(config);


    return (
        <div className='text-center'>
            <button class='btn btn-primary text-center' onClick={() => open()} disabled={!ready}>
                Connect a bank account
            </button>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter "

                show={show} onHide={handleClose}>

                <Modal.Body>

                    <div>
                        <div class="popup-body text-center"><div class="gaps-2x"></div>

                            <div class="pay-status pay-status-progress" style={{ color: "blue" }}><em class="ti ti-alarm-clock
"></em></div><div class="gaps-2x"></div>
                            <h3>We’re reviewing your payment.</h3>
                            {/* <Transcation getdatasuccess={getdatasuccess} /> */}
                            <p>We’ll review your transaction and get back to you within 6 hours. You’ll receive an email with the details of your contribution. Your Order ID is {orderID} </p>
                            <div class="gaps-2x"></div><button class="btn btn-primary" onClick={Transactionsme}  >View Transaction</button>
                            <div class="gaps-1x"></div></div>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    );
}

export default Link;