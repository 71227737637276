import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import MambaNetwork from '../../images/MambaNetwork.pdf'

function Index() {
    return (
        <div>
            <div class="footer-bar">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-8">
                            <ul class="footer-links">
                                <li><a href={MambaNetwork} download>Whitepaper</a></li>
                                <li><NavLink exact to='/Faqpage' href="faq-page.html">FAQs</NavLink></li>
                                <li><NavLink exact to='/Regularpage' href="regular-page.html">Privacy Policy</NavLink></li>
                                <li><NavLink exact to='/Term_and_Condition' href="regular-page.html">Terms & Condition</NavLink></li>
                            </ul>
                        </div>

                        <div class="col-md-4 mt-2 mt-sm-0">
                            <div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
                                <div class="copyright-text">&copy; 2022 MambaVerse LLC.</div>
                                {/* <div class="lang-switch relative">
                                    <a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em>
                                    </a>
                                    <div class="toggle-class dropdown-content dropdown-content-up">
                                        <ul class="lang-list">
                                            <li><a href="#">Fr</a></li>
                                            <li><a href="#">Bn</a></li>
                                            <li><a href="#">Lt</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
