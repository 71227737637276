import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";

import "./Accordion.css";

function Accordion(props) {
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)
  // const [active2, setActive2] = useState(false)
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const [height1, setHeight1] = useState("0px");
  const [height2, setHeight2] = useState("0px");
  const [height3, setHeight3] = useState("0px");
  const [height4, setHeight4] = useState("0px");

  useEffect(() => {
    console.log("Height for ", props.title, ": ", height);
  }, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  function toggleAccordion1() {
    setActive1(!active1);
    setHeight1(active1 ? "0px" : `${content.current.scrollHeight}px`);
  }

   function toggleAccordion2() {
     setActive2(!active2);
     setHeight2(active2 ? "0px" : `${content.current.scrollHeight}px`);
   } 

   function toggleAccordion3() {
    setActive3(!active3);
    setHeight3(active3 ? "0px" : `${content.current.scrollHeight}px`);
  } 
  function toggleAccordion4() {
    setActive4(!active4);
    setHeight4(active4 ? "0px" : `${content.current.scrollHeight}px`);
  } 

  return (
    <>
      <div className="accordion__section">
        <div
          className={`accordion ${active ? "active" : ""}`}
          onClick={toggleAccordion}
        >

          <p className="accordion__title font-600 flex items-center relative text-xs uppercase "><img className="w-7 h-7 mr-2" src='https://www.azuki.com/filtericons/White/Type.png' />TYPE</p>
          <span style={{ marginLeft: "20px" }}>{active ? "-" : "+"}</span>
        </div>
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className="accordion__content"
        >
          <div className="accordion__text" >
            <Input type='text' placeholder="Search..."></Input>
         
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> Human <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> RED <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> SPIRIT<span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">BLUE <span class="opacity-50">(9018)</span></label></div> 

          </div>
        </div>
      </div>

      <hr/>

      <div className="accordion__section">
        <div
          className={`accordion ${active1 ? "active1" : ""}`}
          onClick={toggleAccordion1}
        >
     
          <p className="accordion__title"><img className="w-7 h-7 mr-2" src='https://www.azuki.com/filtericons/White/Special.png'  />SPECIAL</p>
          <span style={{ marginLeft: "20px" }}>{active1 ? "-" : "+"}</span>
        </div>
        <div
          // ref={content}
          style={{ maxHeight: `${height1}` }}
          className="accordion__content"
        >
         <div className="accordion__text" >
            <Input type='text' placeholder="Search..."></Input>
         
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> FIREFLIES <span class="opacity-50">(9)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> BUTTERFLY <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">FOX FIRE<span class="opacity-50">(98)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">SMOKE <span class="opacity-50">(90)</span></label></div> 

          </div>
        </div>
      </div>

      <hr/>

      <div className="accordion__section">
        <div
          className={`accordion ${active2 ? "active2" : ""}`}
          onClick={toggleAccordion2}
        >
     
          <p className="accordion__title"><img className="w-7 h-7 mr-2" src='https://www.azuki.com/filtericons/White/Clothing.png' />CLOTHING</p>
          <span style={{ marginLeft: "20px" }}>{active2 ? "-" : "+"}</span>
        </div>
        <div
          // ref={content}
          style={{ maxHeight: `${height2}` }}
          className="accordion__content"
        >
         <div className="accordion__text" >
            <Input type='text' placeholder="Search..."></Input>
         
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">LIGHT KIMONO <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> BLUE KIMONO <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> GREEN YUKATA <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">TURQUOISE KIMONO <span class="opacity-50">(9018)</span></label></div> 

          </div>
        </div>
      </div>

      <hr/>

      <div className="accordion__section">
        <div
          className={`accordion ${active3 ? "active3" : ""}`}
          onClick={toggleAccordion3}
        >
     
          <p className="accordion__title"><img className="w-7 h-7 mr-2" src='https://www.azuki.com/filtericons/White/Offhand.png' />OFFHAND</p>
          <span style={{ marginLeft: "20px" }}>{active3 ? "-" : "+"}</span>
        </div>
        <div
          // ref={content}
          style={{ maxHeight: `${height3}` }}
          className="accordion__content"
        >
         <div className="accordion__text" >
            <Input type='text' placeholder="Search..."></Input>
         
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> Human <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> RED <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> SPIRIT<span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">BLUE <span class="opacity-50">(9018)</span></label></div> 

          </div>
        </div>
      </div>

      <hr/>

      <div className="accordion__section">
        <div
          className={`accordion ${active4 ? "active2" : ""}`}
          onClick={toggleAccordion4}
        >
     
          <p className="accordion__title"><img className="w-7 h-7 mr-2" src='https://www.azuki.com/filtericons/White/Hair.png' />HAIR</p>
          <span style={{ marginLeft: "20px" }}>{active4 ? "-" : "+"}</span>
        </div>
        <div
          // ref={content}
          style={{ maxHeight: `${height4}` }}
          className="accordion__content"
        >
         <div className="accordion__text" >
            <Input type='text' placeholder="Search..."></Input>
         
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> Human <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> RED <span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full"> SPIRIT<span class="opacity-50">(9018)</span></label></div> 
          <div class="flex px-2 items-center galleryinput"><input id="filter-Type-0" name="Type[]" type="checkbox" class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" value="Human"/><label for="filter-Type-0" class="ml-2 uppercase font-mono text-2xs pt-2 pb-1.5 text-sm opacity-60 w-full cursor-pointer h-full">BLUE <span class="opacity-50">(9018)</span></label></div> 

          </div>
        </div>
      </div>

      <hr/>
    </>
  );
}

export default Accordion;
