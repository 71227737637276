import React, { useState } from 'react'
import Img1 from '../../images/ath-gfx.png'
import { Link } from 'react-router-dom'
import MambaPay from '../../images/mamba.png'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'

function Index() {
    const history = useLocation()
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmpassword] = useState('')
    const newpath = history.pathname.split('/')
    const token = newpath.pop()
    const expriedate = newpath[2]

    
    const chnagepassword = async (e) => {
        e.preventDefault()
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            newpassword: confirmpassword,
            token: token,
            expriedate: expriedate
        }
        if (password !== confirmpassword) {
            NotificationManager.success("password and confirm password does not same")
        } else {
            axios.post(`${BASE_URL}/api/newpasswordreset`, data).then((res) => {
                
                NotificationManager.success("change password")
            }).catch((error) => {
                console.log(error?.response?.data?.message)
                NotificationManager.error(error?.response?.data?.message ?? "something Wrong")

            })
        }
    }


    return (
        <div>
            <body class="page-ath">
                <div class="page-ath-wrap">
                    <div class="page-ath-content">
                        <div class="page-ath-header">
                            <Link to='/' href="index.html" class="page-ath-logo">
                                <img src={MambaPay} alt="logo" />
                            </Link>
                        </div>
                        <div class="page-ath-form">
                            <h2 class="page-ath-heading">Reset password <span>If you forgot your password, well, then we’ll email you instructions to reset your password.</span></h2>
                            <form onSubmit={chnagepassword}>
                                <div class="input-item">
                                    <input type="password" placeholder="New Password" class="input-bordered" onChange={(e) => { setPassword(e.target.value) }} />
                                </div>
                                <div class="input-item">
                                    <input type="password" placeholder="Confirm Password" class="input-bordered" onChange={(e) => { setConfirmpassword(e.target.value) }} />
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div><button class="btn btn-primary btn-block"> Reset</button></div>
                                    <div>
                                        <Link
                                            to='/'>
                                            Return to login</Link>
                                    </div>
                                </div>
                                <div class="gaps-2x"></div>
                            </form>
                        </div>
                        <div class="page-ath-footer">
                            <ul class="footer-links">
                                <li>
                                    <Link to='/Regularpage'>
                                        Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to='/Regularpage' >
                                        Terms</Link>
                                </li>
                                <li>&copy; 2021 MambaVerse Pay LLC.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="page-ath-gfx">
                        <div class="w-100 d-flex justify-content-center">
                            <div class="col-md-8 col-xl-5"><img src={Img1} alt="image" /></div>
                        </div>
                    </div>
                </div>
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script>
            </body>
        </div>
    )
}

export default Index
