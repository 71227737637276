import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { injected } from './injected'
import { useWeb3React } from '@web3-react/core';

export const MetaMaskContext = React.createContext(null)

export const MetaMaskProvider = ({ children }) => {

    const { chainId, activate, account, library, connector, active, deactivate } = useWeb3React()

    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [time, setTime] = useState(false)

    console.log("chainId", chainId)
    console.log('isActive', isActive)
    console.log("active", active)

    // Init Loading
    useEffect(() => {
        // window.sessionStorage.setItem('active', false)
        sessionget()
        // connect().then((val) => {
        //     setIsLoading(false)
        // })
    }, [])

    const sessionget = async () => {
        const getactive = await window.sessionStorage.getItem('active') ?? false
        if (getactive == false) {
            console.log("false")
        } else {
            connect().then((val) => {
                setIsLoading(false)
            })
            console.log("hello")
        }
    }



    const handleIsActive = useCallback(() => {
        setIsActive(active)

    }, [active])

    useEffect(() => {
        handleIsActive()
    }, [handleIsActive])

    // Connect to MetaMask wallet
    const connect = async () => {
        getnble()
        // console.log('Connecting to MetaMask Wallet')

        try {
            await activate(injected)
            window.sessionStorage.setItem('active', true)

        } catch (error) {
            console.log('Error on connecting: ', error)
        }
    }

    const getnble = async () => {
        try {
            if (!window.ethereum)
                // throw new Error("No crypto wallet found. Please install it.");
                alert('No crypto wallet found. Please install it.')

            // await window.ethereum.send("eth_requestAccounts");
        } catch (err) {
            // setError(err.message);
            console.log(err.message)
        }
    }

    // Disconnect from Metamask wallet
    const disconnect = async () => {
        console.log('Deactivating...')
        try {
            await deactivate()
            window.sessionStorage.setItem('active', false)
        } catch (error) {
            console.log('Error on disconnecting: ', error)
        }
    }

    const values = useMemo(
        () => ({
            isActive,
            account,
            isLoading,
            connect,
            disconnect,
            chainId
        }),
        [isActive, isLoading]
    )

    return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>
}

export default function useMetaMask() {
    const context = React.useContext(MetaMaskContext)

    if (context === undefined) {
        throw new Error('useMetaMask hook must be used with a MetaMaskProvider component')
    }

    return context
}

