import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../useContext/walletContext'

function Index() {
    const history = useLocation()
    const navigate = useNavigate()
    const [token, settoken] = useState('')
    const [buttonActive, setButtonActive] = useState(false)

    const tokenverification = async () => {
        const newpath = await history.pathname.split('/').pop()
        await axios.put(`${BASE_URL}/api/verification/${newpath}`).then((res) => {

            settoken(res.data.message ?? "You have completed the Email Verification")
            setButtonActive(true)

        }).catch((error) => {
            console.log("error", error.response.data.message)
        })

    }
    const VerifyEmailAccound = async () => {
        const newpath = await history.pathname.split('/').pop()
        await axios.get(`${BASE_URL}/api/AfterEmailverify/${newpath}`).then((res) => {

            window.sessionStorage.setItem("Token", res?.data?.result?._id ?? "")
            window.sessionStorage.setItem("userName", res?.data?.result?.userName ?? "")
            window.sessionStorage.setItem("fullName", res?.data?.result?.fullName ?? "")
            window.sessionStorage.setItem("email", res?.data?.result?.email ?? "")
            window.sessionStorage.setItem("image", res?.data?.result?.image ?? "")
            window.sessionStorage.setItem("role", res?.data?.result?.role ?? "")
            // NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
            window.sessionStorage.setItem("WalletAddress", res?.data?.result?.WalletAddress ?? "")
            navigate('/KycApplication')
        }).catch((error) => {
            console.log("error", error?.response?.data?.message)
        })
    }

    useEffect(() => {
        tokenverification()
    }, [])

    return (
        <div>
            <div class="page-header page-header-kyc">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-7 text-center">
                            <h2 class="page-title"> Verify your Email </h2>
                            {/* <p class="large">Verify your Email for the process.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-9">
                            <div class="kyc-status card mx-lg-4">
                                <div class="card-innr">
                                    <div class="status status-thank px-md-5">
                                        <div class="status-icon"><em class="ti ti-check"></em></div>
                                        <span class="status-text large text-dark">{token === '' ? null : "Email Verified."}</span>
                                        <p class="px-md-5">  Please click on KYC button below to complete your KYC process.</p>
                                        {/* <Link to='/' href="profile.html" class="btn btn-primary">
                                            Back to Profile</Link> */}
                                        {buttonActive ? <button class='btn btn-primary' onClick={VerifyEmailAccound}>KYC Application</button> : null}
                                    </div>
                                </div>
                            </div>
                            <p class="text-light text-center mgmt-1x">If you have any questions, please contact our customer support team at  <a href="#">contact@mambaverse.io</a>.</p>
                            <div class="gaps-1x"></div>
                            <div class="gaps-3x d-none d-sm-block"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
