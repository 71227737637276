// import { lighten } from '@amcharts/amcharts4/.internal/core/utils/Colors'
import React from 'react'
import { Navigate, Route } from 'react-router'
import { login } from './loginSession'

const isRestricted = false

function Restricted(props) {
    if (login()) {
        return props.children
    } else {
        return <Navigate to="/" />
    }

    // if (isRestricted) {
    //     return (
    //         <Navigate to="/home" />

    //     )
    // }


    // return (
    //     <Element />

    // )
}

export default Restricted
