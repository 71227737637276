import React, { useState, useEffect } from 'react'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import Header from '../header/index'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../useContext/walletContext'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Footer from '../adminFooter/index'
import NumberFormat from 'react-number-format'

function Index() {

    const [data, setData] = useState([])
    const [transcation, setTransaction] = useState({})
    const history = useNavigate()
    const [pageNumber, setPageNumber] = useState(0)
    const [Transactionhash, setTransactionhash] = useState('')
    const [TranscationaddressWallet, setTranscationaddressWallet] = useState('')
    const [show, setShow] = useState(false);
    const [approved, setApproved] = useState()
    const [filters, setFilter] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = (ele) => {
        setShow(true)
        setApproved(ele)
    }


    useEffect(() => {
        gettrancation()
    }, [])


    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/tsxndetailsforadmin`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
          
            setData(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    /* ****************************admin Rejected************************** */
    const transcationdetailsAdminRejected = async (ele) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
       
        // const date = new Date()

        const data = {
            transactionStats: 'Rejected',
            orderID: ele
        }
        axios.put(`${BASE_URL}/api/transactionAdminupdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.error(`Successfully Rejected OD${ele}`)

            window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }

    /* ****************************End admin Rejected************************** */

    /* ****************************admin Accepted************************** */
    const transcationdetailsAdminAccepted = async (index) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
      
        const data = {
            transactionStats: 'Accepted',
            orderID: index
        }
        axios.put(`${BASE_URL}/api/transactionAdminupdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success(` Successfully Accepted OD${index} `)

            // window.location.reload()

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
        })
    }
    /* ****************************END admin Accepeted************************** */

    /* **************************** admin wallet andtoken **************************  */
    const transcationdetailsToken = async (index) => {
        if (TranscationaddressWallet == '' || Transactionhash === '') {
            NotificationManager.error("Please Fill the details")
        } else {
            const sessiontoken = await window.sessionStorage.getItem('Token')
           
            const data = {
                AdminTransactionWallet: TranscationaddressWallet,
                AdminTransactionHash: Transactionhash,
                orderID: index
            }
            axios.put(`${BASE_URL}/api/transactionAdmintoken`, data, {
                headers: {
                    userid: sessiontoken
                }
            }).then((res) => {
               
                transcationdetailsAdminAccepted(res?.data?.result?.orderID)

            }).catch((error) => {
                console.log(error?.response?.data?.message)
                NotificationManager.error(error?.response?.data?.message ?? "Connection Failed")
            })
        }
    }
    /* **************************** admin wallet andtoken **************************  */
    const userPages = 10
    const pageVisited = pageNumber * userPages
    const displayAdminTransction = data.filter((val) => {
      
        if (filters === '') {
            return val
        } else if (val.userProfile?.email.toLowerCase().includes(filters?.toLowerCase()) || val.userProfile?.fullName.toLowerCase().includes(filters?.toLowerCase())) {
            return val
        }
    }).slice(pageVisited, pageVisited + userPages).map((ele, index) => {
        const fromfirst = ele?.transactionHash?.substring(0, 4)
        const fromlast = ele?.transactionHash?.substring(ele.transactionHash?.length - 4)
        const hash = ele?.orderID?.toString()?.substring(0, 4)
        const lasthst = ele?.orderID?.toString().substring(ele.orderID.toString().length - 4)
       
        return (
            <>
                <tr class="data-item">
                    <td class="data-col dt-tnxno"><div class="d-flex align-items-center">{ele.transactionStats === 'Pending' && ele.transactionHash !== '' ? <div class="data-state data-state-progress"><span class="d-none">Progress</span></div> : ele.transactionStats === 'Accepted' ? <div class="data-state data-state-approved"><span class="d-none">Approved</span></div> : ele.transactionHash === '' ? <div class="data-state data-state-pending"><span class="d-none">Pending</span></div> : <div class="data-state data-state-canceled"><span class="d-none">Canceled</span></div>}<div class="fake-class"><span class="lead tnx-id">OD{hash}...{lasthst}</span><span class="sub sub-date"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.createdAt)} /></span></div></div></td>
                    <td class="data-col dt-token"><span class="lead token-amount">{ele?.userProfile?.fullName}</span><span class="sub sub-symbol">MAMBA</span></td>

                    <td class="data-col dt-token"><span class="lead token-amount">{ele?.userProfile?.email}</span><span class="sub sub-symbol">MAMBA</span></td>

                    <td class="data-col dt-token"><span class="lead token-amount">
                        <NumberFormat
                            thousandsGroupStyle="thousand"
                            // format="$#########0"
                            value={ele?.mambaToken == null ? 0 : ele.mambaToken}
                            prefix=""
                            decimalSeparator="."
                            displayType="text"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={true}
                            suffix={0} /></span><span class="sub sub-symbol">MAMBA</span></td>

                    <td class="data-col dt-amount"><span class="lead amount-pay">{ele.ethAmount != null ? ele.ethAmount : ele.maticAmount != null ? ele.maticAmount : ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? '...' : ele.usdAmount}</span><span class="sub sub-symbol">{ele.ethAmount != null ? "ETH" : ele.maticAmount != null ? "MATIC" : ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "" : "USD"} <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 ${ele.transactionHash == 'Referral Bonus' ? "referral Bonus" : ele.ethAmount != null ? "ETH" : ele.maticAmount != null ? "MATIC" : "USD"} = ${ele.transactionHash == 'Referral Bonus' ? "..." : ele.ethAmount != null ? ele.usdCrrentAmount ?? 0 : ele.maticAmount != null ? ele.usdMaticCrrentAmount ?? 0 : '20000'} USD`}></em></span></td>
                    {/* <td class="data-col dt-amount"><span class="lead amount-pay">{ele.maticAmount === null ? "..." : ele.maticAmount}</span><span class="sub sub-symbol">MATIC<em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 MATIC = ${ele.usdMaticCrrentAmount} USD`}></em></span></td> */}
                    {/* <td class="data-col dt-amount"><span class="lead amount-pay">{ele.usdAmount === null ? "..." : ele.usdAmount}</span><span class="sub sub-symbol">USD <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 USD = 20000 MAMBA`}></em></span></td> */}
                    {/* <td class="data-col dt-usd-amount"><span class="lead amount-pay">245.52</span><span class="sub sub-symbol">USD <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="1 ETH = 350.54 USD"></em></span></td> */}
                    <td class="data-col dt-account"><a href={ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "/" : ele.ethAmount === null && ele.usdAmount === null ? `https://polygonscan.com/tx/${ele.transactionHash}` : ele.maticAmount === null && ele.usdAmount === null ? `https://etherscan.io/tx/${ele.transactionHash}` : "/"} class="lead user-info linkactive" target="_blank" >{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "Referral " : fromfirst}{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "" : "..."}{ele.maticAmount == null && ele.usdAmount == null && ele.ethAmount == null ? "Bonus" : fromlast}</a>
                        <span class="sub sub-date copy-wrap mgb-0-5x"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.createdAt)} /><em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px' }} onClick={() => { navigator.clipboard.writeText(ele.transactionHash) }}
                        ></em></span>
                        <span></span></td>
                    <td class="data-col text-right">
                        <div class="relative d-inline-block">
                            <a href="#" class="btn btn-light-alt btn-xs btn-icon toggle-tigger"><em class="ti ti-more-alt"></em></a>
                            <div class="toggle-class dropdown-content dropdown-content-top-left">
                                <ul class="dropdown-list">
                                    <li>
                                        <Link to='/TranscationDetails' state={ele}>
                                            <em class="ti ti-eye"></em> View Details</Link>
                                    </li>
                                    {/* <li onClick={() => transcationdetailsAdminAccepted(ele.orderID)} style={{ cursor: 'pointer' }}><a ><em class="ti ti-check-box"></em> Approve</a></li> */}
                                    <li onClick={() => { handleShow(ele.orderID) }} style={{ cursor: 'pointer' }}><a ><em class="ti ti-check-box"></em> Approve</a></li>
                                    <li onClick={() => transcationdetailsAdminRejected(ele.orderID)} style={{ cursor: 'pointer' }}><a ><em class="ti ti-na"></em> Rjected</a></li>
                                    {/* <li><a href="#"><em class="ti ti-trash"></em> Delete</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </>
        )
    })

    const pageCount = Math.ceil(data.length / userPages)
    const changePage = (texted) => {
        setPageNumber(texted)
    }





    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <body class="page-user"><div class="topbar-wrap"><div class="topbar is-sticky"><div class="container">
                <Menu />


            </div></div>
                <Header />


            </div>
                <div class="page-content">
                    <div class="container">
                        <div class="content-area card">
                            <div class="card-innr">
                                <div class="card-head">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 class="card-title"  >Admin Transactions</h4>
                                        <input type='text' class='input-bordered w-25' placeholder='Search by Email, Name' onChange={(e) => setFilter(e.target.value)} />
                                    </div>
                                </div>
                                {data.length !== 0 ? <><div style={{ overflowX: "auto" }} > <table class="data-table dt-init user-tnx"><thead>
                                    <tr class="data-item data-head">
                                        <th class="data-col dt-tnxno">Order ID</th>
                                        <th class="data-col dt-tnxno">Name</th>
                                        <th class="data-col dt-tnxno">Email</th>
                                        <th class="data-col dt-token">MAMBA</th>
                                        <th class="data-col dt-amount"> Amount</th>
                                        {/* <th class="data-col dt-usd-amount">MATIC Amount</th>
                                        <th class="data-col dt-usd-amount">USD Amount</th> */}
                                        <th class="data-col dt-account">Txns</th>
                                        {/* <th class="data-col dt-type">
                                            <div class="dt-type-text">Type</div></th> */}
                                        <th class="data-col"> </th>

                                        {/* <th class="data-col"> Details</th> */}
                                    </tr></thead>
                                    <tbody>
                                        {displayAdminTransction}
                                    </tbody></table></div>
                                    <div><ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}

                                        onPageChange={page => setPageNumber(page.selected)}

                                        pageCount={pageCount}
                                        breakLabel={'...'}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        nextClassName={'page-item next'}
                                        previousClassName={'page-item prev'}
                                        previousLinkClassName={'page-link'}
                                        pageLinkClassName={'page-link'}
                                        breakClassName='page-item'
                                        breakLinkClassName='page-link'
                                        containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1'}
                                    /></div>
                                </> :
                                    <div class='text-center'>
                                        <h2>No Data  Transaction data Available </h2>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                    show={show} onHide={handleClose}>

                    <Modal.Body>

                        <div>

                            <div class="input-item input-with-label">
                                <label for="token-address" class="input-item-label deepak">Enter your wallet address</label>
                                <input type='text' name='sender' id='sender' class="input-bordered" onChange={(e) => { setTranscationaddressWallet(e.target.value) }} />

                                <label for="token-address " class="input-item-label deepak  mt-2">Find your Transaction Hash on Etherscan or Polygonscan and enter Hash below
                                </label>
                                <input type='text' name='tsnx' id='tsnx' class="input-bordered" type='text' onChange={(e) => { setTransactionhash(e.target.value) }} />

                            </div>
                            <ul class="d-flex flex-wrap align-items-center guttar-30px">
                                <li><a href="#" class="btn btn-primary" onClick={() => transcationdetailsToken(approved)} > Approved</a></li>
                                {/* <li class="pdt-1x pdb-1x"><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#pay-online"
                                    class="link link-primary">Make Online Payment</a></li> */}
                            </ul>
                            <div class="gaps-2x"></div><div class="gaps-1x d-none d-sm-block"></div>
                            {/* <div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>In case you send a different amount, number of MAMBAtokens will update accordingly.</p></div> */}
                        </div>

                    </Modal.Body>

                </Modal>

                <Footer />

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div >
    )
}

export default Index
