import React, { useState, useEffect, useContext } from 'react'
import logo from './logo.svg';
import './App.css';
import Home from './component/home/index'
import KycApplication from './component/kycApplication/index'
import Distribution from './component/distribution/index'
import Profile from './component/Profile/index'
import BuyToken from './component/BuyToken/Index'
import Transactions from './component/Transactions.js/index'
import Regularpage from './component/regularpage/index'

import Faqpage from './component/faqpage/index'
import SingIn from './component/singin/index'
import SingUp from './component/singup/index'
import Activity from './component/Activity/index'
import ForgetPassword from './component/ForgetPassword/index'
import KycForm from './component/KycForm/index'
import Kycthnaks from './component/kycthnaku/index'
import Error from './component/404Page/index'
import Index from './component/AdminIndex/index'
import UserList from './component/AdminuserList/index'
import UserDetails from './component/AdminUserdetails/index'
import Transcation from './component/AdminTanscation/index'
import TranscationDetails from './component/AdminTranscationDetails/index'
import KycList from './component/AdminKyclist/index'
import KycListDetails from './component/AdminKycDetails/index'
import ManualPayment from './component/manualpayment/index.js'
import { RouterConfig } from './component/Router'
import { StoreContext1 } from './useContext/pathContext'
import { useLocation } from 'react-router-dom'
import 'react-notifications/lib/notifications.css';

import useMetaMask from './metamask';
import Web3 from 'web3'



import {
  Routes, Route, Link
} from "react-router-dom"
import Restricted from './component/Router/Restricted';
import Protected from './component/Router/Protected';

function App() {
  const { chainId, connect, disconnect, isActive, account } = useMetaMask()
  const location = useLocation()
  const [balance, setBalance] = useState('')
  const [address, setAddress] = useState(account)
  const context = useContext(StoreContext1)

  useEffect(async () => {
    context.setPath({ ...context.path, newdata: location.pathname })
    if (location.pathname.includes('referral')) {
     
      window.location.reload()

      await sessionStorage.setItem('referralcode', location.pathname.split('/').pop())
    }
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      })
      window.ethereum.on('accountsChanged', () => {

      })
    }

  }, [])



  return (
    <div >
      <Routes>
        {
          RouterConfig.map(({ path, component, isExact, isRestricted, isProtected, isPublic }) => {

            if (isRestricted) {
              return (< >
                {/* // <Restricted path={path} element={component} exact={isExact} />
                // <Route path={path} element={<Restricted element={component} />} exact={isExact} /> */}
               
                <Route path={path} element={<Restricted path={path} exact={isExact} >
                  {component}
                </Restricted>} exact={isExact} />
              </>
              )
            } else if (isProtected) {
              return (< >
               
                <Route path={path} element={<Protected path={path} exact={isExact} >
                  {component}
                </Protected>} exact={isExact} />
                {/* // <Route path={path} element={component} exact={isExact} /> */}
              </>
              )
            } else if (isPublic) {
              return (<>
               
                <Route path={path} element={component} exact={isExact} />
              </>
              )
            } else {
              return (<>
               
                <Route path='*' element={<Error />} />
              </>
              )
            }
          })
        }
        <Route path='*' element={<Error />} />

        {/* <Route exact path="/home" element={<Home />} />

        <Route exact path="/KycApplication" element={<KycApplication />} />

        <Route exact path="/Distribution" element={<Distribution />} />
        <Route exact path="/Profile" element={<Profile />} />
        <Route exact path="/BuyToken" element={<BuyToken />} />
        <Route exact path="/Transactions" element={<Transactions />} />
        <Route exact path="/Regularpage" element={<Regularpage />} />
        <Route exact path="/Faqpage" element={<Faqpage />} />
        <Route exact path="/" element={<SingIn />} />
        <Route exact path="/SingUp" element={<SingUp />} />
        <Route exact path="/Activity" element={<Activity />} />
        <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
        <Route exact path="/KycForm" element={<KycForm />} />
        <Route exact path="/Kycthnaks" element={<Kycthnaks />} />
        <Route exact path="/Index" element={<Index />} />
        <Route exact path="/UserList" element={<UserList />} />
        <Route exact path="/UserDetails" element={<UserDetails />} />
        <Route exact path="/Transcation" element={<Transcation />} />
        <Route exact path="/TranscationDetails" element={<TranscationDetails />} />
        <Route exact path="/KycList" element={<KycList />} />
        <Route exact path="/KycListDetails" element={<KycListDetails />} />
        <Route exact path="/payment/:id" element={<ManualPayment />} /> */}
  </Routes>
    </div>
  )
}

export default App;
