import React, { useState, useEffect } from 'react'
import Header from '../header/index'
import Footer from '../Footer/index'
import Menu from '../menu/index'
import axios from 'axios'
import Moment from 'react-moment'
import ReactPaginate from 'react-paginate'

import { BASE_URL } from '../../useContext/walletContext'
// import { useEffect, useState } from 'react'
import SocailMedia from '../Socialbar/index'

function Index() {
    const [token, setToken] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [status, setStatus] = useState('')
    const [referral, setreferral] = useState('')


    const getuserstatus = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/statusdetails`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {
           
            // setUsertoken(res?.data.result[0])
            setStatus(res.data.result[0])

        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    const getReferralLink = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getreferrallink`, {
            headers: {
                "userid": sessiontoken
            }
        }).then((res) => {
           
            setreferral(res?.data?.result?.referralLink)

        }).catch((error) => {
            console.log(error)
        })
    }

    const gettoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/loginhistory`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            setToken(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    useEffect(() => {
        getuserstatus()
        getReferralLink()
        gettoken()
    }, [])

    const userPages = 10
    const pageVisited = pageNumber * userPages

    const ActivityAccount = token.slice(pageVisited, pageVisited + userPages).map((ele, index) => {
        return (
            <tr class="data-item">
                <td class="data-col activity-time"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.date)} /></td>
                <td class="data-col activity-device">{ele.device}</td>
                <td class="data-col activity-browser">{ele.Brower}</td>
                <td class="data-col activity-ip">{ele.ip}</td>
            </tr>
        )

    })

    const pageCount = Math.ceil(token.length / userPages)



    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>
                    </div>
                    <Header />

                </div>



                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 main-content">
                                <div class="card content-area">
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <h4 class="card-title">Activity</h4>
                                        </div>
                                        <div class="card-text">
                                            <p>Here is your recent activities. You can clear your log  or disable tracking option from security settings. </p>
                                        </div>
                                        <div class="gaps-1-5x"></div>
                                        <table class="data-table  activity-table" data-items="10">
                                            <thead>
                                                <tr class="data-item data-head">
                                                    <th class="data-col activity-time"><span>Date</span></th>
                                                    <th class="data-col activity-device"><span>Device</span></th>
                                                    <th class="data-col activity-browser"><span>Browser</span></th>
                                                    <th class="data-col activity-ip"><span>IP</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ActivityAccount}

                                            </tbody>
                                        </table>
                                        <div><ReactPaginate
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}

                                            onPageChange={page => setPageNumber(page.selected)}

                                            pageCount={pageCount}
                                            breakLabel={'...'}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={2}
                                            activeClassName={'active'}
                                            pageClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            nextClassName={'page-item next'}
                                            previousClassName={'page-item prev'}
                                            previousLinkClassName={'page-link'}
                                            pageLinkClassName={'page-link'}
                                            breakClassName='page-item'
                                            breakLinkClassName='page-link'
                                            containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-center pr-1'}
                                        /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 aside sidebar-right">
                                <div class="account-info card">
                                    <div class="card-innr">
                                        <h6 class="card-title card-title-sm">Your Account Status</h6>
                                        <ul class="btn-grp">
                                            {status.AccountVerify ? <li>
                                                <a href="#" class="btn btn-auto btn-xs btn-success">Email Verified</a></li> : <li>
                                                <a href="#" class="btn btn-auto btn-xs btn-warning">Email Pending</a></li>}
                                            {status.KycForm ? <li><a href="#" class="btn btn-auto btn-xs btn-success">KYC Verified </a></li> : <li><a href="#" class="btn btn-auto btn-xs btn-warning">KYC Pending</a></li>}
                                        </ul>
                                        <div class="gaps-2-5x"></div>
                                        <h6 class="card-title card-title-sm">Receiving Wallet</h6>
                                        <div class="d-flex justify-content-between"><span><span>0x39deb3.....e2ac64rd</span> <em class="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="1 ETH = 100 TWZ"></em></span><a href="#" data-toggle="modal" data-target="#edit-wallet" class="link link-ucap">Edit</a></div>
                                    </div>
                                </div>
                                <div class="referral-info card">
                                    <div class="card-innr">
                                        <h6 class="card-title card-title-sm">Earn with Referral</h6>
                                        <p class=" pdb-0-5x">Invite friends & family to receive a<strong><span class="text-primary">  15% Token Bonus </span> - based off their contribution</strong></p>
                                        <div class="copy-wrap mgb-0-5x"><span class="copy-feedback"></span><em class="fas fa-link"></em><input type="text" class="copy-address" value={`https://ido.mambapayments.com/referral/${referral}`} disabled /><button class="copy-trigger copy-clipboard" data-clipboard-text={`https://ido.mambapayments.com/referral/${referral}`}><em class="ti ti-files"></em></button></div>
                                    </div>
                                </div>
                                <div class="kyc-info card">
                                    {/* <div class="card-innr">
                                        <h6 class="card-title card-title-sm">Identity Verification - KYC</h6>
                                        <p>To comply with regulation, participant will have to go through indentity verification.</p>
                                        <p class="lead text-light pdb-0-5x">You have not submitted your KYC application to verify your indentity.</p>
                                        <a href="#" class="btn btn-primary btn-block">Click to Proceed</a>
                                        <h6 class="kyc-alert text-danger">* KYC verification required for purchase token</h6>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SocailMedia />
                <Footer />
                {/* <div class="footer-bar"><div class="container"><div class="row align-items-center justify-content-center"><div class="col-md-8"><ul class="footer-links"><li><a href="#">Whitepaper</a></li><li><a href="faq-page.html">FAQs</a></li><li><a href="regular-page.html">Privacy Policy</a></li><li><a href="regular-page.html">Terms of Condition</a></li></ul></div><div class="col-md-4 mt-2 mt-sm-0"><div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x"><div class="copyright-text">&copy; 2021 MambaVerse Pay LLC.</div><div class="lang-switch relative"><a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a><div class="toggle-class dropdown-content dropdown-content-up"><ul class="lang-list"><li><a href="#">Fr</a></li><li><a href="#">Bn</a></li><li><a href="#">Lt</a></li></ul></div></div></div></div></div></div></div> */}

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
