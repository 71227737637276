import React, { useEffect, useState, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'


function Paypal(props) {
    const history = useNavigate()
    const { usd, mamba, maticAmountcurrent, usdAmountcurrent } = props
    const [paid, setPaid] = useState(false);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [orderID, setOrderID] = useState('')
    const paypal = useRef();
    console.log("usd", usd)
    useEffect(() => {
        window.paypal
            .Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: "Your description",
                                amount: {

                                    value: usd
                                    // currency_code: "INR",
                                },
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    setPaid(true);
                    
                    transcationdetailssave(order)
                },
                onError: (err) => {
                    setError(err);
                        console.error(err);
                },
            })
            .render(paypal.current);
    }, []);

    function Transactionsme() {
        history('/Transactions')
        window.location.reload()
    }

    const transcationdetailssave = async (order) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            mambaToken: mamba,
            ethAmount: "",
            maticAmount: "",
            usdAmount: usd,
            transactionHash: order.id,
            usdCrrentAmount: usdAmountcurrent,
            usdMaticCrrentAmount: maticAmountcurrent,
            senderID: order.payer.email_address
        }
        axios.post(`${BASE_URL}/api/transaction-counter`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            setOrderID(res.data.result.orderID)
            
            window.$('#pay-online').modal('hide');

            window.$('#pay-online').on('hidden.bs.modal', function () {
                window.$(".modal-backdrop").remove();
            })
            setTimeout(function () { handleShow() }, 1000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }


    // If any error occurs
    // if (error) {
    //     return <div>Error Occurred in processing payment.! Please try again.</div>;
    // }
    return (
        <>
            <div ref={paypal}>

            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter "

                show={show} onHide={handleClose}>

                <Modal.Body>

                    <div>
                        <div class="popup-body text-center"><div class="gaps-2x"></div>

                            <div class="pay-status pay-status-progress" style={{ color: "blue" }}><em class="ti ti-alarm-clock
"></em></div><div class="gaps-2x"></div>
                            <h3>We’re reviewing your payment.</h3>
                            {/* <Transcation getdatasuccess={getdatasuccess} /> */}
                            <p>We’ll review your transaction and get back to you within 6 hours. You’ll receive an email with the details of your contribution. Your Order ID is {orderID} </p>
                            <div class="gaps-2x"></div><button class="btn btn-primary" onClick={Transactionsme} >View Transaction</button>
                            <div class="gaps-1x"></div></div>
                    </div>

                </Modal.Body>

            </Modal>
            {/* {paid ? <div>Payment successful.!</div> : null}
            {error ? <div>Error Occurred in processing payment.! Please try again.</div> : null} */}
        </>
    )
}

export default Paypal
