import React, { createContext, useState } from 'react'

const StoreContext1 = createContext()

const StoreProvider1 = (props) => {

    const [path, setPath] = useState({ kycform: "", newdata: "", calculatorInput: 0, calculatorOutput: 0, currency: "" })

    return (
        <StoreContext1.Provider value={{
            path,
            setPath

        }} >
            {props.children}
        </StoreContext1.Provider>
    )

}


export { StoreContext1, StoreProvider1 }

export const BASE_URL = "https://nodejs.mambapayments.com"