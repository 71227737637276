import React, { useEffect, useState, useContext, useRef } from 'react'
import Helmet from "react-helmet"
import { NavLink, Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'
import MambaPay from '../../images/mamba.png'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { StoreContext } from '../../useContext/walletContext'
import './index.css'


function Index() {
    const history = useNavigate()

    const context = useContext(StoreContext)
    const [email, setEmail] = useState('')
    const [user, setUser] = useState('')
    const [imageselect, setimageselect] = useState(false)
    const [usertoken, setUsertoken] = useState('')
    const [showtoggle, setShowtoggle] = useState(false)
    const [adminrole, setAdminrole] = useState('')
    const ref = useRef()
    const [show,setShow] = useState(false)

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    // useEffect(() => {
    //     const checkIfClickedOutside = e => {
    //         // If the menu is open and the clicked target is not within the menu,
    //         // then close the menu
    //         if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
    //             setIsMenuOpen(false)
    //         }
    //     }

    //     document.addEventListener("mousedown", checkIfClickedOutside)

    //     return () => {
    //         // Cleanup the event listener
    //         document.removeEventListener("mousedown", checkIfClickedOutside)
    //     }
    // }, [isMenuOpen])


    const logoutUser = () => {
        window.sessionStorage.clear()
        history('/')
    }

    const getusershowInfo = async () => {
        const name = await window.sessionStorage.getItem("fullName")

        const image = await window.sessionStorage.getItem("image")

        setUser(name)
        setEmail(image)
    }
    useEffect(() => {
        getusershowInfo()
        getusertoken()
        email.includes('https://images.coinbase.com/avatar?h=6167e77e937b6a1ad6721fgMjjtXk0n439ENDSUWEmi1y6LBSIkjKnEfHFTy%0Ak5Mo&s=128') ? setimageselect(false) : setimageselect(true)
    }, [])

   

    /* ****************************get Token ********************************************* */
    const getusertoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/usertoken`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {
            
            setUsertoken(res?.data.result[0])


        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    /* ******************************ENd TOken******************************************** */
    const active = showtoggle ? 'active' : ''
    const toggle = context.setHeaderTitle(!showtoggle)
    


    const getadminrole = async () => {
        const sessionrole = await window.sessionStorage.getItem('role')
        setAdminrole(sessionrole)
        // setAddress(account)
    }


    useEffect(() => {
        getadminrole()

    }, [])

    return (

        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <div class="d-flex justify-content-between align-items-center">
                <ul class="topbar-nav d-lg-none" onClick={() => setShowtoggle(!showtoggle)}>
                    <li class="topbar-nav-item relative">
                        <a class={`toggle-nav ${active} `} href="#">
                            <div class="toggle-icon">
                                <span class="toggle-line"></span>
                                <span class="toggle-line"></span>
                                <span class="toggle-line"></span>
                                <span class="toggle-line"></span>
                            </div>
                        </a>
                    </li>


                </ul>


                <Link class="topbar-logo" to='/home'>
                    <img src='images/logos/mamba-icon.png' alt="logo" />
                    {/*  <img src='images/logos/mamba-icon.png' width="96px" height="56px" alt="logo" /> */}
                </Link>
                <div >
                    <ul class="navbar-nav" >
                    <li class={`nav-item dropdown ${show ?"show":""} `} onClick={() => setShow(!show)}>
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="user-welcome d-none d-lg-inline-block">Welcome! {user}</span>
        <img src={email === 'https://images.coinbase.com/avatar?h=6167e77e937b6a1ad6721fgMjjtXk0n439ENDSUWEmi1y6LBSIkjKnEfHFTy%0Ak5Mo&s=128' ? email : email.includes('https:') ? email : `${BASE_URL}/${email}`} class="toggle-tigger user-thumb " width="50px" height="50px" alt="logo" />
        </a>
        <div class={`dropdown-menu ${show ?"show":""}`} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
        <div class="user-status">
                                    <h6 class="user-status-title">Token balance</h6>
                                    <div class="user-status-balance">
                                        <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={usertoken?.totaltoken ?? 0}
                                        prefix=""
                                        decimalSeparator="."
                                        displayType="text"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={true}
                                        suffix={0} /> <small>MambaVerse</small>
                                    </div>
                                </div>

                                <ul class="user-links">
                                    <li><NavLink exact to='/Profile' href="profile.html"><i class="ti ti-id-badge"></i>My Profile</NavLink></li>
                                   
                                    {adminrole === 'admin' ? <> <li><NavLink exact to='/Activity' href="activity.html"><i class="ti ti-eye"></i> Admin Activity</NavLink></li>
                                        <li><NavLink exact to='/AdminActivity' href="activity.html"><i class="ti ti-eye"></i> User Activity</NavLink></li>
                                    </> : <li><NavLink exact to='/Activity' href="activity.html"><i class="ti ti-eye"></i>Activity</NavLink></li>}
                                </ul>
                                <ul class="user-links bg-light" onClick={logoutUser}>
                                    <li><NavLink exact to='/SingIn' ><i class="ti ti-power-off"></i>Logout</NavLink></li>
                                </ul>
        </div>
      </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Index
