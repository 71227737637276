import React from 'react'
import Header from '../header/index'
import Footer from '../Footer/index'
import Menu from '../menu/index'
import { Link, useNavigate } from 'react-router-dom'
import SocailMedia from '../Socialbar/index'

function Index() {
    const history = useNavigate()
    const Onredirect = () => {
        history('/KycForm')
        window.location.reload()
    }

    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>

                    </div>

                    <Header />


                </div>
                <SocailMedia />


                <div class="page-header page-header-kyc"><div class="container"><div class="row justify-content-center"><div class="col-lg-8 col-xl-7 text-center"><h2 class="page-title">KYC Verification</h2><p class="large">To comply with regulation each participant will have to go through indentity verification (KYC/AML) to prevent fraud. Please, complete our fast and secure verification process to participate in our token sale.</p></div></div></div></div><div class="page-content"><div class="container"><div class="row justify-content-center"><div class="col-lg-10 col-xl-9"><div class="kyc-status card mx-lg-4"><div class="card-innr"><div class="status status-empty"><div class="status-icon"><em class="ti ti-files"></em></div><span class="status-text text-dark">You have not submitted your necessary documents to verify your identity. In order to purchase our tokens, please verify your identity.</span><a
                /* to='/KycForm' */ onClick={Onredirect} class="btn btn-primary">Click here to complete your KYC</a></div></div></div><p class="text-light text-center">If you have any question, please contact our support team If you have any question, please contact our support team <a href="#">contact@mambaverse.io</a>.</p><div class="gaps-1x"></div><div class="gaps-3x d-none d-sm-block"></div></div></div></div></div>
                <Footer />

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script>
            </body>
        </div>
    )
}

export default Index
