import React, { useState } from 'react';
import './index.css'
import Chart from 'react-apexcharts'
// import { useState } from 'react';

function Amhart() {
    const [option, setOption] = useState({
        chart: {
            height: 500,
            width: "100%",
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                track: {
                    background: '#737b80'
                },
                offsetY: 0,
                startAngle: 0,
                endAngle: 360,
                hollow: {
                    margin: 10,
                    size: '67%',
                    imageWidth: 250,
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {

                    name: {
                        show: true,
                    },
                    value: {
                        show: true,
                        color: '#fff',
                        fontSize: '20px'

                    },
                    backgroundColor: [
                        "#FFFFFF",
                    ]
                }
            }
        },
        colors: ['#e04cab', '#8b3aa7', '#9e54fd', '#3d4aba', '#3e75e1', '#43a8e0', '#33a4a7', '#f4fe8f', '#ff9254', '#ec664a'],
        labels: ['Community Incentives', 'Liquidity Pool Event', 'Mamba Labs Reserve', 'Team', 'Private Sale', 'Public Sale', 'Emergency Tokens', 'Pre-Sale', 'Marketing', 'Advisors & Partners'],
        legend: {
            show: true,
            floating: true,
            // fontSize: '18px',
            fontSize: '20px',
            padding: "10px",
            position: 'left',
            offsetX: -20,
            offsetY: 10,
            labels: {
                // useSeriesColors: true,
                colors: '#fff'
            },
            markers: {
                size: 0
            },
            formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
                vertical: 5
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: "100%",
                    height: '400'

                },
                plotOptions: {
                    radialBar: {
                        track: {
                            background: '#737b80'
                        },
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 360,
                        hollow: {
                            margin: 10,
                            size: '60%',
                            imageWidth: 250,
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {

                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                                color: '#fff',
                                fontSize: '20px'

                            },
                            backgroundColor: [
                                "#FFFFFF",
                            ]
                        }
                    }
                },

                legend: {
                    show: false,

                    // fontSize: '18px',
                    fontSize: '16px',
                    padding: "0px",
                    position: 'left',
                    offsetX: -20,
                    offsetY: 10,
                    labels: {
                        // useSeriesColors: true,
                        colors: '#fff'
                    },

                },

            }
        }]
    })
    const [series, setSeries] = useState([30, 20, 9, 10, 10, 5, 5, 4, 4, 3])

    // var chart = new ApexCharts(document.querySelector("#chart"), options);
    // chart.render();
    return <div>
        <Chart options={option}
            series={series}
            type="radialBar"
            height={550}
        />
    </div>;
}

export default Amhart;
