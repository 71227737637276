import React from 'react'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import Header from '../header/index'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import Footer from '../adminFooter/index'
import NumberFormat from 'react-number-format'
function Index() {
    const details = useLocation()
  
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <body class="page-user"><div class="topbar-wrap"><div class="topbar is-sticky"><div class="container">
                <Menu />


            </div></div>
                <Header />


            </div>
                <div class="page-content">
                    <div class="container">
                        <div class="card content-area">
                            <div class="card-innr">
                                <div class="card-head d-flex justify-content-between align-items-center">
                                    <h4 class="card-title mb-0">Transaction Details</h4>
                                    <Link to='/Transcation' class="btn btn-sm btn-auto btn-primary d-sm-block d-none">
                                        <em class="fas fa-arrow-left mr-3"></em>Back</Link>
                                    <a href="transactions.html" class="btn btn-icon btn-sm btn-primary d-sm-none"><em class="fas fa-arrow-left"></em></a>
                                </div>
                                <div class="gaps-1-5x"></div>
                                <div class="data-details d-md-flex">
                                    <div class="fake-class"><span class="data-details-title abhimanyu">Tranx Date</span><span class="data-details-info abhimanyu"><Moment format="MM-DD-YYYY HH:mm" date={new Date(details.state.createdAt)} /></span></div>
                                    <div class="fake-class"><span class="data-details-title abhimanyu">Tranx Status</span><span class="badge badge-success ucap">{details.state.transactionStats === 'Pending' && details.state.transactionHash !== "" ? <span class="badge badge-success ucap">Pending</span> : details.state.transactionStats === 'Accepted' ? <span class="badge badge-success ucap">Accepted</span> : details.state.transactionHash === '' ? <span class="badge badge-success ucap">N/A</span> : <span class="badge badge-success ucap">Rejected</span>}</span></div>
                                    <div class="fake-class"><span class="data-details-title abhimanyu">Tranx Approved Note</span>
                                        {details?.state?.transactionadmindate ? <Moment format="MM-DD-YYYY HH:mm" date={new Date(details.state.transactionadmindate)} /> : "...."}
                                    </div>
                                </div>
                                <div class="gaps-3x"></div>
                                <h6 class="card-sub-title abhimanyu">Transaction Info</h6>
                                <ul class="data-details-list">
                                    <li>
                                        <div class="data-details-head abhimanyu">Transaction Type</div>
                                        <div class="data-details-des abhimanyu"><strong>Purchase</strong></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head abhimanyu">Payment Getway</div>
                                        <div class="data-details-des abhimanyu">
                                            {details.state.transactionHash == 'Referral Bonus' ? "Referral Bonus" : details.state.ethAmount == null && details.state.usdAmount == null && details.state.transactiontype == "online" ? <strong class='abhimanyu'>Matic <small class='abhimanyu'>-  MetaMask</small></strong> : details.state.maticAmount == null && details.state.usdAmount == null && details.state.transactiontype == "online" ? <strong class='abhimanyu'>Ethereum <small class='abhimanyu'>- MetaMask</small></strong> : details.state.ethAmount == null && details.state.transactiontype == "Manual" ? <strong class='abhimanyu'>Matic <small class='abhimanyu'>-  Offline</small></strong> : details.state.ethAmount == null && details.state.maticAmount == null ? <strong class='abhimanyu'>USD <small class='abhimanyu'>-{details.state.transactionHash?.substring(0, 3) === 'txn' ? "stripe" : "paypal"} </small></strong> : <strong class='abhimanyu'>Ethereum <small class='abhimanyu'>-  Offline</small></strong>}
                                        </div>
                                    </li>
                                    <li>
                                        <div class="data-details-head abhimanyu" >{details?.state?.ethAmount == null && details?.state?.maticAmount == null ? "Email" : "Deposit From"}</div>
                                        <a href={details.state.ethAmount === null && details.state.maticAmount === null ? null : details.state.ethAmount === null ? `https://polygonscan.com/address/${details.state.senderID}` : details.state.maticAmount === null ? `https://etherscan.io/address/${details.state.senderID}` : null} class="data-details-des abhimanyu" target='_blank'><strong>{details.state.senderID}</strong></a>
                                    </li>
                                    <li>
                                        <div class="data-details-head abhimanyu">{details?.state?.ethAmount == null && details?.state?.maticAmount == null ? "Txn ID" : "Txn Hash"}</div>
                                        <a href={details.state.ethAmount === null && details.state.maticAmount === null ? null : details.state.ethAmount === null ? `https://polygonscan.com/tx/${details.state.senderID}` : details.state.maticAmount === null ? `https://etherscan.io/tx/${details.state.senderID}` : null} class="data-details-des abhimanyu" target='_blank'><span>{details.state.transactionHash}</span> <span></span></a>
                                    </li>
                                    {details.state.ethAmount == null && details.state.maticAmount == null ? null : <li>
                                        <div class="data-details-head abhimanyu">Deposit To</div>
                                        <a href={details.state.ethAmount === null ? `https://polygonscan.com/address/0x68B664F5E0fBd67385175c50703Bda4e50F72341` : `https://etherscan.io/address/0x68B664F5E0fBd67385175c50703Bda4e50F72341`} class="data-details-des abhimanyu"><span>0x68B664F5E0fBd67385175c50703Bda4e50F72341</span><span></span></a>
                                    </li>}
                                    <li>
                                        <div class="data-details-head abhimanyu">Token</div>
                                        <div class="data-details-des abhimanyu">
                                            <NumberFormat
                                                thousandsGroupStyle="thousand"
                                                // format="$#########0"
                                                value={details.state.mambaToken == null ? 0 : details.state.mambaToken}
                                                prefix=""
                                                decimalSeparator="."
                                                displayType="text"
                                                type="text"
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                suffix={0} />
                                            MAMBA</div>
                                    </li>
                                    <li>
                                        <div class="data-details-head abhimanyu">Polygon Wallet Address <span class="sub sub-symbol"> <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`User's wallet address to receive ERC20 polygon token.`}></em></span></div>
                                        <div class="data-details-des abhimanyu"> {details?.state?.userProfile?.WalletAddress ? details.state.userProfile.WalletAddress : ""}
                                            {details.state.userProfile.WalletAddress ? <em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px', color: '#74fffa' }} onClick={() => { navigator.clipboard.writeText(details.state.userProfile.WalletAddress) }}
                                            ></em> : null}
                                        </div>
                                    </li>
                                </ul>
                                {/* <div class="gaps-3x"></div>
                                <h6 class="card-sub-title">Token Details</h6> */}
                                {/* <ul class="data-details-list">
                                    <li>
                                        <div class="data-details-head">Stage Name</div>
                                        <div class="data-details-des"><strong>ICO Phase 3</strong></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head">Contribution</div>
                                        <div class="data-details-des"><span><strong>1.000 ETH</strong> <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="1 ETH = 100 TWZ"></em></span><span><em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="1 ETH = 100 TWZ"></em> $2540.65</span></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head">Tokens Added To</div>
                                        <div class="data-details-des"><strong>UD1020001 <small>- infoicox@gmail..com</small></strong></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head">Token (T)</div>
                                        <div class="data-details-des"><span>4,780.00</span><span>(4780 * 1)</span></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head">Bonus Tokens (B)</div>
                                        <div class="data-details-des"><span>956.00</span><span>(956 * 1)</span></div>
                                    </li>
                                    <li>
                                        <div class="data-details-head">Total Tokens</div>
                                        <div class="data-details-des"><span><strong>5,736.00</strong></span><span>(T+B)</span></div>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* <div class="footer-bar"><div class="container"><div class="row align-items-center justify-content-center"><div class="col-md-8"><ul class="footer-links"><li><a href="#">Whitepaper</a></li><li><a href="faq-page.html">FAQs</a></li><li><a href="regular-page.html">Privacy Policy</a></li><li><a href="regular-page.html">Terms of Condition</a></li></ul></div><div class="col-md-4 mt-2 mt-sm-0"><div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x"><div class="copyright-text">© 2021 MambaVerse LLC.</div><div class="lang-switch relative"><a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em></a><div class="toggle-class dropdown-content dropdown-content-up"><ul class="lang-list"><li><a href="#">Fr</a></li><li><a href="#">Bn</a></li><li><a href="#">Lt</a></li></ul></div></div></div></div></div></div></div> */}
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
