import React, { useState, useEffect } from 'react'
import { SpinnerCircular } from 'spinners-react';
import { Link, NavLink, useLocation } from 'react-router-dom'
import Img1 from '../../images/ath-gfx.png'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'
import MambaPay from '../../images/MambaPay.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { GoogleLogin } from 'react-google-login';
import { FacebookProvider, Login } from 'react-facebook';
// import { useLocation } from 'react-router-dom';

import SocailMedia from '../Socialbar/index'
const eye = <FontAwesomeIcon icon={faEye} />;

function Index() {
    const location = useLocation()
    const [username, setUsername] = useState('')
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useNavigate()
    // const location = useLocation()
    const [passwordShownconfirm, setPasswordShownconfirm] = useState(false);
    const [referral, setReferral] = useState('')
    const togglePasswordVisiblityconfirm = () => {
        setPasswordShownconfirm(passwordShownconfirm ? false : true);
    };
    useEffect(() => {
        let userRole

        userRole = window.sessionStorage.getItem("role")
        console.log("userRole", userRole ?? "")
        if (userRole == 'admin') {
            history('/index')
        } else if (userRole == 'user') {
            history('/home')
        } else {
            history(location.pathname)
        }
        setReferral(location.pathname.split('/').pop())


    }, [])
    const onsubmitedform = (e) => {
        e.preventDefault()
        const data = {
            userName: username,
            fullName: fullname,
            email: email,
            password: password
        }
        setLoading(true)
        axios.post(`${BASE_URL}/api/registration-referrals/${referral}`, data).then((res) => {
            console.log(res.data)

            history('/VerifyEmail')
            setLoading(false)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            setLoading(false)
            NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }

    // const responseGoogle = async (response) => {
    //     console.log("Success", response.tokenId)
    //     const tokendata = {
    //         tokenId: await response.tokenId
    //     }
    //     console.log("tokendata", tokendata)

    //     axios.post(`${BASE_URL}/api/loginwithGoogle`, tokendata).then((res) => {
    //         console.log(res)

    //         if (res?.data?.user?.AccountStatus === "Suspend") {
    //             NotificationManager.error("Your Account Suspended")
    //         } else if (res?.data?.user?.role === 'admin') {
    //             setLoading(false)
    //             window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
    //             window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
    //             window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
    //             window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
    //             window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
    //             window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
    //             window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
    //             history('/index')
    //             NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
    //             window.location.reload();
    //         } else {
    //             setLoading(false)
    //             window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
    //             window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
    //             window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
    //             window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
    //             window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
    //             window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
    //             window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
    //             history('/home')
    //             NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
    //             window.location.reload();

    //         }


    //     }).catch((error) => {
    //         console.log(error)
    //         NotificationManager.error(error?.response?.data?.error ?? "Somethink wrong")

    //     })
    // }

    // const responseGoogleFailure = (error) => {
    //     console.log("error", error)
    // }


    // const handleResponse = async (response) => {
    //     console.log('response', response)
    //     // console.log(data)

    //     const tokendata = {
    //         accessToken: await response.tokenDetail.accessToken,
    //         userID: await response.tokenDetail.userID
    //     }

    //     axios.post(`${BASE_URL}/api/facebooklogin`, tokendata).then((res) => {
    //         console.log(res)
    //         if (res?.data?.user?.AccountStatus === "Suspend") {
    //             NotificationManager.error("Your Account Suspended")
    //         } else if (res?.data?.user?.role === 'admin') {
    //             setLoading(false)
    //             window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
    //             window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
    //             window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
    //             window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
    //             window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
    //             window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
    //             window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
    //             history('/index')
    //             NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
    //             window.location.reload();
    //         } else {
    //             setLoading(false)
    //             window.sessionStorage.setItem("Token", res?.data?.user?._id ?? "")
    //             window.sessionStorage.setItem("userName", res?.data?.user?.userName ?? "")
    //             window.sessionStorage.setItem("fullName", res?.data?.user?.fullName ?? "")
    //             window.sessionStorage.setItem("email", res?.data?.user?.email ?? "")
    //             window.sessionStorage.setItem("image", res?.data?.user?.image ?? "")
    //             window.sessionStorage.setItem("role", res?.data?.user?.role ?? "")
    //             window.sessionStorage.setItem("WalletAddress", res?.data?.user?.WalletAddress ?? "")
    //             history('/home')
    //             NotificationManager.success(res?.data?.message ?? " SingUp Successfully")
    //             window.location.reload();
    //         }

    //     }).catch((error) => {
    //         console.log(error)
    //         NotificationManager.error(error?.response?.data?.error ?? "Somethink wrong")

    //     })
    // }

    // const handleError = (error) => {
    //     console.log('error', error)
    // }


    return (
        <div>
            <SocailMedia />
            <body class="page-ath">
                <div class="page-ath-wrap">
                    <div class="page-ath-content">
                        <div class="page-ath-header">
                            <Link to='/' class="page-ath-logo">
                                <img src={MambaPay} alt="logo" /></Link></div><div class="page-ath-form"><h2 class="page-ath-heading">Sign up <small class='text-white'>Create New MambaVerse Account</small></h2>

                            <form onSubmit={onsubmitedform}>
                                <div class="input-item">
                                    <input type="text" placeholder="Your Username" onChange={(e) => { setUsername(e.target.value) }} class="input-bordered" /></div>
                                <div class="input-item"><input type="text" placeholder="Your Fullname" class="input-bordered" onChange={(e) => { setFullname(e.target.value) }} /></div>
                                <div class="input-item">
                                    <input type="text" placeholder="Email" class="input-bordered" onChange={(e) => { setEmail(e.target.value) }} /></div>
                                <div class="input-item"><input type={passwordShownconfirm ? "text" : "password"} placeholder=" Password" class="input-bordered" onChange={(e) => { setPassword(e.target.value) }} />
                                    <i onClick={togglePasswordVisiblityconfirm} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                </div>
                                <div class="input-item">
                                    <input type="text" placeholder="Your Rererral" value={referral} onChange={(e) => { setReferral(e.target.value) }} class="input-bordered" disabled /></div>
                                <div class="input-item text-left"><input class="input-checkbox input-checkbox-md" id="term-condition" type="checkbox" /><label for="term-condition" class='text-white'>I agree to MambaVerse’s <a href="regular-page.html" class='text-white'>Privacy Policy</a> &amp; <a href="regular-page.html" class='text-white'> Terms.</a></label></div>
                                <button class="btn btn-primary btn-block" disabled={loading}>{loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Create Account"}</button></form>


                            <div class="gaps-2x"></div><div class="gaps-2x"></div><div class="form-note">Already have an account ? <Link exact to='/' > <strong>Sign in instead</strong></Link></div></div><div class="page-ath-footer"><ul class="footer-links">
                                <li><Link to='/Regularpage'>Privacy Policy</Link></li><li><Link to='/Regularpage'>Terms</Link></li><li class='text-white'>&copy; 2021 MambaVerse Pay LLC.</li></ul></div></div><div class="page-ath-gfx"><div class="w-100 d-flex justify-content-center"><div class="col-md-8 col-xl-5"><img src={Img1} alt="image" /></div></div>            </div></div><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
