import React from 'react'

function index() {
    return (

        <body class="page-user">
            <div class="topbar-wrap">

            </div>

            <div class="page-header">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-7 text-center">
                            <h2 class="page-title">Email Templates</h2>
                            <p class="large">Take a look at our pre styled email templates, for basic usages.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="page-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-9 col-lg-10">
                            <div class="content-area card">
                                <div class="card-innr">


                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Basic Email Template - Content Center</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tr>
                                            <td class="pdt-4x pdb-4x">
                                                <table class="email-header">
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center pdb-2-5x">
                                                                <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                <p class="email-title">Welcome to MambaVerse</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="email-body">
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center pd-3x pdb-3x">
                                                                <p class="mgb-1x">Your account is now verified and you can purchase tokens for the IDO. Also you need to submit your documents for the KYC.</p>
                                                                <p class="mgb-1-5x">Hope you'll enjoy the experience, we're here if you have any questions, drop us a line at contact@mambaverse.io anytime. </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="email-footer">
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                <p class="email-copyright-text">Copyright © 2021 MambaVerse.</p>
                                                                <ul class="email-social">
                                                                    <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                    <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                    <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                    <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                    <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Confirm Template</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body">
                                                        <tbody>
                                                            <tr>
                                                                <td class="pd-3x pdb-1-5x">
                                                                    <h2 class="email-heading">Confirm Your E-Mail Address</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pdl-3x pdr-3x pdb-2x">
                                                                    <p class="mgb-1x">Hi First Name</p>
                                                                    <p class="mgb-1x">Welcome! <br /> You are receiving this email because you have registered on our site.</p>
                                                                    <p class="mgb-1x">Click the link below to active your MAMBAVERSE account.</p>
                                                                    <p class="mgb-2-5x">This link will expire in 15 minutes and can only be used once.</p>
                                                                    <a href="#" target="_blank" class="email-btn">Verify Email</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pdl-3x pdr-3x">
                                                                    <h4 class="email-heading-s2">or</h4>
                                                                    <p class="mgb-1x">If the button above does not work, paste this link into your web browser:</p>
                                                                    <a href="#" target="_blank" class="link-block">https://icocrypto.com/account?login_token=dacb711d08a0ee7bda83ce1660918c31e8b43c30</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pd-3x pdt-2x pdb-3x">
                                                                    <p>If you did not make this request, please contact us or ignore this message.</p>
                                                                    <p class="email-note">This is an automatically generated email please do not reply to this email. If you face any issues, please contact us at contact@mambaverse.io</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>

                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Password Request Template</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-3x pdb-1-5x">
                                                                    <h2 class="email-heading">Reset Password</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center pd-3x pt-0 pdb-2x">
                                                                    <p class="mgb-1x">Hi First Name,</p>
                                                                    <p class="mgb-2-5x">Click On The link blow to reset your password.</p>
                                                                    <a class="email-btn">Reset Password</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center pd-3x pdt-2x pdb-4x">
                                                                    <p>If you did not make this request, please contact us or ignore this message.</p>
                                                                    <p class="email-note">This is an automatically generated email please do not reply to this email. If you face any issues, please contact us at  contact@mambaverse.io</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Password Reset Success Template</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-3x pdb-1-5x">
                                                                    <h2 class="email-heading">Password Reseted</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center pd-3x pt-0 pdb-2x">
                                                                    <p class="mgb-1x">Hi First Name,</p>
                                                                    <p>You Successfully Reseted Your Password. Thanks For being with Us.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center pd-3x pt-0 pdb-4x">
                                                                    <p class="email-note">This is an automatically generated email please do not reply to this email. If you face any issues, please contact us at  contact@mambaverse.io</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - KYC Submission</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <img class="w-88px mgb-1x" src="images/kyc-progress.png" alt="img" />
                                                                    <h2 class="email-heading-s3">Your Application under Process for Verification.</h2>
                                                                    <p>We are still working on your identity verification. Once our team verifies your identity, you will be notified by email.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - KYC Pending</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title" >Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-warning">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <img class="w-88px mgb-1x" src="images/kyc-pending.png" alt="img" />
                                                                    <h2 class="email-heading-s3 email-heading-warning">We found some information missing in application.</h2>
                                                                    <p>In our verification process, we found some information is missing. It would be great if you resubmit the KYC form. If you face any problem in submiting, please contact us at contact@mambaverse.io.</p>
                                                                    <a class="email-btn">Re-do KYC Form</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - KYC Approved</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <img class="w-88px mgb-1x" src="images/kyc-success.png" alt="img" />
                                                                    <h2 class="email-heading-s3 email-heading-success">Identity Verified</h2>
                                                                    <p>We have successfully verified your identity. You can continue to purchase MAMBA tokens.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Order ID Created (Online)</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Order Created</h2>
                                                                    <p>Thank you for purchasing MAMBA tokens. Your Order ID is _____. Our team will verify the transaction and will send MAMBA tokens in your given Polygon wallet address.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Order ID Created (Offline)</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Order Created</h2>
                                                                    <p>Your Order ID is _____. Please add your transaction hash and your sending wallet address on the Transaction page of the MAMBA dashboard. Our team will verify the transaction and will send MAMBA tokens in your given Polygon wallet address.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Transaction Approved</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">

                                                                    <h2 class="email-heading-s3 email-heading-success">Transaction Verified</h2>
                                                                    <p>We have successfully verified your transaction. We will send you MAMBA tokens shortly to your given Polygon wallet address.
                                                                        <br />
                                                                        You can continue to purchase MAMBA tokens.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Transaction Rejected</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-danger">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">

                                                                    <h2 class="email-heading-s3 email-heading-danger">Transaction Rejected</h2>
                                                                    <p>We have rejected your transaction. Please send us more information about the transaction which you have sent to us which can be screenshots, transaction hash, sending wallet address etc. on contact@mambaverse.io.
                                                                        <br />
                                                                        You can continue to purchase MAMBA tokens.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Token Sent by Admin</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Token Sent</h2>
                                                                    <p>We have successfully sent <b>50,000 MAMBA tokens</b> to your Polygon wallet address _______. Transaction hash for the MAMBA token is ______.
                                                                        <br />
                                                                        Please add the MAMBA contract address if you have not already addded it.
                                                                        <br /><br />
                                                                        <b>MAMBA token contract address</b>: 0xfc49642cabdb87587c84fa21decade6d8b33ab42
                                                                        <br />
                                                                        You can continue to purchase MAMBA tokens.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Account Suspended</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-danger">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-danger">Account Suspended</h2>
                                                                    <p>We have suspended your account. Please reach out to us at contact@mambaverse.io for further clarification.</p>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Referral Sign up</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Your referral  signed up on the dashboard,</h2>
                                                                    <p>Your referral (Full Name) has signed up using your referral link. When they make their first purchase, we will notify you.
                                                                        <br />
                                                                        Share your referral link with friends and family to earn a 15% referral bonus.
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Referral First Purchase</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Your referral just made their first purchase.</h2>
                                                                    <p>Your referral (Full Name) just made their first purchase of ______ MAMBA tokens. You have eanred a 15% - <b>50,000 MAMBA tokens</b> as referral bonus.
                                                                        <br />
                                                                        We will shortly send your bonus tokens into your given Polygon wallet address.
                                                                        <br /><br />
                                                                        Share your referral link with friends and family to earn a 15% referral bonus.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="hr"></div>
                                    <div class="card-head">
                                        <h6 class="card-title text-center">Email Notification - Bonus Token Sent by Admin</h6>
                                    </div>
                                    <div class="gaps-1x"></div>

                                    <table class="email-wraper mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="pdt-4x pdb-4x">
                                                    <table class="email-header">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdb-2-5x">
                                                                    <a href="#"><img class="email-logo" src="images/logo2x.png" alt="logo" /></a>
                                                                    <p class="email-title">Welcome to MambaVerse</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-body email-success">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pd-5x pdl-3x pdr-3x">
                                                                    <h2 class="email-heading-s3 email-heading-success">Bonus Token Sent</h2>
                                                                    <p>We have successfully sent <b>50,0000 bonus MAMBA tokens</b> to your Polygon wallet address _______. Transaction hash for the MAMBA token is ______.
                                                                        <br />
                                                                        Please add the MAMBA contract address if you have not already addded it.
                                                                        <br /><br />
                                                                        <b>MAMBA token contract address</b>: 0xfc49642cabdb87587c84fa21decade6d8b33ab42
                                                                        <br />
                                                                        You can continue to purchase MAMBA tokens.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="email-footer">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center pdt-2-5x pdl-2x pdr-2x">
                                                                    <p class="email-copyright-text">Copyright © 2021 MAMBAVERSE.</p>
                                                                    <ul class="email-social">
                                                                        <li><a target="_blank" href="https://www.instagram.com/MambaSocial/"><img src="instagram.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://www.facebook.com/MambaSocial/"><img src="images/brand-b.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://twitter.com/mambasocial"><img src="images/brand-e.png" alt="brand" /></a></li>
                                                                        <li><a target="_blank" href="https://discord.com/invite/Pe9Fxm3Y"><img src="discord.png" alt="brand" /></a></li>
                                                                        <li><a href="https://telegram.org/dl" target="_blank"><img src="telegram.png" alt="brand" /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="gaps-0-5x"></div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </body>

    )
}

export default index
