import { SpinnerCircular } from 'spinners-react';
import React, { useState, useEffect } from 'react'
import Header from '../header/index'
import Footer from '../Footer/index'
import Menu from '../menu/index'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import { constants } from 'ethers'
import Avatar from 'react-avatar';
import './avater.css'
import { BASE_URL } from '../../useContext/walletContext'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/dark.css";
import { Button, Modal } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet"
import SocailMedia from '../Socialbar/index'
const eye = <FontAwesomeIcon icon={faEye} />;


function Index() {
    const [oldpassword, setOldpassword] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [confirmpassword, setConfirmpassword] = useState('')
    const [fullname, setFullname] = useState('')
    const [dob, setDob] = useState('')
    const [nationality, setNationality] = useState('')
    const [phoneNumber, setphonenumber] = useState('')
    const [avater, setAvater] = useState('')
    const [uploadfile, setUploadfile] = useState('')
    const [imgebol, setImagebol] = useState(false)
    const [sessionimage, setsessionimage] = useState('')
    const [newpassicon, setNewpassicon] = useState(false)
    const [profileicon, setProfileicon] = useState(false)
    const [walletAddress, setWalletAddress] = useState('')
    const [newpassicon1, setNewpassicon1] = useState(false)
    const [loading, setLoading] = useState(false)
    const [referral, setreferral] = useState('')
    const [status, setStatus] = useState('')
    const [TwoAuthEnabled, setTwoAuthEnabled] = useState(false)
    const [isMount, setIsMount] = useState(false)
    const [checkAble, setCheckAble] = useState({ value: '', avaiblity: false })
    const [updateCode, setUpdateCode] = useState('')
    const [disabled, setDisabled] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getuserstatus = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/statusdetails`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {
           
            // setUsertoken(res?.data.result[0])
            setStatus(res.data.result[0])
            
            setIsMount(true)



        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }



    const sessionemail = window.sessionStorage.getItem('email')
    const getdata = async () => {
        const sessionfullname = await window.sessionStorage.getItem('fullName')
        const sessionimage = await window.sessionStorage.getItem('image')

        setFullname(sessionfullname)
        if (sessionimage.includes('https:')) {
            setsessionimage(`${sessionimage}`)

        } else {
            setsessionimage(`${BASE_URL}/${sessionimage}`)
        }


    }

    useEffect(() => {
        getdata()
        getwalletAddressforuser()
        getProfiledata()
        getReferralLink()
        getuserstatus()

    }, [])

    // useEffect(() => {

    // }, [])



    const getwalletAddressforuser = async () => {
        const sessionrole = await window.sessionStorage.getItem('WalletAddress')
        setWalletAddress(sessionrole)

    }
    const changePassword = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        // const sessionemail = await window.sessionStorage.getItem('email')

        const data = {
            oldpassword: oldpassword,
            newpassword: confirmpassword
        }
        setLoading(true)
        if (newpassword !== confirmpassword) {
            NotificationManager.error("new-password and Confirm-password not same")
            setLoading(false)
        } else {
            axios.put(`${BASE_URL}/api/ChangePassword-ico`, data, {
                headers: {
                    "userid": sessiontoken,

                }
            }).then((res) => {
                

                NotificationManager.success(res?.data?.message ?? "Password has been reset successfully")
                setNewpassicon1(true)
                setLoading(false)
                setTimeout(function () { setNewpassicon1(false) }, 3000);


                // setTimeout(setNewpassicon(false), 3000)

            }).catch((error) => {
                console.log(error?.response?.data?.message)
                NotificationManager.success(error.response.data.message ?? "Connection Failed")
                setLoading(false)
            })
        }

    }
    const HandleonSubmit = async (e) => {
        e.preventDefault()
        const sessiontoken = await window.sessionStorage.getItem('Token')
       
        const formData = new FormData()
        formData.append('DOB', dob ?? "")
        formData.append('Nationality', nationality ?? "")

        formData.append('fullName', fullname ?? "")

        formData.append('MobileNumber', phoneNumber ?? "")

        formData.append('image', uploadfile)

        // const data = {
        //     DOB: dob,
        //     Nationality: nationality,
        //     MobileNumber: phoneNumber,
        //     fullName: fullname,
        //     image: uploadfile
        // }
        setLoading(true)
        axios.put(`${BASE_URL}/api/updateProfile-ico`, formData, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {
            window.sessionStorage.setItem("fullName", res.data.result.fullName ?? "")
            // window.sessionStorage.setItem("email", res.data.result.email ?? "")
            window.sessionStorage.setItem("image", res.data.result.image ?? "")
            NotificationManager.success(res?.data?.message ?? "Profile Updated")
            setProfileicon(true)
            setTimeout(function () { setProfileicon(false) }, 3000);
            setLoading(false)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.success(error?.response?.data?.message ?? "Connection Failed")
            setLoading(false)
        })

    }

    const onchangeavater = (e) => {
        setImagebol(true)
        const reader = new FileReader(),
            files = e.target.files
        setUploadfile(files[0])
        reader.onload = function () {
            setAvater(reader.result)
        }
        reader.readAsDataURL(files[0])
        setUploadfile(files[0])
    }

    const fillname = (e) => {
        setFullname(e.target.value)
    }

    const getProfiledata = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getpersonaldetails-ico`, {
            headers: {
                "userid": sessiontoken
            }
        }).then((res) => {
           
            setDob(res.data.result[0].DOB)
            setNationality(res.data.result[0].Nationality)
            setphonenumber(res.data.result[0].MobileNumber)
            setTwoAuthEnabled(res.data.result[0].TwoAuthEnabled)
        }).catch((error) => {
            console.log(error)
        })
    }


    const getReferralLink = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getreferrallink`, {
            headers: {
                "userid": sessiontoken
            }
        }).then((res) => {
           
            setreferral(res?.data?.result?.referralLink)
        }).catch((error) => {
            console.log(error)
        })
    }

    const [passwordShownold, setPasswordShownold] = useState(false);
    const [passwordShownnew, setPasswordShownnew] = useState(false);
    const [passwordShownconfirm, setPasswordShownconfirm] = useState(false);
    const togglePasswordVisiblityold = () => {
        setPasswordShownold(passwordShownold ? false : true);
    };
    const togglePasswordVisiblitynew = () => {
        setPasswordShownnew(passwordShownnew ? false : true);
    };
    const togglePasswordVisiblityconfirm = () => {
        setPasswordShownconfirm(passwordShownconfirm ? false : true);
    };

    /* ********************************** Wallet Address **************** */
    const SubmitwalletAddress = async (e) => {
        e.preventDefault()
        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            WalletAddress: walletAddress
        }
        axios.put(`${BASE_URL}/api/WalletAddress`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
        
            NotificationManager.success("Wallet updated")
            window.sessionStorage.setItem("WalletAddress", res?.data?.result?.WalletAddress ?? "")
            setNewpassicon(true)
            setTimeout(function () { setNewpassicon(false) }, 2000);
            window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)

        })
    }

    // const getwalletAddressforuser = async () => {
    //     const sessionrole = await window.sessionStorage.getItem('WalletAddress')
    //     setWalletAddress(sessionrole)

    // }
    /* ********************************** End Wallet Address **************** */

    const TwoAuthSwitch = async () => {

        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            TwoAuthEnabled: TwoAuthEnabled === true ? false : true
        }
        axios.put(`${BASE_URL}/api/TwoAuthSwitch`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success("Two-Factor Authentication preference updated sucessfully.")
            window.sessionStorage.setItem("switch", res?.data?.result ?? false)

            window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error("Connection Failed")
            setLoading(false)

        })
    }


    const ReferralCodeAbvaliblelity = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')
        setLoading(true)
        axios.get(`${BASE_URL}/api/referralCodeget/${updateCode}`, {
            headers: {
                "userid": sessiontoken
            }
        }).then((res) => {

            if (res.data.resulte) {
                setCheckAble({ value: '', avaiblity: false })
                NotificationManager.error(`Oh snap! Your code is already taken. Please try a different one.`)
                setLoading(false)
            } else {
                setCheckAble({ value: updateCode, avaiblity: true })
                NotificationManager.success(`Yay! Your code is available. Please click on Update Code.`)
                setDisabled(true)
                setLoading(false)

            }



        }).catch((error) => {
            console.log(error)
            NotificationManager.error("Internal Issue")

        })
    }

    useEffect(() => {
        if (updateCode === checkAble.value) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [updateCode, ReferralCodeAbvaliblelity])


    const SubmitReferralCodepudate = async () => {

        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            NewCode: checkAble.value
        }
        axios.put(`${BASE_URL}/api/referralCodeUpdate`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            NotificationManager.success("Code updated")
            getReferralLink()
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            NotificationManager.error(error?.response?.data?.message ?? "Something Wrong")

        })
    }

    return (
        <div>

<Helmet>
        <title>Profile | MambaVerse - Bridge between physical and virtual worlds within the decentralized and open-source virtual world</title>
        <meta name="description" content="MambaVerse is a multi-faceted economy with P2P, B2C, & B2B marketplace which include collectibles, gaming items, and other virtual goods backed by blockchain technology." />
    </Helmet> 

            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>

                    </div>

                    <Header />


                </div>



                <div class="page-content">
                    <div class="container"><div class="row"><div class="main-content col-lg-8">
                        <div class="content-area card">
                            <div class="card-innr">
                                <div class="card-head"><h4 class="card-title">Profile Details</h4></div>
                                <ul class="nav nav-tabs nav-tabs-line" role="tablist"><li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#personal-data">Personal Data</a></li>
                                    {/* <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#settings">Settings</a></li> */}
                                    <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#password">Password</a></li>
                                </ul>
                                <div class="tab-content" id="profile-details">
                                    <div class="tab-pane fade show active" id="personal-data">
                                        <form onSubmit={HandleonSubmit}>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    {/* <Avatar src={imgebol ? avater : sessionimage} size="70" round={true} /> */}
                                                    <img src={imgebol ? avater : sessionimage} width={70} height={70} style={{ borderRadius: '50%' }} />



                                                    <input type="file" id="upload" hidden onChange={onchangeavater} />
                                                    &nbsp;&nbsp;&nbsp;<label class='avater' for="upload">Choose file</label>

                                                </div>
                                                <div class="col-md-6">
                                                </div>
                                                <div class="col-md-6"><div class="input-item input-with-label"><label for="full-name" class="input-item-label">Full Name</label><input class="input-bordered" type="text" placeholder='fullname' id="full-name1234" name="full-name" value={fullname} onChange={fillname} /></div></div>
                                                <div class="col-md-6"><div class="input-item input-with-label"><label for="email-address" class="input-item-label">Email Address</label><input class="input-bordered" type="text" id="email-address" name="email-address" value={sessionemail} disabled /></div></div>
                                                <div class="col-md-6"><div class="input-item input-with-label"><label for="mobile-number" class="input-item-label">Mobile Number</label><input class="input-bordered" type="text" id="mobile-number" name="mobile-number" value={phoneNumber} onChange={(e) => { setphonenumber(e.target.value) }} /></div></div>
                                                <div class="col-md-6"><div class="input-item input-with-label"><label for="date-of-birth" class="input-item-label">Date of Birth</label>
                                                    {/* <input class="input-bordered" type="date" id="date-of-birth" name="date-of-birth" value={dob} onChange={(e) => { setDob(e.target.value) }} /> */}
                                                    <Flatpickr className='form-control' dateFormat="MM/dd/yyyy"
                                                        value={dob}
                                                        options={{ altFormat: 'm/d/y' }}
                                                        onChange={(date, dateStr) => setDob(dateStr)}
                                                        id='default-picker' style={{ background: '#3b3b3b', color: 'rgb(235 235 235 / 70%)' }} />
                                                </div></div>
                                                <div class="col-md-6"><div class="input-item input-with-label"><label for="nationality" class="input-item-label">Nationality</label><select class="input-bordered select-block" name="nationality" id="nationality" value={nationality} onChange={(e) => { setNationality(e.target.value) }}>
                                                   
                                                    <option value='' selected disabled hidden>Select Country</option>
                                                    <option value="UNITED STATES">UNITED STATES</option>
                                                    <option value="AFGHANISTAN">AFGHANISTAN</option>
                                                    <option value="ÅLAND ISLANDS">ÅLAND ISLANDS</option>
                                                    <option value="ALBANIA">ALBANIA</option>
                                                    <option value="ALGERIA">ALGERIA</option>
                                                    <option value="AMERICAN">AMERICAN SAMOA</option>
                                                    <option value="ANDORRA">ANDORRA</option>
                                                    <option value="ANGOLA">ANGOLA</option>
                                                    <option value="ANGUILLA">ANGUILLA</option>
                                                    <option value="ANTARCTICA">ANTARCTICA</option>
                                                    <option value="ANTIGUA AND BARBUDA">ANTIGUA AND BARBUDA</option>
                                                    <option value="ARGENTINA">ARGENTINA</option>
                                                    <option value="ARMENIA">ARMENIA</option>
                                                    <option value="ARUBA">ARUBA</option>
                                                    <option value="AUSTRALIA">AUSTRALIA</option>
                                                    <option value="AUSTRIA">AUSTRIA</option>
                                                    <option value="AZERBAIJAN">AZERBAIJAN</option>
                                                    <option value="BAHAMAS">BAHAMAS</option>
                                                    <option value="BAHRAIN">BAHRAIN</option>
                                                    <option value="BANGLADESH">BANGLADESH</option>
                                                    <option value="BARBADOS">BARBADOS</option>
                                                    <option value="BELARUS">BELARUS</option>
                                                    <option value="BELGIUM">BELGIUM</option>
                                                    <option value="BELIZE">BELIZE</option>
                                                    <option value="BENIN">BENIN</option>
                                                    <option value="BERMUDA">BERMUDA</option>
                                                    <option value="BHUTAN">BHUTAN</option>
                                                    <option value="BOLIVIA">BOLIVIA</option>
                                                    <option value="BONAIRE, SINT EUSTATIUS AND SABA">BONAIRE, SINT EUSTATIUS AND SABA</option>
                                                    <option value="BOSNIA AND HERZEGOVINA">BOSNIA AND HERZEGOVINA</option>
                                                    <option value="BOTSWANA">BOTSWANA</option>
                                                    <option value="BOUVET ISLAND">BOUVET ISLAND</option>
                                                    <option value="BRAZIL">BRAZIL</option>
                                                    <option value="BRITISH INDIAN OCEAN TERRITORY">BRITISH INDIAN OCEAN TERRITORY</option>
                                                    <option value="BRITISH VIRGIN ISLANDS">BRITISH VIRGIN ISLANDS</option>
                                                    <option value="BRUNEI">BRUNEI</option>
                                                    <option value="BULGARIA">BULGARIA</option>
                                                    <option value="BURKINA">BURKINA FASO</option>
                                                    <option value="BURMA">BURMA</option>
                                                    <option value="BURUNDI">BURUNDI</option>
                                                    <option value="CAMBODIA">CAMBODIA</option>
                                                    <option value="CAMEROON">CAMEROON</option>
                                                    <option value="CANADA">CANADA</option>
                                                    <option value="CAPE VERDE">CAPE VERDE</option>
                                                    <option value="CAYMAN ISLANDS">CAYMAN ISLANDS</option>
                                                    <option value="CENTRAL AFRICAN REPUBLIC">CENTRAL AFRICAN REPUBLIC</option>
                                                    <option value="CHAD">CHAD</option>
                                                    <option value="CHILE">CHILE</option>
                                                    <option value="CHINA">CHINA</option>
                                                    <option value="CHRISTMAS ISLAND">CHRISTMAS ISLAND</option>
                                                    <option value="COCOS (KEELING) ISLANDS">COCOS (KEELING) ISLANDS</option>
                                                    <option value="COLOMBIA">COLOMBIA</option>
                                                    <option value="COMOROS">COMOROS</option>
                                                    <option value="CONGO">CONGO</option>
                                                    <option value="CONGO, THE DEMOCRATIC REPUBLIC OF THE">CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                                                    <option value="COOK ISLANDS">COOK ISLANDS</option>
                                                    <option value="COSTA RICA">COSTA RICA</option>
                                                    <option value="COTE D'IVOIRE">COTE D'IVOIRE</option>
                                                    <option value="CROATIA">CROATIA</option>
                                                    <option value="CUBA">CUBA</option>
                                                    <option value="CURACAO">CURACAO</option>
                                                    <option value="CYPRUS">CYPRUS</option>
                                                    <option value="CZECH REPUBLIC">CZECH REPUBLIC</option>
                                                    <option value="DENMARK">DENMARK</option>
                                                    <option value="DJIBOUTI">DJIBOUTI</option>
                                                    <option value="DOMINICA">DOMINICA</option>
                                                    <option value="DOMINICAN">DOMINICAN REPUBLIC</option>
                                                    <option value="ECUADOR">ECUADOR</option>
                                                    <option value="EGYPT">EGYPT</option>
                                                    <option value="EL SALVADOR">EL SALVADOR</option>
                                                    <option value="EQUATORIAL GUINEA">EQUATORIAL GUINEA</option>
                                                    <option value="ERITREA">ERITREA</option>
                                                    <option value="ESTONIA">ESTONIA</option>
                                                    <option value="ETHIOPIA">ETHIOPIA</option>
                                                    <option value="FALKLAND ISLANDS (MALVINAS)">FALKLAND ISLANDS (MALVINAS)</option>
                                                    <option value="FAROE ISLANDS">FAROE ISLANDS</option>
                                                    <option value="FIJI">FIJI</option>
                                                    <option value="FINLAND">FINLAND</option>
                                                    <option value="sdi_8522d3d739de43FRANCEad92b813daec02cee7">FRANCE</option>
                                                    <option value="FRENCH">FRENCH GUIANA</option>
                                                    <option value="FRENCH POLYNESIA">FRENCH POLYNESIA</option>
                                                    <option value="FRENCH SOUTHERN TERRITORIES">FRENCH SOUTHERN TERRITORIES</option>
                                                    <option value="GABON">GABON</option>
                                                    <option value="GAMBIA">GAMBIA</option>
                                                    <option value="sdi_90443d65ceb84bf3b61b95e600a5746f">GEORGIA</option>
                                                    <option value="GERMANY">GERMANY</option>
                                                    <option value="GHANA">GHANA</option>
                                                    <option value="GIBRALTAR">GIBRALTAR</option>
                                                    <option value="GREECE">GREECE</option>
                                                    <option value="GREENLAND">GREENLAND</option>
                                                    <option value="GRENADA">GRENADA</option>
                                                    <option value="GUADELOUPE">GUADELOUPE</option>
                                                    <option value="GUAM">GUAM</option>
                                                    <option value="GUATEMALA">GUATEMALA</option>
                                                    <option value="GUERNSEY">GUERNSEY</option>
                                                    <option value="GUINEA">GUINEA</option>
                                                    <option value="GUINEA BISSAU">GUINEA BISSAU</option>
                                                    <option value="GUYANA ">GUYANA</option>
                                                    <option value="HAITI">HAITI</option>
                                                    <option value="HEARD ISLAND AND MCDONALD ISLANDS">HEARD ISLAND AND MCDONALD ISLANDS</option>
                                                    <option value="HOLY SEE">HOLY SEE</option>
                                                    <option value="HONDURAS">HONDURAS</option>
                                                    <option value="HONG KONG">HONG KONG</option>
                                                    <option value="HUNGARY">HUNGARY</option>
                                                    <option value="ICELAND">ICELAND</option>
                                                    <option value="INDIA">INDIA</option>
                                                    <option value="INDONESIA">INDONESIA</option>
                                                    <option value="IRAN">IRAN</option>
                                                    <option value="IRAQ">IRAQ</option>
                                                    <option value="IRELAND">IRELAND</option>
                                                    <option value="ISLE OF MAN">ISLE OF MAN</option>
                                                    <option value="ISRAEL">ISRAEL</option>
                                                    <option value="ITALY">ITALY</option>
                                                    <option value="JAMAICA">JAMAICA</option>
                                                    <option value="JAPAN">JAPAN</option>
                                                    <option value="JERSEY">JERSEY</option>
                                                    <option value="JORDAN">JORDAN</option>
                                                    <option value="KAZAKHSTAN">KAZAKHSTAN</option>
                                                    <option value="KENYA">KENYA</option>
                                                    <option value="KIRIBATI">KIRIBATI</option>
                                                    <option value="KOSOVO">KOSOVO</option>
                                                    <option value="KUWAIT">KUWAIT</option>
                                                    <option value="KYRGYZSTAN">KYRGYZSTAN</option>
                                                    <option value="LAOS">LAOS</option>
                                                    <option value="LATVIA">LATVIA</option>
                                                    <option value="LEBANON">LEBANON</option>
                                                    <option value="LESOTHO">LESOTHO</option>
                                                    <option value="LIBERIA">LIBERIA</option>
                                                    <option value="LIBYA">LIBYA</option>
                                                    <option value="LIECHTENSTEIN">LIECHTENSTEIN</option>
                                                    <option value="LITHUANIA">LITHUANIA</option>
                                                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                                                    <option value="MACAU">MACAU</option>
                                                    <option value="MACEDONIA">MACEDONIA</option>
                                                    <option value="MADAGASCAR">MADAGASCAR</option>
                                                    <option value="MALAWI">MALAWI</option>
                                                    <option value="MALAYSIA">MALAYSIA</option>
                                                    <option value="MALDIVES">MALDIVES</option>
                                                    <option value="MALI">MALI</option>
                                                    <option value="MALTA">MALTA</option>
                                                    <option value="MARSHALL ISLANDS">MARSHALL ISLANDS</option>
                                                    <option value="MARTINIQUE">MARTINIQUE</option>
                                                    <option value="MAURITANIA">MAURITANIA</option>
                                                    <option value="MAURITIUS">MAURITIUS</option>
                                                    <option value="MAYOTTE">MAYOTTE</option>
                                                    <option value="MEXICO">MEXICO</option>
                                                    <option value="MICRONESIA">MICRONESIA</option>
                                                    <option value="MOLDOVA">MOLDOVA</option>
                                                    <option value="MONACO">MONACO</option>
                                                    <option value="MONGOLIA">MONGOLIA</option>
                                                    <option value="MONTENEGRO">MONTENEGRO</option>
                                                    <option value="MONTSERRAT">MONTSERRAT</option>
                                                    <option value="MOROCCO">MOROCCO</option>
                                                    <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                                                    <option value="NAMIBIA">NAMIBIA</option>
                                                    <option value="NAURU">NAURU</option>
                                                    <option value="NEPAL">NEPAL</option>
                                                    <option value="NETHERLANDS">NETHERLANDS</option>
                                                    <option value="NEW CALEDONIA">NEW CALEDONIA</option>
                                                    <option value="NEW ZEALAND">NEW ZEALAND</option>
                                                    <option value="NICARAGUA">NICARAGUA</option>
                                                    <option value="NIGER">NIGER</option>
                                                    <option value="NIGERIA">NIGERIA</option>
                                                    <option value="NIUE">NIUE</option>
                                                    <option value="NORFOLK ISLAND">NORFOLK ISLAND</option>
                                                    <option value="NORTH KOREA">NORTH KOREA</option>
                                                    <option value="NORTHERN MARIANA ISLANDS">NORTHERN MARIANA ISLANDS</option>
                                                    <option value="NORWAY">NORWAY</option>
                                                    <option value="OMAN">OMAN</option>
                                                    <option value="PAKISTAN">PAKISTAN</option>
                                                    <option value="PALAU">PALAU</option>
                                                    <option value="PALESTINE">PALESTINE</option>
                                                    <option value="PANAMA">PANAMA</option>
                                                    <option value="PAPUA NEW GUINEA">PAPUA NEW GUINEA</option>
                                                    <option value="PARAGUAY">PARAGUAY</option>
                                                    <option value="PERU">PERU</option>
                                                    <option value="PHILIPPINES">PHILIPPINES</option>
                                                    <option value="PITCAIRN">PITCAIRN</option>
                                                    <option value="POLAND">POLAND</option>
                                                    <option value="PORTUGAL">PORTUGAL</option>
                                                    <option value="PUERTO RICO">PUERTO RICO</option>
                                                    <option value="QATAR">QATAR</option>
                                                    <option value="RÉUNION">RÉUNION</option>
                                                    <option value="ROMANIA">ROMANIA</option>
                                                    <option value="RUSSIA">RUSSIA</option>
                                                    <option value="RWANDA">RWANDA</option>
                                                    <option value="SAINT BARTHÉLEMY">SAINT BARTHÉLEMY</option>
                                                    <option value="SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA">SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA</option>
                                                    <option value="SAINT KITTS AND NEVIS">SAINT KITTS AND NEVIS</option>
                                                    <option value="SAINT LUCIA">SAINT LUCIA</option>
                                                    <option value="SAINT MARTIN (FRENCH PART)">SAINT MARTIN (FRENCH PART)</option>
                                                    <option value="SAINT PIERRE AND MIQUELON">SAINT PIERRE AND MIQUELON</option>
                                                    <option value="SAINT VINCENT">SAINT VINCENT</option>
                                                    <option value="SAMOA">SAMOA</option>
                                                    <option value="SAN MARINO">SAN MARINO</option>
                                                    <option value="SAO TOME">SAO TOME</option>
                                                    <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                                                    <option value="SENEGAL">SENEGAL</option>
                                                    <option value="SERBIA">SERBIA</option>
                                                    <option value="SEYCHELLES">SEYCHELLES</option>
                                                    <option value="SIERRA LEONE">SIERRA LEONE</option>
                                                    <option value="SINGAPORE">SINGAPORE</option>
                                                    <option value="SINT MAARTEN">SINT MAARTEN</option>
                                                    <option value="SLOVAKIA">SLOVAKIA</option>
                                                    <option value="SLOVENIA">SLOVENIA</option>
                                                    <option value="SOLOMON">SOLOMON ISLANDS</option>
                                                    <option value="SOMALIA">SOMALIA</option>
                                                    <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                                                    <option value="sdi_3bda1afb59414a0591ac0582f74a82c2">SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS</option>
                                                    <option value="SOUTH KOREA">SOUTH KOREA</option>
                                                    <option value="SOUTH SUDAN">SOUTH SUDAN</option>
                                                    <option value="SPAIN">SPAIN</option>
                                                    <option value="SRI LANKA">SRI LANKA</option>
                                                    <option value="SUDAN">SUDAN</option>
                                                    <option value="SURINAME">SURINAME</option>
                                                    <option value="SVALBARD AND JAN MAYEN">SVALBARD AND JAN MAYEN</option>
                                                    <option value="SWAZILAND">SWAZILAND</option>
                                                    <option value="SWEDEN">SWEDEN</option>
                                                    <option value="SWITZERLAND">SWITZERLAND</option>
                                                    <option value="SYRIA">SYRIA</option>
                                                    <option value="TAIWAN">TAIWAN</option>
                                                    <option value="TAJIKISTAN">TAJIKISTAN</option>
                                                    <option value="TANZANIA">TANZANIA</option>
                                                    <option value="THAILAND">THAILAND</option>
                                                    TIMOR-LESTE                                                <option value="TOGO">TOGO</option>
                                                    <option value="TOKELAU">TOKELAU</option>
                                                    <option value="TONGA">TONGA</option>
                                                    <option value="TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                                                    <option value="TUNISIA">TUNISIA</option>
                                                    <option value="TURKEY">TURKEY</option>
                                                    <option value="TURKMENISTAN">TURKMENISTAN</option>
                                                    <option value="TURKS AND CAICOS">TURKS AND CAICOS</option>
                                                    <option value="TUVALU">TUVALU</option>
                                                    <option value="UGANDA">UGANDA</option>
                                                    <option value="UKRAINE">UKRAINE</option>
                                                    <option value="UNITED ARAB EMIRATES">UNITED ARAB EMIRATES</option>
                                                    <option value="UNITED KINGDOM">UNITED KINGDOM</option>
                                                    <option value="UNITED STATES MINOR OUTLYING ISLANDS">UNITED STATES MINOR OUTLYING ISLANDS</option>
                                                    <option value="URUGUAY">URUGUAY</option>
                                                    <option value="UZBEKISTAN">UZBEKISTAN</option>
                                                    <option value="VANUATU">VANUATU</option>
                                                    <option value="VENEZUELA">VENEZUELA</option>
                                                    <option value="VIETNAM">VIETNAM</option>
                                                    <option value="VIRGIN ISLANDS, U.S.">VIRGIN ISLANDS, U.S.</option>
                                                    <option value="WALLIS AND FUTUNA">WALLIS AND FUTUNA</option>
                                                    <option value="WESTERN SAHARA">WESTERN SAHARA</option>
                                                    <option value="YEMEN">YEMEN</option>
                                                    <option value="ZAMBIA">ZAMBIA</option>
                                                    <option value="ZIMBABWE">ZIMBABWE</option>

                                                </select></div></div>
                                            </div><div class="gaps-1x"></div><div class="d-sm-flex justify-content-between align-items-center"><button class="btn btn-primary" onClick={HandleonSubmit} disabled={false}>{loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Update Profile"}</button><div class="gaps-2x d-sm-none"></div>{profileicon ? <span class="text-success"><em class="ti ti-check-box"></em> All Changes are saved</span> : null}</div></form></div>

                                    <div class="tab-pane fade" id="settings"><div class="pdb-1-5x"><h5 class="card-title card-title-sm text-dark">Security Settings</h5>    </div><div class="input-item"><input type="checkbox" class="input-switch input-switch-sm" id="save-log" checked /><label for="save-log abhimanyu">Save my Activities Log</label></div><div class="input-item"><input type="checkbox" class="input-switch input-switch-sm" id="pass-change-confirm1" /><label for="pass-change-confirm1">Confirm me through email before password change</label></div><div class="pdb-1-5x"><h5 class="card-title card-title-sm">Manage Notification</h5>    </div><div class="input-item"><input type="checkbox" class="input-switch input-switch-sm" id="latest-news" checked /><label for="latest-news abhimanyu">Notify me by email about sales and latest news</label></div><div class="input-item"><input type="checkbox" class="input-switch input-switch-sm" id="activity-alert" checked /><label for="activity-alert">Alert me by email for unusual activity.</label></div><div class="gaps-1x"></div><div class="d-flex justify-content-between align-items-center"><span></span><span class="text-success"><em class="ti ti-check-box"></em> Setting has been updated</span></div></div>

                                    <div class="tab-pane fade" id="password">
                                        <div class="row"><div class="col-md-6">
                                            <div class="input-item input-with-label">
                                                <label for="old-pass" class="input-item-label">Old Password</label><input class="input-bordered" type={passwordShownold ? "text" : "password"} id="old-pass" name="old-pass" onChange={(e) => { setOldpassword(e.target.value) }} />
                                                <i onClick={togglePasswordVisiblityold} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                            </div></div></div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="input-item input-with-label"><label for="new-pass" class="input-item-label">New Password</label><input class="input-bordered" type={passwordShownnew ? "text" : "password"} id="new-pass" name="new-pass" onChange={(e) => { setNewpassword(e.target.value) }} />
                                                    <i onClick={togglePasswordVisiblitynew} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                                </div>
                                            </div><div class="col-md-6">
                                                <div class="input-item input-with-label"><label for="confirm-pass" class="input-item-label">Confirm New Password</label><input class="input-bordered" type={passwordShownconfirm ? "text" : "password"} name="confirm-pass" onChange={(e) => { setConfirmpassword(e.target.value) }} id="id_password" />
                                                    <i onClick={togglePasswordVisiblityconfirm} class='input-item-label' style={{ marginLeft: '-23px' }}>{eye}</i>
                                                </div>
                                            </div></div>
                                        <div class="note note-plane note-info pdb-1x"><em class="fas fa-info-circle"></em><p>Password should be minmum 8 letter and include lower and uppercase letter.</p></div><div class="gaps-1x"></div><div class="d-sm-flex justify-content-between align-items-center"><button class="btn btn-primary" onClick={changePassword} disabled={false}>{loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Update"}</button><div class="gaps-2x d-sm-none"></div>{newpassicon ? <span class="text-success"><em class="ti ti-check-box"></em>  Changed Password</span> : null}</div></div>

                                </div>
                            </div></div><div class="content-area card"><div class="card-innr"><div class="card-head"><h4 class="card-title">Two-Factor Authentication</h4></div><p>Two-Factor Authentication enables you to add another layer of security to your account.</p><div class="d-sm-flex justify-content-between align-items-center pdt-1-5x"><span class="text-light ucap d-inline-flex align-items-center"><span class="mb-0"><small>Current Status:</small></span> <span class=""><small class='deepak'>&nbsp;{TwoAuthEnabled ? "Active" : "Deactive"}</small></span></span><div class="gaps-2x d-sm-none"></div><button class="order-sm-first btn btn-primary" onClick={TwoAuthSwitch} >{TwoAuthEnabled ? "Disabled 2FA" : "Enable 2FA"}</button></div></div></div></div><div class="aside sidebar-right col-lg-4">

                            {walletAddress !== "" ? <div class="account-info card">
                                <div class="card-innr">
                                    <h6 class="card-title card-title-sm">Your Account Status</h6>
                                    <ul class="btn-grp">
                                        {status.AccountVerify ? <li>
                                            <a href="#" class="btn btn-auto btn-xs btn-success">Email Verified</a></li> : <li>
                                            <a href="#" class="btn btn-auto btn-xs btn-warning">Email Pending</a></li>}
                                        {status.KycForm ? <li><a href="#" class="btn btn-auto btn-xs btn-success">KYC Verified </a></li> : <li><a href="#" class="btn btn-auto btn-xs btn-warning">KYC Pending</a></li>}
                                    </ul>
                                    <div class="gaps-2-5x"></div><h6 class="card-title card-title-sm">Receiving Wallet</h6><div class="d-flex justify-content-between"><span><span>{walletAddress?.substring(0, 4) ?? ""}...{walletAddress?.substring(walletAddress?.length - 4) ?? ""}</span> <em class="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="ERC20 Compliant Polygon Wallet"></em></span><a href="#" data-toggle="modal" data-target="#edit-wallet" class="link link-ucap">Edit</a></div></div></div> : null}

                            <div class="referral-info card"><div class="card-innr">
                                <div className='referralCodeEdit'><h6 class="card-title card-title-sm">Earn with Referral</h6><button class='referralCodeEditbutton' style={{ cursor: 'pointer' }} onClick={handleShow} >EDIT</button></div>
                                <p class=" pdb-0-5x">Invite friends & family to receive a<strong><span class="text-primary">  15% Token Bonus </span> - based off their contribution</strong></p>
                                <span class="text-primary">Link</span>
                                <div class="copy-wrap mgb-0-5x">

                                    <span class="copy-feedback"></span><em class="fas fa-link"></em><input type="text" class="copy-address" value={`https://ido.mambapayments.com/referral/${referral}`} disabled /><button class="copy-trigger copy-clipboard" data-clipboard-text={`https://ido.mambapayments.com/referral/${referral}`}><em class="ti ti-files"></em></button></div>
                                <div class='text-center mt-2 mb-2'><span class='text-primary '>OR</span></div>
                                <span class="text-primary">Referral Code</span>
                                <div class="copy-wrap mgb-0-5x">

                                    <span class="copy-feedback"></span><em class="fas fa-link"></em><input type="text" class="copy-address" value={`${referral}`} disabled /><button class="copy-trigger copy-clipboard" data-clipboard-text={`${referral}`}><em class="ti ti-files"></em></button></div>
                            </div></div>

                            <div class="kyc-info card">
                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter "
                                    centered
                                    show={show} onHide={handleClose}>
                                    <Modal.Body>
                                        <div class="popup-body"><h4 class="popup-title">Referral Code</h4> <p>Customize your Referal Code</p>

                                            <div class="input-item input-with-label"><label for="token-address" class="input-item-label text-primary">Your Referral Code:</label>
                                                <input class="input-bordered" type="text" id="token-address" name="token-address" value={referral} disabled /></div>
                                            {/* <div class='inputEditButton'> */}
                                            <div class="input-item input-with-label"><label for="token-address" class="input-item-label text-primary">Your New Referral Code:</label>
                                                <div class='inputEditButton'>
                                                    <input class="input-bordered" type="text" id="token-address" name="token-address" value={updateCode} onChange={(e) => { setUpdateCode(e.target.value) }} /><button class='btn btn-primary m-1' onClick={ReferralCodeAbvaliblelity}> {loading ? (<SpinnerCircular style={{ width: '1.2rem', height: '1.2rem' }} color="light" />) : "Check Availability"} </button></div></div>
                                            <div class="gaps-3x"></div><div class="d-sm-flex justify-content-between align-items-center"><button class="btn btn-primary" disabled={disabled} onClick={SubmitReferralCodepudate}>Update Code</button><div class="gaps-2x d-sm-none"></div>

                                            </div></div>

                                    </Modal.Body>

                                </Modal>
                            </div></div></div></div></div>
                <SocailMedia />
                <Footer />


                <div class="modal fade" id="edit-wallet" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content"><a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a><div class="popup-body"><h4 class="popup-title">Wallet Address</h4><p>In order to receive your <a href="#"><strong>MAMBATokens</strong></a>, please select your wallet address and you have to put the address below input box. <strong>You will receive MAMBAtokens to this address after the Token Sale end.</strong></p><form onSubmit={SubmitwalletAddress}><div class="row"><div class="col-md-6"><div class="input-item input-with-label">

                </div></div></div><div class="input-item input-with-label"><label for="token-address" class="input-item-label">Your Address for tokens:</label><input class="input-bordered" type="text" id="token-address" name="token-address" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} /><span class="input-note">Note: Address should be ERC20-compliant.</span></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb, Binance etc. You can use MetaMask, MayEtherWallet, Mist wallets etc. Do not use the address if you don’t have a private key of the your address. You WILL NOT receive MAMBATokens and WILL LOSE YOUR FUNDS if you do.</p></div><div class="gaps-3x"></div><div class="d-sm-flex justify-content-between align-items-center"><button class="btn btn-primary">Update Wallet</button><div class="gaps-2x d-sm-none"></div>
                        {newpassicon1 ? <span class="text-success"><em class="ti ti-check-box"></em> Updated wallet address</span> : null}
                    </div></form></div></div></div></div>

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
