import React, { useEffect, useState, useContext } from 'react'
import '../../assets/css/vendor.bundle49f7.css?ver=104.css'
import '../../assets/css/style49f7.css'
import Amchart from './amchart'
import Header from '../header/index'
import Footer from '../Footer/index'
import Menu from '../menu/index'
import img1 from '../../images/logo-light-sm.png'
import MambaNetwork from '../../images/MambaNetwork.pdf'
import Countdown from "react-countdown"
import '../MaiCustom.css'
import axios from 'axios'
import Moment from 'react-moment'
import NumberFormat from 'react-number-format'
// import Moment from 'react-moment'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'
import { StoreContext1 } from '../../useContext/pathContext'

import SocailMedia from '../Socialbar/index'
import context from 'react-bootstrap/esm/AccordionContext'
import {Helmet} from "react-helmet";

function Index() {
    const history = useNavigate()
    const [data, setData] = useState([])
    const context1 = useContext(StoreContext1)
    const [inputvalue, setInputValue] = useState()
    const [inputvaluetoken, setInputValuetoken] = useState()
    const [getUSDvalue, setGetUSDvalue] = useState('')
    const [getMATIC, setGetMATIC] = useState(false)
    const [getETH, setGetETH] = useState(false)
    const [getUSD, setGetUSD] = useState(false)
    const [eth, setEth] = useState(false)
    const [maticinputvalue, setmaticinputvalue] = useState(false)
    const [getMATICUSDvalue, setGetMATICUSDvalue] = useState('')
    const [usertoken, setUsertoken] = useState('')
    const [tmecounter, setTmecounter] = useState('')
    const [token,setToken] = useState([])
    const [liveEth,setLiveEth] = useState(false)
    const [CalculatorEth,setCalculatorEth] = useState(false)

    const gotranscation = () => {
        history('/Transactions')
    }

    const BuyToken = () => {
        history('/BuyToken')
    }
    /* ******************ETH************************* */
    const usdToken = (e) => {
        setInputValuetoken(e.target.value)
        
        setInputValue((e.target.value / 20000) / getUSDvalue)
       
        context1.setPath({ ...context1.path, calculatorInput: (e.target.value / 20000) / getUSDvalue, calculatorOutput: e.target.value })
        // context1.setPath({  })

    }
    const usdAmount = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getUSDvalue * 20000)
      
        context1.setPath({ ...context1.path, calculatorOutput: e.target.value * getUSDvalue * 20000, calculatorInput: e.target.value })


    }
    /* **************************END ETH******************* */
    /* ***************************MATIC ******************* */
    const maticinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue((e.target.value / 20000) / getMATICUSDvalue)
        context1.setPath({ ...context1.path, calculatorOutput: e.target.value, calculatorInput: (e.target.value / 20000) / getMATICUSDvalue })
    }
    const mambamaticinput = (e) => {
        
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getMATICUSDvalue * 20000)
        context1.setPath({ ...context1.path, calculatorInput: e.target.value, calculatorOutput: e.target.value * getMATICUSDvalue * 20000 })
    }
    /* *********************END MATIC***************************** */
    /* **********************USD ********************************* */
    const usdinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue(e.target.value / 20000)
        context1.setPath({ ...context1.path, calculatorOutput: e.target.value, calculatorInput: e.target.value / 20000 })
    }
    const mambusdnput = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * 20000)
        context1.setPath({ ...context1.path, calculatorInput: e.target.value, calculatorOutput: e.target.value * 20000 })
    }

    /* ***********************END USD*****************************/

    const usdEthgetvalue = (e) => {
        setEth(false)
        context1.setPath({ ...context1.path, currency: "eth" })
    }
    const getmaticinput = (e) => {
        setEth(true)
        setmaticinputvalue(true)
        context1.setPath({ ...context1.path, currency: "matic" })
      
        // console.log('step1')
        getmaticusdpriceforinput()

    }
    const getusdinput = (e) => {
        setEth(true)
        setmaticinputvalue(false)
        context1.setPath({ ...context1.path, currency: "usd" })
        

    }

    const getethinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {
            
            setGetUSDvalue(res?.data?.USD)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const getmaticusdpriceforinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD').then((res) => {
           
            setGetMATICUSDvalue(res?.data?.USD)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/tsxndetailsfor`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            setData(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }

    useEffect(() => {
       
        if (context1.path.newdata === '/KycApplication') {
            history('/KycApplication')
            context1.setPath({ ...context1.path, newdata: "" })
        }
        gettrancation()
        getusdprice()
        getethinput()
        getusertoken()
        gettimecounter()
        gettoken()
    }, [])

    const getmaticusdprice = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD').then((res) => {
            
            setGetMATICUSDvalue(res.data.USD)
          
            setGetUSD(true)
            setGetETH(false)
            setGetMATIC(true)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    const getusdprice = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {
           
            setGetETH(true)
            setGetUSD(true)
            setGetUSDvalue(res?.data?.USD)

        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const getusd = () => {
        setGetETH(false)
        setGetUSD(false)
        setGetMATIC(false)
    }

    /* **********************************get user token,eth,matic ,and usd **************** */
    const getusertoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/usertoken`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {
           
            setUsertoken(res?.data.result[0])


        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    /* **********************************END user token,eth,matic ,and usd **************** */

    /* *********************TimeCounter**************************** */

    const gettimecounter = () => {
        axios.get(`${BASE_URL}/api/gettimeCouter`).then((res) => {
            
            setTmecounter(res.data.result[0].timeCounter)
        }).catch((error) => {
            console.log(error)

        })
    }

     /* *******************************get total mamba,ETH,MAtic,USD ************************** */
     const gettoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getadmintotalsoldtoken`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            setToken(res?.data?.result)
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    /* *******************************get total mamba,ETH,MAtic,USD ************************** */


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return (
                <div class="countdown-clock" data-date="2021/11/08">
                    <div>
                        <span class="countdown-time countdown-time-first abhimanyu">{days}</span>
                        <span class="countdown-text abhimanyu">Day</span>
                    </div>
                    <div>
                        <span class="countdown-time abhimanyu">{hours}</span>
                        <span class="countdown-text abhimanyu">Hour</span>
                    </div>
                    <div>
                        <span class="countdown-time abhimanyu">{minutes}</span>
                        <span class="countdown-text abhimanyu">Min</span>
                    </div>
                    <div>
                        <span class="countdown-time countdown-time-last abhimanyu">{seconds}</span>
                        <span class="countdown-text abhimanyu">Sec</span>
                    </div>
                </div>
            );
        } else {
            // Render a countdown
            return (

                <div class="countdown-clock" data-date="2021/11/08">
                    <div>
                        <span class="countdown-time countdown-time-first abhimanyu">{days}</span>
                        <span class="countdown-text abhimanyu">Day</span>
                    </div>
                    <div>
                        <span class="countdown-time abhimanyu">{hours}</span>
                        <span class="countdown-text abhimanyu">Hour</span>
                    </div>
                    <div>
                        <span class="countdown-time abhimanyu">{minutes}</span>
                        <span class="countdown-text abhimanyu">Min</span>
                    </div>
                    <div>
                        <span class="countdown-time countdown-time-last abhimanyu">{seconds}</span>
                        <span class="countdown-text abhimanyu">Sec</span>
                    </div>
                </div>

            );
        }
    };
    /* ********************* End TimeCounter**************************** */



    return (
        <div>

 <Helmet>
        <title>Dashboard | MambaVerse - Bridge between physical and virtual worlds within the decentralized and open-source virtual world</title>
        <meta name="description" content="MambaVerse is a multi-faceted economy with P2P, B2C, & B2B marketplace which include collectibles, gaming items, and other virtual goods backed by blockchain technology." />
    </Helmet> 

            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky" >
                        <div class="container">
                            <Menu />
                        </div>
                    </div>
                    <Header />
                </div>

                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="token-statistics card card-token height-auto">
                                    <div class="card-innr">
                                        <div class="token-balance text-center">
                                            {/* <div class="token-balance-icon">
                                                <img src={img1} alt="logo" />
                                            </div> */}
                                            <div class="token-balance-text">
                                                <h6 class="card-sub-title">Balance</h6>
                                                <span class="lead">{/* {usertoken?.totaltoken == null ? 0 : usertoken?.totaltoken.toFixed(2) ?? 0} 
                                                */}
                                                    <NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={usertoken?.totaltoken == null ? 0 : usertoken?.totaltoken.toFixed(2) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />    <span>MAMBA</span></span>
                                            </div>
                                        </div>
                                        <div class="token-balance token-balance-s2">
                                            <h6 class="card-sub-title" style={{ textAlign: "center" }}>Your Contribution</h6>
                                            <ul class="token-balance-list" style={{ textAlign: "center" }}>
                                                <li class="token-balance-sub"><span class="lead">
                                                    <NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={usertoken?.totaleth == null ? 0 : usertoken?.totaleth.toFixed(5) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                </span><span class="sub">ETH</span></li>
                                                <li class="token-balance-sub"><span class="lead">
                                                    <NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={usertoken?.totalmatic == null ? 0 : usertoken?.totalmatic.toFixed(5) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                </span><span class="sub">MATIC</span></li>
                                                <li class="token-balance-sub"><span class="lead">
                                                    <NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={usertoken?.totalusd ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                </span><span class="sub">USD</span></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-8">
                                <div class="token-information card card-full-height">
                                    <div class="row no-gutters height-100">
                                        <div class="col-md-6 text-center">
                                            {getETH ? <><div class={`token-info ${liveEth?"show":""}`}>
                                                <div class="gaps-2x"></div>
                                                <a class={`nav-link dropdown-toggle `} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setLiveEth(!liveEth)}>
                                                1 ETH
        </a>
                                                <div class={`dropdown-menu ${liveEth?"show":""} `} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
                                                <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={getusdprice} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticusdprice} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusd} class='linkactive'>USD</a></li>
                                                    </ul>
        </div>
                                                {/* <h1 class="token-info-head text-light toggle-tigger toggle-caret">1 ETH</h1>
                                                <div class="toggle-class dropdown-content">
                                                    <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={getusdprice} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticusdprice} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusd} class='linkactive'>USD</a></li>
                                                    </ul>
                                                </div> */}
                                                <h1>= <NumberFormat style={{ fontSize: '14px' }}
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getUSDvalue * 20000}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />  <span class="lead">MAMBA</span></h1>

                                                <h5 class="token-info-sub">1 ETH = <NumberFormat


                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getUSDvalue}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />  USD</h5>
                                            </div> </> : <><div class="token-info">
                                                <div class="gaps-2x"></div>
                                                {/* <h1 class="token-info-head text-light toggle-tigger toggle-caret">1 {getMATIC ? "MATIC" : "USD"} </h1> */}
                                                <a class={`nav-link dropdown-toggle ${liveEth?"show":""}`} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setLiveEth(!liveEth)}>
                                                1 {getMATIC ? "MATIC" : "USD"}
        </a>
        <div class={`dropdown-menu `} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
                                                    <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={getusdprice} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticusdprice} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusd} class='linkactive'>USD</a></li>
                                                    </ul>
                                                </div>
                                                {getUSD ? <><h1>=<NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getMATICUSDvalue * 20000}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />   <span class="lead">MAMBA</span></h1></> : <><h1>=  20000 <span class="lead">MAMBA</span></h1></>}

                                                {getUSD ? <h5 class="token-info-sub">1 {getMATIC ? "MATIC" : "USD"}=<NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getMATICUSDvalue}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />   USD</h5> : null}
                                            </div> </>}
                                        </div>
                                        <div class="col-md-6">
                                            <div class="token-info bdr-tl">
                                                <div>
                                                    <ul class="token-info-list">
                                                        <li><span>Token Name:</span>MambaVerse</li>
                                                        <li><span>Token Symbol:</span>MAMBA</li>
                                                        <li><span>Token Supply:</span>200 Billion</li>
                                                        <li><span>Total Token Sold:</span>&nbsp;<NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.mambaToken === null ? 0 : parseFloat(token[0]?.mambaToken).toFixed(2) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />&nbsp;MAMBA</li>
                                                    </ul>
                                                    {/* <a href={MambaNetwork} class="btn btn-primary" download ><em class="fas fa-download mr-3"></em>Download Whitepaper</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-xl-7 col-lg-7">
                                <div class="token-transaction card card-full-height"><div class="card-innr" style={{ overflow: 'auto' }}>
                                    <div class="card-head has-aside">
                                        <h4 class="card-title">Transactions</h4>
                                        <div class="card-opt">
                                            <a onClick={gotranscation} class="link ucap">View ALL <em class="fas fa-angle-right ml-2"></em></a>
                                        </div>
                                    </div>
                                    {data.length !== 0 ? <table class="table tnx-table">
                                        <thead>
                                            <tr>
                                                <th>MAMBA</th>
                                                <th>Date</th>
                                                <th class="d-none d-sm-table-cell tnx-date">Amount</th>

                                                <th class="tnx-type"><div class="tnx-type-text"></div> Transaction Type</th>
                                            </tr>

                                        </thead>

                                        <tbody>
                                            {data.slice(0, 4).map((ele, index) => {
                                                const fromfirst = ele?.transactionHash?.substring(0, 4)
                                                const fromlast = ele?.transactionHash?.substring(ele.transactionHash?.length - 4)
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <div class="d-flex align-items-center">
                                                                    {ele.transactionStats === 'Pending' && ele.transactionHash !== '' ? <div class="data-state data-state-progress"><span class="d-none">Progress</span></div> : ele.transactionStats === 'Accepted' ? <div class="data-state data-state-approved"><span class="d-none">Approved</span></div> : ele.transactionHash === '' ? <div class="data-state data-state-pending"><span class="d-none">Pending</span></div> : <div class="data-state data-state-canceled"><span class="d-none">Canceled</span></div>}<span class="lead"><NumberFormat
                                                                        thousandsGroupStyle="thousand"
                                                                        // format="$#########0"
                                                                        value={parseFloat(ele?.mambaToken ?? 0).toFixed(3)}
                                                                        prefix=""
                                                                        decimalSeparator="."
                                                                        displayType="text"
                                                                        type="text"
                                                                        thousandSeparator={true}
                                                                        allowNegative={true}
                                                                        suffix={0} /> </span></div></td>

                                                            <td>
                                                                <span><span class="lead"><Moment format="MM-DD-YYYY" date={new Date(ele.createdAt)} /></span></span></td>

                                                            <td class="d-none d-sm-table-cell tnx-date"><span class="sub sub-s2">{/* {fromfirst}...{fromlast} */}{ele.ethAmount != null ? ele.ethAmount : ele.maticAmount != null ? ele.maticAmount : ele.usdAmount}</span></td>

                                                            <td class="tnx-type text-center"><span class="tnx-type-md badge badge-outline badge-success badge-md abhimanyu">Purchase</span><span class="tnx-type-sm badge badge-sq badge-outline badge-success badge-md abhimanyu">P</span></td>

                                                        </tr>
                                                    </>
                                                )
                                            }
                                            )}
                                        </tbody>

                                    </table> : <table class="table tnx-table">
                                        <thead>
                                            <tr>
                                                <th>MAMBA</th>
                                                <th>Date</th>
                                                <th class="d-none d-sm-table-cell tnx-date">Transaction</th>
                                                <th class="tnx-type"><div class="tnx-type-text"></div> Transaction Type</th>
                                            </tr>

                                        </thead>
                                        <td></td>
                                        <td></td>
                                        <td class='text-center' style={{ textAlign: 'center' }}><card >No Transactions Available
                                            <div class="token-buy">
                                                <a onClick={BuyToken} class="btn btn-primary">Buy Tokens</a>
                                            </div></card></td>
                                        <td></td>

                                    </table>}

                                </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5"><div class="token-calculator card card-full-height">
                                <div class="card-innr">
                                    <div class="card-head">
                                        <h4 class="card-title">Calculator</h4>
                                        <p class="card-title-text">Enter amount to calculate token.</p>
                                    </div>
                                    <div class="token-calc">
                                        {eth ? <><div class="token-pay-amount">
                                            <input id="token-base-amount1212" class="input-bordered" type="text" value={inputvalue} onChange={maticinputvalue ? mambamaticinput : mambusdnput} />
                                            <div class={`token-pay-currency ${CalculatorEth? "show":""}`} style={{ top: "9px" }}>
                                                <a class={`nav-link dropdown-toggle `} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{padding:'0px'}} onClick={() => setCalculatorEth(!CalculatorEth)}>{maticinputvalue ? "MATIC" : "USD"}</a>
                                                <div class={`dropdown-menu ${CalculatorEth? "show":""} `} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
                                                
                                                    <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={usdEthgetvalue} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticinput} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusdinput} class='linkactive'>USD</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                            <div class="token-received">
                                                <div class="token-eq-sign">=</div>
                                                <div class="token-received-amount">
                                                    <input id="token-base-amount" placeholder='MambaVerse' type="text" name="token-base-amount" value={inputvaluetoken} class="input-bordered input-with-hint" onChange={maticinputvalue ? maticinput : usdinput} />
                                                    {/* <div class="token-symbol">MambaVerse</div> */}
                                                </div>
                                            </div> </> : <><div class="token-pay-amount">
                                                <input id="token-base-amount1212" class="input-bordered" type="text" value={inputvalue} onChange={usdAmount} />
                                                <div class={`token-pay-currency ${CalculatorEth? "show":""}`} style={{ top: "9px" }}>
                                                {/* <a class={`nav-link dropdown-toggle ${CalculatorEth? "show":""}`} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{padding:'0px'}} onClick={() => setCalculatorEth(!CalculatorEth)}>{maticinputvalue ? "MATIC" : "USD"}</a> */}
                                                <a class={`nav-link dropdown-toggle `} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{padding:'0px',color:'white'}} >ETH</a>
                                                <div class={`dropdown-menu ${CalculatorEth ? "show":""}`} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
                                                        <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                            <li><a onClick={usdEthgetvalue} class='linkactive' >ETH</a></li>
                                                            <li><a onClick={getmaticinput} class='linkactive'>MATIC</a></li>
                                                            <li><a onClick={getusdinput} class='linkactive'>USD</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="token-received">
                                                <div class="token-eq-sign">=</div>
                                                <div class="token-received-amount">
                                                    <input id="token-base-amount" placeholder='MambaVerse' type="text" name="token-base-amount" value={inputvaluetoken} class="input-bordered input-with-hint" onChange={usdToken} />
                                                    {/* <div class="token-symbol">MambaVerse</div> */}
                                                </div>
                                            </div> </>}
                                    </div>
                                    <div class="token-calc-note note note-plane">
                                        <em class="fas fa-info-circle text-light"></em>
                                        <span class="note-text text-light">Amount calculated based current tokens price</span>
                                    </div>
                                    <div class="token-buy">
                                        <a onClick={BuyToken} class="btn btn-primary">Buy Tokens</a>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-xl-8 col-lg-7">
                                <div class="token-sale-graph card card-full-height" >
                                    <div class="card-innr">
                                        <div class="card-head has-aside">
                                            <h4 class="card-title">MAMBA Sales</h4>
                                            <div class="card-opt">
                                                <a href="#" class="link ucap link-light toggle-tigger toggle-caret">7 Days</a>
                                                <div class="toggle-class dropdown-content">
                                                    <ul class="dropdown-list">
                                                        <li><a href="#">30 days</a></li>
                                                        <li><a href="#">1 years</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chart-tokensale">

                                            <Amchart />
                                        </div>
                                        {/*  <Amchart /> */}
                                    </div>
                                </div>

                            </div>

                            <div class="col-xl-4 col-lg-5">
                                <div class="token-sales card card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <h4 class="card-title">MAMBA  Pre-Sale</h4>
                                        </div>
                                        <ul class="progress-info">
                                            <li className='abhimanyu'><span className='abhimanyu'>Sold</span> 2,758 MAMBA</li>
                                            <li class="text-right abhimanyu"><span className='abhimanyu'>TOTAL</span> 1,500,000 MAMBA</li>
                                        </ul>
                                        <div class="progress-bar">
                                            <div class="progress-hcap" data-percent="83" style={{ width: "83%" }}><div className='abhimanyu'>Hard cap <span>1,400,000</span></div>
                                            </div>
                                            <div class="progress-scap abhimanyu" data-percent="24" style={{ width: "83%" }}><div>Soft cap <span>40,000</span></div>
                                            </div>

                                            <div class="progress-percent" data-percent="28" style={{ width: "83%" }}></div>
                                        </div>
                                        <span class="card-sub-title mgb-0-5x abhimanyu">Sales END IN</span>
                                        <Countdown date={new Date(tmecounter)} renderer={renderer} />

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                {/*   <div className='gl mb-3' id="glow">

<h2>Glowing border</h2>
  <p>bdhsbfhasbdfhbfhsadbfhk</p>
</div> */}

                <SocailMedia />
                <Footer />



            </body>
        </div>
    )
}

export default Index
