import React, { useState } from 'react';
import './index.css'
import Chart from 'react-apexcharts'
// import { useState } from 'react';

function Amhart() {
    const [option, setOption] = useState({
        labels: ["Tech Development 35%", "Marketing 25%", "Legal 20%", "Business Development 10%", "Operations 10%"],
        colors: ['#e224a2', '#6f388c', '#7e50d2', '#36429b', '#3f65bc'],
        legend: {
            show: true,
            // floating: true,
            fontSize: '18px',
            // position: 'left',
            // offsetX: -20,
            // offsetY: 0,
            // borderColor: "#e224a2",
            labels: {
                colors: '#fff'
            },

        },

        chart: {
            type: 'donut',
            foreColor: '#373d3f'
        },
        stroke: {
            show: false,
            width: 0
        },

        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: "100%",
                    height: "500",
                    borderColor: "#535A6C",
                },
                legend: {
                    position: 'bottom',
                }
            }
        }],

    })
    const [series, setSeries] = useState([35, 25, 20, 10, 10])

    // var chart = new ApexCharts(document.querySelector("#chart"), options);
    // chart.render();
    return <div>
        <Chart options={option}
            series={series}
            type="donut"
            height="700px"
            width="100%"
        />
    </div>;
}

export default Amhart;
