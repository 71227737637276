import React, { useState, useEffect, useContext } from 'react'
import Header from '../header/index'
import Footer from '../Footer/index'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import imgA from '../../images/pay-a.png'
import imgB from '../../images/pay-b.png'
import imgC from '../../images/pay-c.png'
import metamask from '../../images/metamask.svg'
import Token from '../token/index'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../injected"
import Transcation from '../hooks/transaction'
import NumberFormat from 'react-number-format'

import Web3 from 'web3'
import { useNavigate, useLocation } from "react-router-dom"
import { StoreContext } from '../../useContext/walletContext'
import { StoreContext1 } from '../../useContext/pathContext'
import { ethers } from "ethers";
import ErrorMessage from "../hooks/error";
import TxList from "../hooks/txList";
import { Button, Modal } from 'react-bootstrap'
import { BASE_URL } from '../../useContext/walletContext'
import polygon from '../../images/polygon.png'

import './buytoken.css'
import useMetaMask from '../../metamask';
// import { useEffect } from 'react'
import $ from 'jquery'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Paypal from '../paymentwithpaypal/paypal'
import Plaid from '../plaidtry/index'
import ACH from '../../images/ach.png'
import SocailMedia from '../Socialbar/index'
import StripeCheckout from 'react-stripe-checkout';
/* import {Helmet} from "react-helmet"; */


function Index(props) {
    const history = useNavigate()
    const location = useLocation()
    console.log("location", location)
    console.log("props", props)
    const { chainId, connect, disconnect, isActive, account } = useMetaMask()
    const context = useContext(StoreContext)
    const contextpath = useContext(StoreContext1)
    console.log('contextpath', contextpath.path.kycform)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showmanual, setShowmanual] = useState(false);

    const handleClosemanual = () => setShowmanual(false);
    const handleShowmanual = () => setShowmanual(true);
    const [balance, setBalance] = useState()
    const [getData, setGetdata] = useState()
    const [GetEthtousd, setGetEthtousd] = useState()
    const [inputAmount, setInputAmount] = useState(false)
    const [inputToken, setInputToken] = useState(false)
    const [inputvalue, setInputValue] = useState()
    const [inputvaluetoken, setInputValuetoken] = useState()
    const [getUSDvalue, setGetUSDvalue] = useState('')
    const [getMATIC, setGetMATIC] = useState(false)
    const [getETH, setGetETH] = useState(false)
    const [getUSD, setGetUSD] = useState(false)
    const [eth, setEth] = useState(false)
    const [maticinputvalue, setmaticinputvalue] = useState(false)
    const [getMATICUSDvalue, setGetMATICUSDvalue] = useState('')
    const [checkedeth, setcheckedeth] = useState(true)
    const [checkedematic, setcheckedematic] = useState(false)
    const [checkedusd, setcheckedusd] = useState(false)


    // const [walletaddresstore, setwalletaddresstore] = useState(account)
    const [error, setError] = useState();
    const [txs, setTxs] = useState([]);
    const [chainname, setchainname] = useState(chainId)
    const [chainnetwork, setChaninNetwork] = useState()
    const [disabled, setDisabled] = useState(false)
    const [checked, setChecked] = useState(false)
    const [checkedmanual, setCheckedmanual] = useState(false)
    const [orderID, setOrderID] = useState('')
    /* *********manual payment state************ */
    const [tsnxmanual, setTsnxmanual] = useState('')
    const [sendermanual, setSendermanual] = useState('')
    const [usertoken, setUsertoken] = useState('')
    /* ********* ENdmanual payment state************ */
    const [walletAddress, setWalletAddress] = useState('')
    const [newpassicon, setNewpassicon] = useState(false)
    const [getwalletAddress, setGetwalletAddress] = useState('')
    const [checkout, setCheckout] = useState(false)
    const [bankpayment, setBankPayment] = useState(false)
    const [status, setStatus] = useState('')
    const [stripe, setStripe] = useState()




    useEffect(() => {
        console.log(chainId)
        if (contextpath.path.currency === 'matic') {
            getmaticinput()
            console.log('matic1', contextpath.path)
        } else if (contextpath.path.currency === 'usd') {
            getusdinput()
            console.log('usd1', contextpath.path)
        } else {
            usdEthgetvalue()
            console.log('eth3', contextpath.path)

        }

    }, [])

    const getdatasuccess = (data) => {
        setGetdata(data)
        console.log("getData", getData)
    }
    console.log("getData", getData)

    function Transactionsme() {
        history('/Transactions')
        window.location.reload()
    }
    const getusdbalnce = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((response) => {
            setGetEthtousd(response.data.USD)

        }).catch((error) => {

        })
    }
    const getnble = async () => {
        // try {
        //     if (!window.ethereum)
        //         // throw new Error("No crypto wallet found. Please install it.");
        //         alert('No crypto wallet found. Please install it.')

        //     await window.ethereum.send("eth_requestAccounts");
        // } catch (err) {
        //     // setError(err.message);
        // 
        // }
        window.web3 = new Web3(window.ethereum)
        const account = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })

    }
    /* ******************ETH************************* */
    const usdToken = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue((e.target.value / 20000) / getUSDvalue)


    }
    const usdAmount = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getUSDvalue * 20000)
    }
    /* **************************END ETH******************* */
    /* ***************************MATIC ******************* */
    const maticinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue((e.target.value / 20000) / getMATICUSDvalue)
    }
    const mambamaticinput = (e) => {

        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getMATICUSDvalue * 20000)
    }
    /* *********************END MATIC***************************** */
    /* **********************USD ********************************* */
    const usdinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue(e.target.value / 20000)
    }
    const mambusdnput = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * 20000)
    }

    /* ***********************END USD*****************************/

    const usdEthgetvalue = (e) => {

        setcheckedeth(true)
        setEth(false)
        setInputValuetoken(0)
        setInputValue(0)
        setcheckedusd(false)

    }
    const getmaticinput = (e) => {
        setEth(true)
        setmaticinputvalue(true)

        getmaticusdpriceforinput()
        setInputValuetoken(0)
        setInputValue(0)
        setcheckedematic(true)
        setcheckedusd(false)

    }
    const getusdinput = (e) => {
        setEth(true)
        setmaticinputvalue(false)
        setInputValuetoken(0)
        setInputValue(0)
        setcheckedusd(true)
    }
    useEffect(() => {

        setInputValue(contextpath.path.calculatorInput)
        setInputValuetoken(contextpath.path.calculatorOutput)

        getethinput()
        getmaticusdpriceforinput()
        getusertoken()
        getwalletAddressforuser()
        getuserstatus()
    }, [])

    const getethinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {

            setGetUSDvalue(res?.data?.USD)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const getmaticusdpriceforinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD').then((res) => {

            setGetMATICUSDvalue(res?.data?.USD)

        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    useEffect(() => {
        isactiveBalance()
        context.setHeaderTitle({ walletbalnce: inputvalue })
        context.setHeaderTitle({ wallettoken: inputvaluetoken })
    }, [connect])
    const isactiveBalance = () => {
        isActive ? getBalanceUsingWeb3(account) : console.log("notactive")
        isActive ? chnainname() : console.log("notactive")
    }
    /* ****************************get Chain Networkname******************** */
    const chnainname = () => {
        if (chainId == 3) {
            setChaninNetwork('ETH')
        } else if (chainId == 80001) {
            setChaninNetwork('MATIC')
        } else if (chainId == 137) {
            setChaninNetwork('MATIC MAINNET')
        } else if (chainId == 1)
            setChaninNetwork('ETH MAINNET')
        else {
            setChaninNetwork('defulte Network')
        }
    }

    /* ****************************END Chain Networkname******************** */
    /* ********************************get Balance metamask******************** */
    async function getBalanceUsingWeb3(account) {
        var web3 = window.web3;
        try {
            if (typeof web3 !== 'undefined') {
                web3 = new Web3(web3.currentProvider);
                web3.eth.getBalance(account).then((balance) => {
                    // alert("Balance: " + web3.utils.fromWei(balance, 'ether'));
                    setBalance(+ web3.utils.fromWei(balance, 'ether'))

                });
            }
        } catch (error) {
            console.log('Error on connecting: ', error)
        }
    }
    /* ************************************** ENd Balance Metamask ************************ */
    /* ************************Payment Function***************************** */
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const data = new FormData(e.target);
        if (account == null) {
            NotificationManager.error("Please Connected the Metamask")
        } else {
            setError();
            await startPayment({
                setError,
                setTxs,
                ether: inputvalue,
                addr: "0x9a418F346B26df2FEA522E955F641F3Cc70456d9"
            });
            getdatasuccess(txs)
        }
    };

    const startPayment = async ({ setError, setTxs, ether, addr }) => {
        try {
            if (!window.ethereum)
                throw new Error("No crypto wallet found. Please install it.");

            await window.ethereum.send("eth_requestAccounts");
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            ethers.utils.getAddress(addr);
            const tx = await signer.sendTransaction({
                to: addr,
                value: ethers.utils.parseEther(ether)
            });
            setTxs([tx]);
            transcationdetailssave(tx)

        } catch (err) {
            setError(err.message);
        }
    };
    /* ****************************END Payment Method************************ */

    /* ****************************post transcation details******************* */
    const transcationdetailssave = async (tx) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            mambaToken: inputvaluetoken,
            ethAmount: chainnetwork == 'ETH' || chainnetwork == 'ETH MAINNET' ? inputvalue : "",
            maticAmount: chainnetwork == 'MATIC' || chainnetwork == 'MATIC MAINNET' ? inputvalue : "",
            usdAmount: "",
            transactionHash: tx.hash,
            usdCrrentAmount: getUSDvalue,
            usdMaticCrrentAmount: getMATICUSDvalue,
            senderID: account
        }
        axios.post(`${BASE_URL}/api/transaction-counter`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {

            setOrderID(res.data.result.orderID)

            window.$('#pay-online').modal('hide');

            window.$('#pay-online').on('hidden.bs.modal', function () {
                window.$(".modal-backdrop").remove();
            })
            setTimeout(function () { handleShow() }, 1000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }
    /* **************************** End post transcation details******************* */

    /* **************************** post Manualtranscation details******************* */
    const transcationdetailssaveManual = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')


        const data = {
            mambaToken: inputvaluetoken,
            ethAmount: eth ? "" : inputvalue,
            maticAmount: maticinputvalue ? inputvalue : "",
            usdAmount: "",
            transactionHash: "",
            usdCrrentAmount: getUSDvalue,
            usdMaticCrrentAmount: getMATICUSDvalue,
            senderID: "",
            transactiontype: 'Manual'
        }
        axios.post(`${BASE_URL}/api/transaction-counter`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {

            setOrderID(res.data.result.orderID)

            window.$('#get-pay-address').modal('hide');
            // window.$('#get-pay-address').modal({ backdrop: 'none', keyboard: false })
            // window.$('.get-pay-address').remove();
            window.$('#get-pay-address').on('hidden.bs.modal', function () {
                window.$(".modal-backdrop").remove();
            })
            setTimeout(function () { handleShowmanual() }, 1000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }

    /* ****************************End post Manualtranscation details******************* */

    /* ****************************put Manualtranscation details update******************* */
    const transcationdetailsManualupdate = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            transactionHash: tsnxmanual,
            senderID: sendermanual
        }
        axios.put(`${BASE_URL}/api/transactionmaunalupdate/${orderID}`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {


            setTimeout(function () { handleShow() }, 1000);

        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }
    /* **************************** ENd put Manualtranscation details update******************* */


    /* **********************************get user token,eth,matic ,and usd **************** */
    const getusertoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/usertoken`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {

            setUsertoken(res?.data.result[0])


        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    /* **********************************END user token,eth,matic ,and usd **************** */

    /* ********************************** Wallet Address **************** */
    const walletAddressAdd = async (e) => {
        e.preventDefault()
        const sessiontoken = await window.sessionStorage.getItem('Token')
        const data = {
            WalletAddress: walletAddress
        }
        axios.put(`${BASE_URL}/api/WalletAddress`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {

            NotificationManager.success("Add your Wallet Address")
            window.sessionStorage.setItem("WalletAddress", res?.data?.result?.WalletAddress ?? "")
            setNewpassicon(true)
            setTimeout(function () { setNewpassicon(false) }, 2000);
            window.location.reload()
        }).catch((error) => {
            console.log(error?.response?.data?.message)

        })
    }

    const getwalletAddressforuser = async () => {
        const sessionrole = await window.sessionStorage.getItem('WalletAddress')
        setWalletAddress(sessionrole)

    }
    /* ********************************** End Wallet Address **************** */


    /****************************KYC and Email Verification status get************************** */
    const getuserstatus = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/statusdetails`, {
            headers: {
                "userid": sessiontoken,

            }
        }).then((res) => {

            setStatus(res.data.result[0])

        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    /***************************end KYC and Email Verification status get*********************** */

    /* ***************************Stripe payment******************************** */
    const MakeStripePayments = (token) => {

        const data = {
            product: inputvalue,
            token: token
        }
        axios.post(`${BASE_URL}/api/Stripe`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((result) => {
            transcationdetailStripe(result.data.result)
            setStripe(result)
        }).catch((error) => {
            console.log("error", error)


        })

    }


    const transcationdetailStripe = async (result) => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            mambaToken: inputvaluetoken,
            ethAmount: "",
            maticAmount: "",
            usdAmount: (result.amount) / 100,
            transactionHash: result.balance_transaction,
            usdCrrentAmount: getUSDvalue,
            usdMaticCrrentAmount: getMATICUSDvalue,
            senderID: result.receipt_email
        }
        axios.post(`${BASE_URL}/api/transaction-counter`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {

            setOrderID(res.data.result.orderID)

            window.$('#pay-online').modal('hide');

            window.$('#pay-online').on('hidden.bs.modal', function () {
                window.$(".modal-backdrop").remove();
            })
            setTimeout(function () { handleShow() }, 1000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)

        })
    }
    /* ************************End Stripe Payment******************************** */
    return (
        <div>

            <Helmet>
                <title>Buy Token | MambaVerse - Bridge between physical and virtual worlds within the decentralized and open-source virtual world</title>
                <meta name="description" content="MambaVerse is a multi-faceted economy with P2P, B2C, & B2B marketplace which include collectibles, gaming items, and other virtual goods backed by blockchain technology." />
            </Helmet>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>
                    </div>
                    <Header data={contextpath.path.kycform} />

                </div>


                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="main-content col-lg-8">

                                <div class="content-area card">
                                    <div class="card-innr">
                                        <div class="card-head">
                                            <span class="card-sub-title text-primary font-mid">Step 1</span>
                                            <h4 class="card-title">Choose currency and calculate MAMBA price</h4>
                                        </div>
                                        <div class="card-text">
                                            <p>Buy MAMBA using ETH, MATIC, or USD
                                            </p>
                                        </div>
                                        <div class="token-currency-choose">
                                            <div class="row guttar-15px">
                                                <div class="col-4">
                                                    <div class="pay-option" onClick={usdEthgetvalue} >
                                                        <input class="pay-option-check" type="radio" id="payeth" name="payOption" checked={checkedeth} />
                                                        <label class="pay-option-label" for="payeth">
                                                            <span class="pay-title"><em class="pay-icon cf cf-eth"></em><span class="pay-cur">ETH</span></span>
                                                            {/* <span class="pay-amount">0.000800</span> */}</label>
                                                    </div>
                                                </div>

                                                <div class="col-4" onClick={getmaticinput}>
                                                    <div class="pay-option">
                                                        <input class="pay-option-check" type="radio" id="payusd" name="payOption" checked={checkedematic} /><label class="pay-option-label" for="payusd"><span class="pay-title"><img src={polygon} width='25' class='mr-1' /><span class="pay-cur">MATIC</span></span></label></div>
                                                </div>
                                                <div class="col-4" onClick={getusdinput}>
                                                    <div class="pay-option">
                                                        <input type='number' class="pay-option-check" type="radio" id="paybtc" name="payOption" checked={checkedusd} /><label class="pay-option-label" for="paybtc"><span class="pay-title"><em class="pay-icon fas fa-dollar-sign"></em><span class="pay-cur">USD</span></span></label></div>
                                                </div>
                                            </div></div>
                                        <div class="card-head"><span class="card-sub-title text-primary font-mid">Step 2</span><h4 class="card-title">Conversion </h4></div><div class="card-text"><p>Calculate amount of MAMBA token you will receive</p></div>
                                        <div class="token-contribute">
                                            <div class="token-calc">
                                                {eth ? <><div class='col-6 mobilecolsix'><div class="token-pay-amount" style={{ width: '100%' }}>
                                                    <span>What You Pay</span>
                                                    <input id="token-base-amount1212" class="input-bordered" type="number" value={inputvalue} onChange={maticinputvalue ? mambamaticinput : mambusdnput} />
                                                    <div class="token-pay-currency">
                                                        <a href="#" class="link ucap link-light toggle-tigger ">{maticinputvalue ? "MATIC" : "USD"}</a>
                                                        {/* <div class="toggle-class dropdown-content">
                                                            <ul class="dropdown-list toggle-tigger ">
                                                                <li><a onClick={usdEthgetvalue}>ETH</a></li>
                                                                <li><a onClick={getmaticinput}>MATIC</a></li>
                                                                <li><a onClick={getusdinput}>USD</a></li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                </div>
                                                    <div class='col-6 mobilecolsix'>
                                                        <div class="token-received" >
                                                            <div class="token-eq-sign old">=</div>
                                                            <div class="token-received-amount mobileamount" >
                                                                <span>What You Get</span>
                                                                <input id="token-base-amount" placeholder='' type="number" name="token-base-amount" value={inputvaluetoken} class="input-bordered input-with-hint" onChange={maticinputvalue ? maticinput : usdinput} />
                                                                <div class="token-pay-currency">
                                                                    <span class="link ucap link-light toggle-tigger pr-2 new">MAMBA</span>

                                                                </div>
                                                                {/* <div class="token-symbol">MambaVerse</div> */}
                                                            </div>
                                                        </div></div> </> : <> <div class='col-6 mobilecolsix' ><div class="token-pay-amount" style={{ width: '100%' }}>
                                                            <span>What You Pay</span>

                                                            <input id="token-base-amount1212" class="input-bordered" type="number" value={inputvalue} onChange={usdAmount} />

                                                            <div class="token-pay-currency">
                                                                <a href="#" class="link ucap link-light toggle-tigger">ETH</a>
                                                                {/* <div class="toggle-class dropdown-content">
                                                                    <ul class="dropdown-list toggle-tigger ">
                                                                        <li><a onClick={usdEthgetvalue}>ETH</a></li>
                                                                        <li><a onClick={getmaticinput}>MATIC</a></li>
                                                                        <li><a onClick={getusdinput}>USD</a></li>
                                                                    </ul>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    <div class='col-6 mobilecolsix'>
                                                        <div class="token-received" >
                                                            <div class="token-eq-sign ">=</div>
                                                            <div class="token-received-amount mobileamount" >
                                                                <span>What You Get</span>
                                                                <input id="token-base-amount" placeholder='' type="text" name="token-base-amount" value={inputvaluetoken} class="input-bordered input-with-hint" onChange={usdToken} />
                                                                <div class="token-pay-currency">
                                                                    <span class="link ucap link-light toggle-tigger pr-2 new">MAMBA</span>
                                                                </div>

                                                                {/* <div class="token-symbol">MambaVerse</div> */}
                                                            </div>
                                                        </div></div> </>}
                                            </div>

                                            <div class="note note-plane note-danger note-sm pdt-1x pl-0"><p>Your Contribution will be calculated based on exchange rate at the moment your transaction is confirm.</p></div></div>
                                        <div class="card-head"><span class="card-sub-title text-primary font-mid">Step 3</span><h4 class="card-title">Make A Payment</h4></div><div class="card-text"><p>To get tokens please make a payment. You can send payment directly to our address or you may pay online using Metamask or PayPal. Once you paid, you will receive an email about the successfull transaction. </p></div>

                                        <div class="pay-buttons">


                                            <div class="pay-button"><a href="#" data-toggle="modal" data-target={walletAddress === '' ? "#add-wallet" : "#pay-online"} class="btn btn-primary btn-between w-100">{checkedusd ? "Make USD Payment" : "Make Crypto Payment"} <em class="ti ti-arrow-right"></em></a></div></div>

                                        <div class="pay-notes"><div class="note note-plane note-light note-md font-italic"><em class="fas fa-info-circle"></em><p>Tokens will appear in your account after payment successfully made and approved by our team. <br class="d-none d-lg-block" /> Please note that, MambaVerse will distribute at the end of IDO Token Sales. </p></div></div></div> </div> </div><div class="aside sidebar-right col-lg-4">
                                {walletAddress === '' ? <div class="d-none d-lg-block"><a href="#" data-toggle="modal" data-target="#add-wallet" class="btn btn-primary btn-xl btn-between w-100">Add Your Wallet <em class="ti ti-arrow-right"></em></a><div class="gaps-3x"></div></div> : <div class="account-info card">
                                    <div class="card-innr">
                                        <h6 class="card-title card-title-sm">Your Account Status</h6>
                                        <ul class="btn-grp">
                                            {status.AccountVerify ? <li>
                                                <a href="#" class="btn btn-auto btn-xs btn-success">Email Verified</a></li> : <li>
                                                <a href="#" class="btn btn-auto btn-xs btn-warning">Email Pending</a></li>}
                                            {status.KycForm ? <li><a href="#" class="btn btn-auto btn-xs btn-success">KYC Verified </a></li> : <li><a href="#" class="btn btn-auto btn-xs btn-warning">KYC Pending</a></li>}
                                        </ul>
                                        <div class="gaps-2-5x"></div><h6 class="card-title card-title-sm">Receiving Wallet</h6><div class="d-flex justify-content-between"><span><span>{walletAddress?.substring(0, 4) ?? ""}...{walletAddress?.substring(walletAddress?.length - 4) ?? ""}</span> <em class="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="ERC20 Compliant Polygon Wallet"></em></span><a href="#" data-toggle="modal" data-target="#add-wallet" class="link link-ucap">Edit</a></div></div></div>}

                                <div class="token-statistics card card-token height-auto">

                                    <div class="card-innr">
                                        <div class="token-balance">
                                            <div class="token-balance-text">
                                                <h6 class="card-sub-title">Token Balance</h6><span class="lead"><NumberFormat style={{ fontSize: '1.2rem' }}
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={usertoken?.totaltoken == null ? 0 : usertoken?.totaltoken.toFixed(2) ?? 0}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />
                                                    <span>MAMBA</span></span></div></div><div class="token-balance token-balance-s2"><h6 class="card-sub-title">Your Contribution</h6><ul class="token-balance-list"><li class="token-balance-sub"><span class="lead">
                                                        <NumberFormat style={{ fontSize: '1.2rem' }}
                                                            thousandsGroupStyle="thousand"
                                                            // format="$#########0"
                                                            value={usertoken?.totaleth == null ? 0 : usertoken?.totaleth.toFixed(5) ?? 0}
                                                            prefix=""
                                                            decimalSeparator="."
                                                            displayType="text"
                                                            type="text"
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            suffix={0} /></span><span class="sub">ETH</span></li><li class="token-balance-sub"><span class="lead">
                                                                <NumberFormat style={{ fontSize: '1.2rem' }}
                                                                    thousandsGroupStyle="thousand"
                                                                    // format="$#########0"
                                                                    value={usertoken?.totalmatic == null ? 0 : usertoken?.totalmatic.toFixed(5) ?? 0}
                                                                    prefix=""
                                                                    decimalSeparator="."
                                                                    displayType="text"
                                                                    type="text"
                                                                    thousandSeparator={true}
                                                                    allowNegative={true}
                                                                    suffix={0} />
                                                            </span><span class="sub">MATIC</span></li><li class="token-balance-sub"><span class="lead">
                                                                <NumberFormat style={{ fontSize: '1.2rem' }}
                                                                    thousandsGroupStyle="thousand"
                                                                    // format="$#########0"
                                                                    value={usertoken?.totalusd ?? 0}
                                                                    prefix=""
                                                                    decimalSeparator="."
                                                                    displayType="text"
                                                                    type="text"
                                                                    thousandSeparator={true}
                                                                    allowNegative={true}
                                                                    suffix={0} /></span><span class="sub">USD</span></li></ul></div></div>
                                </div>
                                <Token />


                            </div></div></div></div>
                <SocailMedia />
                <Footer />
                {/* <div class="footer-bar">
                    <div class="container">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-md-8">
                                <ul class="footer-links">
                                    <li><a href="#">Whitepaper</a></li>
                                    <li><a href="faq-page.html">FAQs</a></li>
                                    <li><a href="regular-page.html">Privacy Policy</a></li>
                                    <li><a href="regular-page.html">Terms of Condition</a></li>
                                </ul>
                            </div>
                            <div class="col-md-4 mt-2 mt-sm-0">
                                <div class="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
                                    <div class="copyright-text">&copy; 2021 MambaVerse Pay LLC.</div>
                                    <div class="lang-switch relative">
                                        <a href="#" class="lang-switch-btn toggle-tigger">En <em class="ti ti-angle-up"></em>
                                        </a>
                                        <div class="toggle-class dropdown-content dropdown-content-up">
                                            <ul class="lang-list">
                                                <li><a href="#">Fr</a></li>
                                                <li><a href="#">Bn</a></li>
                                                <li><a href="#">Lt</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="modal fade" id="add-wallet" tabindex="-1">
                    <div class="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div class="modal-content">
                            <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <em class="ti ti-close"></em>
                            </a>
                            <div class="popup-body">
                                <h4 class="popup-title">Wallet Address</h4>
                                <p>In order to receive your <a href="#"><strong>MAMBA </strong></a> tokens, please add your Polygon wallet address. <strong>You will receive MAMBA tokens to this address after the transaction is completed.</strong>
                                </p>
                                <form onSubmit={walletAddressAdd}>
                                    <div class="row">
                                        <div class="col-md-6">
                                            {/* <div class="input-item input-with-label">
                                                <label for="swalllet" class="input-item-label">Select Wallet</label>
                                                <select class="select-bordered select-block" name="swalllet" id="swalllet" style={{ border: '1px solid #d2dde9' }}>
                                                    <option value="eth">Ethereum</option>
                                                    <option value="dac">DashCoin</option>
                                                    <option value="bic">BitCoin</option>
                                                </select>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="input-item input-with-label">
                                        <label for="token-address" class="input-item-label">Your Polygon Wallet Address for tokens:</label>
                                        {walletAddress == '' ? <input class="input-bordered" type="text" id="token-address" name="token-address" onChange={(e) => setWalletAddress(e.target.value)} /> :
                                            <input class="input-bordered" type="text" id="mobile-Wallet" name="mobile-Wallet" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />}
                                        <span class="input-note">Note: Address should be ERC20-compliant.</span>
                                    </div>
                                    {/* <div class='mb-3 text-center'>
                                        <span>OR</span>
                                    </div> */}
                                    {/* <div class="d-sm-flex justify-content-between align-items-center">
                                        {isActive ? <button class="btn btn-primary btn-start w-100" onClick={disconnect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Disconnect With MetaMask</button> : <button class="btn btn-primary btn-start w-100" onClick={connect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Connect With MetaMask</button>}
                                    </div> */}
                                    {/* {isActive ? <><div class='row'>  <div class='col-6'>
                                        <div class='mt-3'>
                                            <h6 class="card-sub-title deepak">Wallet Balance</h6>
                                            <span class='abhimanyu'>{chainnetwork} = {balance}</span>
                                        </div>
                                    </div>

                                        <div class='col-6'>
                                            <div class='mt-3'>
                                                <h6 class="card-sub-title deepak">Wallet Address</h6>
                                                <span class='abhimanyu'>{account.substring(0, 6)}...{account.substring(account.length - 6)}<span></span></span>

                                            </div>

                                        </div></div></> : null} */}

                                    <div class="note note-plane note-danger mt-3"><em class="fas fa-info-circle"></em>
                                        <p>DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb, Binance etc. You can use MetaMask, MayEtherWallet, Mist wallets etc. Do not use the address if you don’t have a private key of the your address. You WILL NOT receive MAMBA tokens and WILL LOSE YOUR FUNDS if you do.
                                        </p>
                                    </div>
                                    <div class="gaps-3x"></div>
                                    <div class="d-sm-flex justify-content-between align-items-center">
                                        <button class="btn btn-primary" >{walletAddress == '' ? "Add Wallet" : "Update Wallet"}</button>
                                        <div class="gaps-2x d-sm-none"></div>
                                        {newpassicon ? <span class="text-success"><em class="ti ti-check-box"></em> {walletAddress == '' ? "Added wallet address" : " Updated wallet address"}</span> : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="get-pay-address" tabindex="-1">
                    <div class="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div class="modal-content">
                            <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em>
                            </a>
                            <div class="popup-body">
                                <h4 class="popup-title">Copy and Paste Address Below into your Sending Wallet </h4>
                                <p>Please make deposit amount of <strong class='deepak'>{inputvalue ?? 0} {maticinputvalue ? "MATIC" : eth ? "USD" : "ETH"}</strong> to our address and receive <strong class='deepak'>{inputvaluetoken ?? 0} MAMBA</strong> tokens once we recevie payment.</p>
                                <div class="gaps-1x"></div>
                                <h6 class="font-bold">Payment to the following Address</h6>
                                <div class="copy-wrap mgb-0-5x">
                                    <span class="copy-feedback"></span><em class="fab fa-ethereum"></em>
                                    <input type="text" class="copy-address clipfontsize" value="0x9a418F346B26df2FEA522E955F641F3Cc70456d9" disabled="" />
                                    <button class="copy-trigger copy-clipboard " data-clipboard-text="0x9a418F346B26df2FEA522E955F641F3Cc70456d9">
                                        <em class="ti ti-files" style={{ cursor: 'pointer', paddingBottom: '10px' }} onClick={() => { navigator.clipboard.writeText('0x9a418F346B26df2FEA522E955F641F3Cc70456d9') }}
                                        ></em>
                                    </button>
                                </div>
                                {/* <ul class="pay-info-list row">
                                    <li class="col-sm-6"><span>SET GAS LIMIT:</span> 120 000</li>
                                    <li class="col-sm-6"><span>SET GAS PRICE:</span> 95 Gwei</li>
                                </ul> */}
                                <div class="pdb-2-5x pdt-1-5x">
                                    <input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term" onChange={(e) => { setCheckedmanual(!checkedmanual) }} />
                                    <label for="agree-term">I hereby agree to the <strong>token purchase aggrement &amp; token sale term</strong>.</label>
                                </div>
                                <button class="btn btn-primary" /* data-dismiss="modal" data-toggle="modal" data-target="#pay-confirm" */ onClick={transcationdetailssaveManual} disabled={!checkedmanual}>Buy Tokens Now  <em class="ti ti-arrow-right mgl-4-5x"></em>
                                </button>
                                <div class="gaps-3x"></div><div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>In case you send a different amount, number of MAMBAtokens will update accordingly.</p></div></div>
                        </div>
                    </div>
                </div>

                {/* *******************manual payment************************* */}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter "

                    show={showmanual} onHide={handleClosemanual}>

                    <Modal.Body>
                        {/* <div class="modal fade" id="pay-confirm" tabindex="-1">
                            <div class="modal-dialog modal-dialog-md modal-dialog-centered">
                                <div class="modal-content"> */}
                        {/* <div class="popup-body"> */}
                        <div>
                            <h4 class="popup-title">Confirm Your Payment</h4><p class="lead abhimanyu">Your Order no. <strong class='deepak'>OD{orderID}</strong> has been placed successfully. </p><p>The tokens will be send to your wallet only after your transaction gets confirmed and approved by our team.</p><p>To <strong>speed up verifcation</strong> process please enter <strong>your wallet address</strong> from where you’ll transferring your ethereum to our address.</p><div class="input-item input-with-label">
                                <label for="token-address" class="input-item-label deepak">Enter your wallet address</label>
                                <input type='text' name='sender' id='sender' class="input-bordered" onChange={(e) => setSendermanual(e.target.value)} />

                                <label for="token-address " class="input-item-label deepak  mt-2">Find your Transaction Hash on Etherscan or Polygonscan and enter Hash below
                                </label>
                                <input type='text' name='tsnx' id='tsnx' class="input-bordered" type='text' onChange={(e) => setTsnxmanual(e.target.value)} />

                            </div>
                            <ul class="d-flex flex-wrap align-items-center guttar-30px">
                                <li><a href="#" /* data-dismiss="modal" data-toggle="modal" data-target="#pay-review" */ onClick={transcationdetailsManualupdate} class="btn btn-primary">Confirm Payment</a></li><li class="pdt-1x pdb-1x"><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#pay-online"
                                    class="link link-primary">Make Online Payment</a></li></ul>
                            <div class="gaps-2x"></div><div class="gaps-1x d-none d-sm-block"></div><div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>In case you send a different amount, number of MAMBAtokens will update accordingly.</p></div>
                        </div>
                        {/* </div> */}
                        {/* </div></div></div> */}
                    </Modal.Body>

                </Modal>

                {/* ******************* ENd manual payment************************* */}


                <div class="modal fade" id="pay-online" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content pb-0"><div class="popup-body">
                    <h4 class="popup-title">Select Payment Method</h4>
                    <p class="lead">To receive <strong class='deepak'>{inputvaluetoken ?? 0} MAMBA</strong> tokens. we  {/* <strong>bonus 1,540 MambaVerse</strong> */} require payment amount of <strong class='deepak'>{inputvalue ?? 0} {maticinputvalue ? "MATIC" : eth ? "USD" : "ETH"}</strong>.</p><p> The tokens will be send to your wallet post successfull transaction.</p>
                    <h5 class="mgt-1-5x font-mid">Select payment method:</h5>
                    <ul class="pay-list guttar-20px">

                        {checkedusd ? <>

                            <li class="pay-item"><input type="radio" class="pay-check" name="pay-option" id="pay-coinpay" onChange={() => { setBankPayment(true); setCheckout(false) }} /><label class="pay-check-label" for="pay-coinpay"><img src={ACH} alt="pay-logo" /><span class='text-bold' style={{ color: "#2c80ff", position: 'relative', top: '4px', fontWeight: '600', fontSize: '17px' }}>Stripe</span></label></li>

                            <li class="pay-item" style={{ listStyle: 'none' }}><input type="radio" class="pay-check" name="pay-option" id="pay-paypal" onChange={() => { setCheckout(true); setBankPayment(false) }} /><label class="pay-check-label" for="pay-paypal"><img src={imgC} alt="pay-logo" /></label></li>

                        </> : <>{isActive ? <button class="btn btn-primary btn-start w-100" onClick={disconnect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Disconnect With MetaMask</button> : <button class="btn btn-primary btn-start w-100" onClick={connect} variant="secondary"><img src={metamask} alt="MetaMask" width="50" height="50" /> Connect With MetaMask</button>}
                            {isActive ? <>  <div class='col-6'>
                                <div class='mt-3'>
                                    <h6 class="card-sub-title deepak">Wallet Balance</h6>
                                    <span class='abhimanyu'>{chainnetwork} = {balance}</span>
                                </div>
                            </div>
                                <div class='col-6'>
                                    <div class='mt-3'>
                                        <h6 class="card-sub-title deepak">Wallet Address</h6>
                                        <span class='abhimanyu'>{account.substring(0, 6)}...{account.substring(account.length - 6)}<span></span></span>
                                    </div>
                                </div></> : null}
                        </>}

                        <Modal
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={show} onHide={handleClose}>

                            <Modal.Body>
                                <div class="popup-body text-center"><div class="gaps-2x"></div>

                                    <div class="pay-status pay-status-progress" style={{ color: "blue" }}>
                                    <em class="ti ti-alarm-clock"></em>
                                    </div><div class="gaps-2x"></div>
                                    <h3>We’re reviewing your payment.</h3>
                                    {/* <Transcation getdatasuccess={getdatasuccess} /> */}
                                    <p>We’ll review your transaction and get back to you within 6 hours. You’ll receive an email with the details of your contribution. Your Order ID is {orderID} </p>
                                    <div class="gaps-2x"></div><button class="btn btn-primary" onClick={Transactionsme} >View Transaction</button>
                                    <div class="gaps-1x"></div></div>
                            </Modal.Body>

                        </Modal>

                      
                    </ul>
                    <span class="text-light font-italic mgb-2x"><small class='mt-2'>* Payment gateway company may charge you a processing fees.</small></span>
                    {/**************************  Paypal*************************************** */}

                    {checkout ? <Paypal usd={inputvalue} mamba={inputvaluetoken} usdAmountcurrent={getUSDvalue} maticAmountcurrent={getMATICUSDvalue} /> : null}

                    {/**************************  Paypal*************************************** */}

                    {/* ******************plaid************************** */}
                    {/* {bankpayment ? <Plaid usd={inputvalue} mamba={inputvaluetoken} usdAmountcurrent={getUSDvalue} maticAmountcurrent={getMATICUSDvalue} /> : null} */}
                    {bankpayment ? <><div><StripeCheckout
                        token={MakeStripePayments}
                        stripeKey="pk_live_51K3luVFzdVEalZKfmY5FPzW8B2il2nQb2hLraQAFu1j3FDMngiCwKx2gwNvuX3nn0dAzx8jfzO8eOt9vrsNT5Pti00mECA93t1"
                        amount={inputvalue * 100}
                    >
                        <button className='btn btn-primary w-100' >Payment</button>
                    </StripeCheckout></div> </> : null}
                    {/* ******************ENd plaid************************** */}
                    {checkedusd ? null : <div class="pdb-2-5x pdt-1-5x"><input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term-3" onChange={(e) => { setChecked(!checked) }} /><label for="agree-term-3">I hereby agree to the <strong>token purchase aggrement &amp; token sale term</strong>.</label></div>}
                    <ul class="d-flex flex-wrap align-items-center guttar-30px">
                        {checkedusd ? null : <> <li>
                            <button href="#" /* data-dismiss="modal" data-toggle="modal" data-target="#pay-review" */ onClick={handleSubmit} disabled={!checked} class="btn btn-primary">Buy Tokens &amp; Process to Pay <em class="ti ti-arrow-right mgl-2x"></em></button></li>
                            {/* <li class="pdt-1x pdb-1x"><a href="#" data-dismiss="modal" data-toggle="modal" data-target={walletAddress === '' ? "#add-wallet" : "#get-pay-address"} class="link link-primary">Make Manual Payment</a></li> */}
                        </>}


                    </ul><div class="gaps-2x"></div><div class="gaps-1x d-none d-sm-block"></div><div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p class="text-light">You will automatically redirect for payment after your order placing.</p></div></div></div></div></div>
                <div class="modal fade" id="pay-coingate" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content"><div class="popup-body"><h4 class="popup-title">Buy Tokens and Payment</h4><p class="lead">To receiving <strong>18,750 MambaVerse</strong> tokens including <strong>bonus 1,540 MambaVerse</strong> require payment amount of <strong>1.0 ETH</strong>.</p><p>You can pay via online payment geteway <strong>“Coingate”</strong>. It’s safe and secure.</p><div class="pdb-2-5x pdt-1-5x"><input type="checkbox" class="input-checkbox input-checkbox-md" id="agree-term-2" /><label for="agree-term-2">I hereby agree to the <strong>token purchase aggrement &amp; token sale term</strong>.</label></div><button class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#pay-thanks">Place Order &amp; Process to Pay  <em class="ti ti-arrow-right mgl-4-5x"></em></button><div class="gaps-3x"></div><div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>You will automatically redirect to Coingate website for payment</p></div></div></div></div></div><div class="modal fade" id="pay-thanks" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content"><div class="popup-body text-center"><div class="gaps-2x"></div><div class="pay-status pay-status-success"><em class="ti ti-check"></em></div><div class="gaps-2x"></div><h3>Thanks for your contribution!</h3><p>Your payment amount <strong>1.0 ETH</strong> has been successfully received againest order no. <strong>TNX94KR8N0</strong>. We’ve added <strong>18,750 MambaVerse</strong> tokens in account.</p><div class="gaps-2x"></div><a href="ico-distribution.html" class="btn btn-primary">See Token Balance</a><div class="gaps-1x"></div></div></div></div></div>
                <div class="modal fade" id="pay-review" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content"><div class="popup-body text-center"><div class="gaps-2x"></div>

                    <div class="data-state data-state-progress"><span class="d-none">Progress</span></div><div class="gaps-2x"></div>
                    <h3>We’re reviewing your payment.</h3>
                    {/* <Transcation getdatasuccess={getdatasuccess} /> */}
                    <p>We’ll review your transaction and get back to you within 6 hours. You’ll receive an email with the details of your contribution.</p>
                    <div class="gaps-2x"></div><button class="btn btn-primary" onClick={Transactionsme} >View Transaction</button>
                    <div class="gaps-1x"></div></div></div></div></div>
                <div class="modal fade" id="pay-failed" tabindex="-1"><div class="modal-dialog modal-dialog-md modal-dialog-centered"><div class="modal-content"><div class="popup-body text-center"><div class="gaps-2x"></div><div class="pay-status pay-status-error"><em class="ti ti-alert"></em></div><div class="gaps-2x"></div><h3>Oops! Payment failed!</h3><p>Sorry, seems there is an issues occurred and we couldn’t process your payment. If you continue to have issues, please contact us with order no. <strong>OD{orderID}</strong>.</p><div class="gaps-2x"></div><a href="#" data-dismiss="modal" data-toggle="modal" data-target={walletAddress === '' ? "#add-wallet" : "#get-pay-address"} class="btn btn-primary">Try to Pay Again</a><div class="gaps-1x"></div></div></div></div></div><script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
