import React, { useState, useEffect } from 'react'
import Menu from '../menu/index'
import Header from '../header/index'
import Footer from '../Footer/index'
import { Link } from 'react-router-dom'
import img1 from '../../images/vector-licence.png'
import img2 from '../../images/vector-id-back.png'
import SocailMedia from '../Socialbar/index'
import img3 from '../../images/vector-id-front.png'

import img4 from '../../images/vector-passport.png'

import img5 from '../../images/icon-national-id-color.png'
import img6 from '../../images/icon-passport.png'

import img7 from '../../images/icon-licence.png'
import img10 from '../../images/icon-passport-color.png'
import img11 from '../../images/icon-licence-color.png'
import img9 from '../../images/icon-national-id.png'
import axios from 'axios'
import { FileUploader } from "react-drag-drop-files";
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useNavigate } from "react-router-dom"
import { BASE_URL } from '../../useContext/walletContext'
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/dark.css";

function Index() {
    const history = useNavigate()
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [emailname, setEmailname] = useState('')
    const [phonenumber, setphonenumber] = useState('')
    const [dob, setDob] = useState(new Date())
    const [telegram, setTelegram] = useState('')
    const [Address1, setAddress1] = useState('')
    const [Address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [State, setState] = useState('')
    const [Nationality, setNationality] = useState('')
    const [ZipCode, setZipCode] = useState('')
    const [document, setDocument] = useState('')
    const [wallet, setWallet] = useState('')
    const [tokenAddress, settokenAddress] = useState('')
    /* ****fistimage*************** */
    const [avater, setAvater] = useState('')
    const [passport, setpassport] = useState(false)
    /* ****secondimage*************** */
    const [avater1, setAvater1] = useState('')
    const [avater2, setAvater2] = useState('')
    const [idCard, setIdCard] = useState(false)
    /* ****thirdimage*************** */
    const [avater3, setAvater3] = useState('')
    const [driving, setDriving] = useState(false)
    /* **** end thirdimage*************** */
    /* **** saveimage*************** */
    const [uploadfile, setUploadfile] = useState('')
    const [uploadfile1, setUploadfile1] = useState('')
    const [uploadfile2, setUploadfile2] = useState('')
    const [uploadfile3, setUploadfile3] = useState('')
    const [documentType, setDocumentType] = useState('')
    /* **** end saveimage*************** */
    const [disable, setDisable] = useState(true)

    const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
    let sessionemil = window.sessionStorage.getItem('email')
    const onSubmit = async () => {
        let sessionemil = window.sessionStorage.getItem('email')
        const sessiontoken = await window.sessionStorage.getItem('Token')
        setDisable(true)
        if (passport === false && idCard === false && driving === false) {
            NotificationManager.error("Please fill the Document")
        } else {
            const formData = new FormData()
            if (passport === true) {
                formData.append('document', uploadfile)
                formData.append('documenttype', "Passport")
            } else if (idCard == true) {
                formData.append('document', uploadfile1)
                formData.append('document', uploadfile2)
                formData.append('documenttype', "IDCard")
            } else {
                formData.append('document', uploadfile3)
                formData.append('documenttype', "driving licence")

            }
            

            formData.append('firstName', firstname)
            formData.append('lastName', lastname)
            formData.append('email', sessionemil)
            formData.append('phoneNumber', phonenumber)
            formData.append('address', Address1)
            formData.append('city', city)
            formData.append('State', State)
            formData.append('DOB', dob)
            formData.append('Nationality', Nationality)
            formData.append('zipcode', ZipCode)
            formData.append('telegramUser', telegram)
            formData.append('OptionAddress', Address2)
            formData.append('tokenAddress', tokenAddress)
            formData.append('wallet', wallet)

            axios.post(`${BASE_URL}/api/KYCFormsaveandupdate`, formData, {
                headers: {
                    "userid": sessiontoken,

                }
            }).then((res) => {
                // NotificationManager.success(res?.data?.message ?? " KyC Form Successfully Submited")
                window.sessionStorage.setItem("WalletAddress", res?.data?.result?.WalletAddress ?? "")
                setDisable(false)
                history('/KycSubmission')
            }).catch((error) => {
                console.log(error?.response?.data?.message)
                setDisable(false)
                // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
                if (error?.response?.data?.message?.firstName) {
                    NotificationManager.error("Please fill the Name")
                } else if (error?.response?.data?.message?.Nationality) {
                    NotificationManager.error("Please fill the Nationality")
                } else if (error?.response?.data?.message?.zipcode) {
                    NotificationManager.error("Please fill the zipcode")

                } else if (error?.response?.data?.message?.phoneNumber) {
                    NotificationManager.error("Please fill the phoneNumber")

                } else if (error?.response?.data?.message?.city) {
                    NotificationManager.error("Please fill the city")

                } else if (error?.response?.data?.message?.State) {
                    NotificationManager.error("Please fill the State")

                } else if (error?.response?.data?.message?.address) {
                    NotificationManager.error("Please fill the address")

                } else if (error?.response?.data?.message?.DOB) {
                    NotificationManager.error("Please fill the DOB")

                } else {
                    NotificationManager.error("Something Wrong. Contact Support")
                }

            })
        }
    }

    // useEffect(() => {
    //     window.location.reload()
    // }, [])

    const onchangeavater = (file) => {
        setpassport(true)
        // const reader = new FileReader(),
        // files = e.target.files
        // setUploadfile(files[0])
        // reader.onload = function () {
        //     setAvater(reader.result)
      
        // }
       
        // reader.readAsDataURL(files[0])
        setUploadfile(file)
    }

    const onchangeavater1 = (file) => {
        setIdCard(true)
        // const reader1 = new FileReader(),
        //     files = e.target.files
        // setUploadfile1(files[0])
        // reader1.onload = function () {
        //     setAvater1(reader1.result)
       
        // }
        // reader1.readAsDataURL(files[0])
        setUploadfile1(file)

    }

    const onchangeavater2 = (file) => {
        setIdCard(true)
        // const reader = new FileReader(),
        //     files = e.target.files
        // setUploadfile2(files[0])
        // reader.onload = function () {
        //     setAvater2(reader.result)
        // }
        // reader.readAsDataURL(files[0])
        setUploadfile2(file)
    }

    const onchangeavater3 = (file) => {
        setDriving(true)
        // const reader = new FileReader(),
        //     files = e.target.files
        // setUploadfile3(files[0])
        // reader.onload = function () {
        //     setAvater3(reader.result)
        // }
        // reader.readAsDataURL(files[0])
        setUploadfile3(file)
    }

    const resetImgepassport = () => {
        setUploadfile('')
    }
    const resetImageIDCard = () => {
        setUploadfile1('')
        setUploadfile2('')
    }
    const resetImageDriving = () => {
        setUploadfile3('')
    }

    
    return (
        <div>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>
                    </div>
                    <Header />

                </div>
                <SocailMedia />

                <div class="page-header page-header-kyc">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 col-xl-7 text-center">
                                <h2 class="page-title">Begin your ID-Verification</h2><p class="large">Verify your identity to participate in token sale</p></div></div></div></div>
                <div class="page-content"><div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-9">
                            <div class="kyc-form-steps card mx-lg-4">
                                <div class="form-step form-step1">
                                    <div class="form-step-head card-innr">
                                        <div class="step-head">
                                            <div class="step-number">01</div><div class="step-head-text"><h4>Required Information </h4><p>Personal information required</p></div></div></div>
                                    <div class="form-step-fields card-innr">
                                        <div class="note note-plane note-light-alt note-md pdb-1x"><em class="fas fa-info-circle"></em><p className='abhimanyu'>Please type carefully and fill out the form with your personal details. Your can’t edit these details once you submitted the form.</p></div>
                                        <div class="row"><div class="col-md-6"><div class="input-item input-with-label">
                                            <label class="input-item-label">First Name <span class="text-danger">*</span></label>
                                            <input class="input-bordered" type="text" onChange={(e) => { setFirstname(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Last Name <span class="text-danger">*</span></label><input class="input-bordered" type="text" onChange={(e) => { setLastname(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Email Address <span class="text-danger">*</span></label><input class="input-bordered" id='sessionid' name='sessionemail' type="text" value={sessionemil} onChange={(e) => { setEmailname(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Phone Number <span class="text-danger">*</span></label><input class="input-bordered" type="text" onChange={(e) => { setphonenumber(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Date of Birth <span class="text-danger">*</span></label>
                                                <Flatpickr className='form-control' dateFormat="MM/dd/yyyy"
                                                    value={dob}
                                                    options={{ altFormat: 'm/d/y' }}
                                                    onChange={(date, dateStr) => setDob(dateStr)}
                                                    id='default-picker' style={{ background: '#3b3b3b', color: 'rgb(235 235 235 / 70%)' }} />
                                            </div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Telegram Username</label><input class="input-bordered" type="text" onChange={(e) => { setTelegram(e.target.value) }} /></div></div>

                                        </div>
                                        <h4 class="mgt-0-5x deepak">Your Address</h4>
                                        <div class="row">
                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Address Line 1 <span class="text-danger">*</span></label><input class="input-bordered" type="text" onChange={(e) => { setAddress1(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Address Line 2</label><input class="input-bordered" type="text" onChange={(e) => { setAddress2(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">City <span class="text-danger">*</span></label>
                                                <input class="input-bordered" type="text" onChange={(e) => { setCity(e.target.value) }}
                                                />
                                            </div></div>
                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">State <span class="text-danger">*</span></label>
                                                <input class="input-bordered" type="text" onChange={(e) => { setState(e.target.value) }} /></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label for="nationality" class="input-item-label">Nationality <span class="text-danger">*</span></label>
                                                <select class="input-bordered" name="nationality" id="nationality" onChange={(e) => { setNationality(e.target.value) }}>
                                                    {/* *************statename******************** */}
                                                    <option value='' selected disabled hidden>Select Country</option>
                                                    <option>UNITED STATES</option>
                                                    <option value="AFGHANISTAN">AFGHANISTAN</option>
                                                    <option value="ÅLAND ISLANDS">ÅLAND ISLANDS</option>
                                                    <option value="ALBANIA">ALBANIA</option>
                                                    <option value="ALGERIA">ALGERIA</option>
                                                    <option value="AMERICAN">AMERICAN SAMOA</option>
                                                    <option value="ANDORRA">ANDORRA</option>
                                                    <option value="ANGOLA">ANGOLA</option>
                                                    <option value="ANGUILLA">ANGUILLA</option>
                                                    <option value="ANTARCTICA">ANTARCTICA</option>
                                                    <option value="ANTIGUA AND BARBUDA">ANTIGUA AND BARBUDA</option>
                                                    <option value="ARGENTINA">ARGENTINA</option>
                                                    <option value="ARMENIA">ARMENIA</option>
                                                    <option value="ARUBA">ARUBA</option>
                                                    <option value="AUSTRALIA">AUSTRALIA</option>
                                                    <option value="AUSTRIA">AUSTRIA</option>
                                                    <option value="AZERBAIJAN">AZERBAIJAN</option>
                                                    <option value="BAHAMAS">BAHAMAS</option>
                                                    <option value="BAHRAIN">BAHRAIN</option>
                                                    <option value="BANGLADESH">BANGLADESH</option>
                                                    <option value="BARBADOS">BARBADOS</option>
                                                    <option value="BELARUS">BELARUS</option>
                                                    <option value="BELGIUM">BELGIUM</option>
                                                    <option value="BELIZE">BELIZE</option>
                                                    <option value="BENIN">BENIN</option>
                                                    <option value="BERMUDA">BERMUDA</option>
                                                    <option value="BHUTAN">BHUTAN</option>
                                                    <option value="BOLIVIA">BOLIVIA</option>
                                                    <option value="BONAIRE, SINT EUSTATIUS AND SABA">BONAIRE, SINT EUSTATIUS AND SABA</option>
                                                    <option value="BOSNIA AND HERZEGOVINA">BOSNIA AND HERZEGOVINA</option>
                                                    <option value="BOTSWANA">BOTSWANA</option>
                                                    <option value="BOUVET ISLAND">BOUVET ISLAND</option>
                                                    <option value="BRAZIL">BRAZIL</option>
                                                    <option value="BRITISH INDIAN OCEAN TERRITORY">BRITISH INDIAN OCEAN TERRITORY</option>
                                                    <option value="BRITISH VIRGIN ISLANDS">BRITISH VIRGIN ISLANDS</option>
                                                    <option value="BRUNEI">BRUNEI</option>
                                                    <option value="BULGARIA">BULGARIA</option>
                                                    <option value="BURKINA">BURKINA FASO</option>
                                                    <option value="BURMA">BURMA</option>
                                                    <option value="BURUNDI">BURUNDI</option>
                                                    <option value="CAMBODIA">CAMBODIA</option>
                                                    <option value="CAMEROON">CAMEROON</option>
                                                    <option value="CANADA">CANADA</option>
                                                    <option value="CAPE VERDE">CAPE VERDE</option>
                                                    <option value="CAYMAN ISLANDS">CAYMAN ISLANDS</option>
                                                    <option value="CENTRAL AFRICAN REPUBLIC">CENTRAL AFRICAN REPUBLIC</option>
                                                    <option value="CHAD">CHAD</option>
                                                    <option value="CHILE">CHILE</option>
                                                    <option value="CHINA">CHINA</option>
                                                    <option value="CHRISTMAS ISLAND">CHRISTMAS ISLAND</option>
                                                    <option value="COCOS (KEELING) ISLANDS">COCOS (KEELING) ISLANDS</option>
                                                    <option value="COLOMBIA">COLOMBIA</option>
                                                    <option value="COMOROS">COMOROS</option>
                                                    <option value="CONGO">CONGO</option>
                                                    <option value="CONGO, THE DEMOCRATIC REPUBLIC OF THE">CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                                                    <option value="COOK ISLANDS">COOK ISLANDS</option>
                                                    <option value="COSTA RICA">COSTA RICA</option>
                                                    <option value="COTE D'IVOIRE">COTE D'IVOIRE</option>
                                                    <option value="CROATIA">CROATIA</option>
                                                    <option value="CUBA">CUBA</option>
                                                    <option value="CURACAO">CURACAO</option>
                                                    <option value="CYPRUS">CYPRUS</option>
                                                    <option value="CZECH REPUBLIC">CZECH REPUBLIC</option>
                                                    <option value="DENMARK">DENMARK</option>
                                                    <option value="DJIBOUTI">DJIBOUTI</option>
                                                    <option value="DOMINICA">DOMINICA</option>
                                                    <option value="DOMINICAN">DOMINICAN REPUBLIC</option>
                                                    <option value="ECUADOR">ECUADOR</option>
                                                    <option value="EGYPT">EGYPT</option>
                                                    <option value="EL SALVADOR">EL SALVADOR</option>
                                                    <option value="EQUATORIAL GUINEA">EQUATORIAL GUINEA</option>
                                                    <option value="ERITREA">ERITREA</option>
                                                    <option value="ESTONIA">ESTONIA</option>
                                                    <option value="ETHIOPIA">ETHIOPIA</option>
                                                    <option value="FALKLAND ISLANDS (MALVINAS)">FALKLAND ISLANDS (MALVINAS)</option>
                                                    <option value="FAROE ISLANDS">FAROE ISLANDS</option>
                                                    <option value="FIJI">FIJI</option>
                                                    <option value="FINLAND">FINLAND</option>
                                                    <option value="sdi_8522d3d739de43FRANCEad92b813daec02cee7">FRANCE</option>
                                                    <option value="FRENCH">FRENCH GUIANA</option>
                                                    <option value="FRENCH POLYNESIA">FRENCH POLYNESIA</option>
                                                    <option value="FRENCH SOUTHERN TERRITORIES">FRENCH SOUTHERN TERRITORIES</option>
                                                    <option value="GABON">GABON</option>
                                                    <option value="GAMBIA">GAMBIA</option>
                                                    <option value="sdi_90443d65ceb84bf3b61b95e600a5746f">GEORGIA</option>
                                                    <option value="GERMANY">GERMANY</option>
                                                    <option value="GHANA">GHANA</option>
                                                    <option value="GIBRALTAR">GIBRALTAR</option>
                                                    <option value="GREECE">GREECE</option>
                                                    <option value="GREENLAND">GREENLAND</option>
                                                    <option value="GRENADA">GRENADA</option>
                                                    <option value="GUADELOUPE">GUADELOUPE</option>
                                                    <option value="GUAM">GUAM</option>
                                                    <option value="GUATEMALA">GUATEMALA</option>
                                                    <option value="GUERNSEY">GUERNSEY</option>
                                                    <option value="GUINEA">GUINEA</option>
                                                    <option value="GUINEA BISSAU">GUINEA BISSAU</option>
                                                    <option value="GUYANA ">GUYANA</option>
                                                    <option value="HAITI">HAITI</option>
                                                    <option value="HEARD ISLAND AND MCDONALD ISLANDS">HEARD ISLAND AND MCDONALD ISLANDS</option>
                                                    <option value="HOLY SEE">HOLY SEE</option>
                                                    <option value="HONDURAS">HONDURAS</option>
                                                    <option value="HONG KONG">HONG KONG</option>
                                                    <option value="HUNGARY">HUNGARY</option>
                                                    <option value="ICELAND">ICELAND</option>
                                                    <option value="INDIA">INDIA</option>
                                                    <option value="INDONESIA">INDONESIA</option>
                                                    <option value="IRAN">IRAN</option>
                                                    <option value="IRAQ">IRAQ</option>
                                                    <option value="IRELAND">IRELAND</option>
                                                    <option value="ISLE OF MAN">ISLE OF MAN</option>
                                                    <option value="ISRAEL">ISRAEL</option>
                                                    <option value="ITALY">ITALY</option>
                                                    <option value="JAMAICA">JAMAICA</option>
                                                    <option value="JAPAN">JAPAN</option>
                                                    <option value="JERSEY">JERSEY</option>
                                                    <option value="JORDAN">JORDAN</option>
                                                    <option value="KAZAKHSTAN">KAZAKHSTAN</option>
                                                    <option value="KENYA">KENYA</option>
                                                    <option value="KIRIBATI">KIRIBATI</option>
                                                    <option value="KOSOVO">KOSOVO</option>
                                                    <option value="KUWAIT">KUWAIT</option>
                                                    <option value="KYRGYZSTAN">KYRGYZSTAN</option>
                                                    <option value="LAOS">LAOS</option>
                                                    <option value="LATVIA">LATVIA</option>
                                                    <option value="LEBANON">LEBANON</option>
                                                    <option value="LESOTHO">LESOTHO</option>
                                                    <option value="LIBERIA">LIBERIA</option>
                                                    <option value="LIBYA">LIBYA</option>
                                                    <option value="LIECHTENSTEIN">LIECHTENSTEIN</option>
                                                    <option value="LITHUANIA">LITHUANIA</option>
                                                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                                                    <option value="MACAU">MACAU</option>
                                                    <option value="MACEDONIA">MACEDONIA</option>
                                                    <option value="MADAGASCAR">MADAGASCAR</option>
                                                    <option value="MALAWI">MALAWI</option>
                                                    <option value="MALAYSIA">MALAYSIA</option>
                                                    <option value="MALDIVES">MALDIVES</option>
                                                    <option value="MALI">MALI</option>
                                                    <option value="MALTA">MALTA</option>
                                                    <option value="MARSHALL ISLANDS">MARSHALL ISLANDS</option>
                                                    <option value="MARTINIQUE">MARTINIQUE</option>
                                                    <option value="MAURITANIA">MAURITANIA</option>
                                                    <option value="MAURITIUS">MAURITIUS</option>
                                                    <option value="MAYOTTE">MAYOTTE</option>
                                                    <option value="MEXICO">MEXICO</option>
                                                    <option value="MICRONESIA">MICRONESIA</option>
                                                    <option value="MOLDOVA">MOLDOVA</option>
                                                    <option value="MONACO">MONACO</option>
                                                    <option value="MONGOLIA">MONGOLIA</option>
                                                    <option value="MONTENEGRO">MONTENEGRO</option>
                                                    <option value="MONTSERRAT">MONTSERRAT</option>
                                                    <option value="MOROCCO">MOROCCO</option>
                                                    <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                                                    <option value="NAMIBIA">NAMIBIA</option>
                                                    <option value="NAURU">NAURU</option>
                                                    <option value="NEPAL">NEPAL</option>
                                                    <option value="NETHERLANDS">NETHERLANDS</option>
                                                    <option value="NEW CALEDONIA">NEW CALEDONIA</option>
                                                    <option value="NEW ZEALAND">NEW ZEALAND</option>
                                                    <option value="NICARAGUA">NICARAGUA</option>
                                                    <option value="NIGER">NIGER</option>
                                                    <option value="NIGERIA">NIGERIA</option>
                                                    <option value="NIUE">NIUE</option>
                                                    <option value="NORFOLK ISLAND">NORFOLK ISLAND</option>
                                                    <option value="NORTH KOREA">NORTH KOREA</option>
                                                    <option value="NORTHERN MARIANA ISLANDS">NORTHERN MARIANA ISLANDS</option>
                                                    <option value="NORWAY">NORWAY</option>
                                                    <option value="OMAN">OMAN</option>
                                                    <option value="PAKISTAN">PAKISTAN</option>
                                                    <option value="PALAU">PALAU</option>
                                                    <option value="PALESTINE">PALESTINE</option>
                                                    <option value="PANAMA">PANAMA</option>
                                                    <option value="PAPUA NEW GUINEA">PAPUA NEW GUINEA</option>
                                                    <option value="PARAGUAY">PARAGUAY</option>
                                                    <option value="PERU">PERU</option>
                                                    <option value="PHILIPPINES">PHILIPPINES</option>
                                                    <option value="PITCAIRN">PITCAIRN</option>
                                                    <option value="POLAND">POLAND</option>
                                                    <option value="PORTUGAL">PORTUGAL</option>
                                                    <option value="PUERTO RICO">PUERTO RICO</option>
                                                    <option value="QATAR">QATAR</option>
                                                    <option value="RÉUNION">RÉUNION</option>
                                                    <option value="ROMANIA">ROMANIA</option>
                                                    <option value="RUSSIA">RUSSIA</option>
                                                    <option value="RWANDA">RWANDA</option>
                                                    <option value="SAINT BARTHÉLEMY">SAINT BARTHÉLEMY</option>
                                                    <option value="SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA">SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA</option>
                                                    <option value="SAINT KITTS AND NEVIS">SAINT KITTS AND NEVIS</option>
                                                    <option value="SAINT LUCIA">SAINT LUCIA</option>
                                                    <option value="SAINT MARTIN (FRENCH PART)">SAINT MARTIN (FRENCH PART)</option>
                                                    <option value="SAINT PIERRE AND MIQUELON">SAINT PIERRE AND MIQUELON</option>
                                                    <option value="SAINT VINCENT">SAINT VINCENT</option>
                                                    <option value="SAMOA">SAMOA</option>
                                                    <option value="SAN MARINO">SAN MARINO</option>
                                                    <option value="SAO TOME">SAO TOME</option>
                                                    <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                                                    <option value="SENEGAL">SENEGAL</option>
                                                    <option value="SERBIA">SERBIA</option>
                                                    <option value="SEYCHELLES">SEYCHELLES</option>
                                                    <option value="SIERRA LEONE">SIERRA LEONE</option>
                                                    <option value="SINGAPORE">SINGAPORE</option>
                                                    <option value="SINT MAARTEN">SINT MAARTEN</option>
                                                    <option value="SLOVAKIA">SLOVAKIA</option>
                                                    <option value="SLOVENIA">SLOVENIA</option>
                                                    <option value="SOLOMON">SOLOMON ISLANDS</option>
                                                    <option value="SOMALIA">SOMALIA</option>
                                                    <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                                                    <option value="sdi_3bda1afb59414a0591ac0582f74a82c2">SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS</option>
                                                    <option value="SOUTH KOREA">SOUTH KOREA</option>
                                                    <option value="SOUTH SUDAN">SOUTH SUDAN</option>
                                                    <option value="SPAIN">SPAIN</option>
                                                    <option value="SRI LANKA">SRI LANKA</option>
                                                    <option value="SUDAN">SUDAN</option>
                                                    <option value="SURINAME">SURINAME</option>
                                                    <option value="SVALBARD AND JAN MAYEN">SVALBARD AND JAN MAYEN</option>
                                                    <option value="SWAZILAND">SWAZILAND</option>
                                                    <option value="SWEDEN">SWEDEN</option>
                                                    <option value="SWITZERLAND">SWITZERLAND</option>
                                                    <option value="SYRIA">SYRIA</option>
                                                    <option value="TAIWAN">TAIWAN</option>
                                                    <option value="TAJIKISTAN">TAJIKISTAN</option>
                                                    <option value="TANZANIA">TANZANIA</option>
                                                    <option value="THAILAND">THAILAND</option>
                                                    TIMOR-LESTE                                                <option value="TOGO">TOGO</option>
                                                    <option value="TOKELAU">TOKELAU</option>
                                                    <option value="TONGA">TONGA</option>
                                                    <option value="TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                                                    <option value="TUNISIA">TUNISIA</option>
                                                    <option value="TURKEY">TURKEY</option>
                                                    <option value="TURKMENISTAN">TURKMENISTAN</option>
                                                    <option value="TURKS AND CAICOS">TURKS AND CAICOS</option>
                                                    <option value="TUVALU">TUVALU</option>
                                                    <option value="UGANDA">UGANDA</option>
                                                    <option value="UKRAINE">UKRAINE</option>
                                                    <option value="UNITED ARAB EMIRATES">UNITED ARAB EMIRATES</option>
                                                    <option value="UNITED KINGDOM">UNITED KINGDOM</option>
                                                    <option value="UNITED STATES MINOR OUTLYING ISLANDS">UNITED STATES MINOR OUTLYING ISLANDS</option>
                                                    <option value="URUGUAY">URUGUAY</option>
                                                    <option value="UZBEKISTAN">UZBEKISTAN</option>
                                                    <option value="VANUATU">VANUATU</option>
                                                    <option value="VENEZUELA">VENEZUELA</option>
                                                    <option value="VIETNAM">VIETNAM</option>
                                                    <option value="VIRGIN ISLANDS, U.S.">VIRGIN ISLANDS, U.S.</option>
                                                    <option value="WALLIS AND FUTUNA">WALLIS AND FUTUNA</option>
                                                    <option value="WESTERN SAHARA">WESTERN SAHARA</option>
                                                    <option value="YEMEN">YEMEN</option>
                                                    <option value="ZAMBIA">ZAMBIA</option>
                                                    <option value="ZIMBABWE">ZIMBABWE</option>



                                                    {/* *************statename******************** */}

                                                </select></div></div>

                                            <div class="col-md-6"><div class="input-item input-with-label"><label class="input-item-label">Zip Code <span class="text-danger">*</span></label><input class="input-bordered" type="text" onChange={(e) => { setZipCode(e.target.value) }} /></div></div>

                                        </div></div>

                                </div>
                                <div class="form-step form-step2"><div class="form-step-head card-innr"><div class="step-head"><div class="step-number">02</div><div class="step-head-text"><h4>Document Upload</h4><p>Verify your identity</p></div></div></div><div class="form-step-fields card-innr"><div class="note note-plane note-light-alt note-md pdb-0-5x"><em class="fas fa-info-circle"></em><p class='abhimanyu'>To complete the process, please upload any of the following documents.</p></div><div class="gaps-2x"></div>
                                    <ul class="nav nav-tabs nav-tabs-bordered row flex-wrap guttar-20px" role="tablist">
                                        <li class="nav-item flex-grow-0"><a class="nav-link d-flex align-items-center active" data-toggle="tab" href="#passport">
                                            <div class="nav-tabs-icon"><img src={img6} alt="icon" /><img src={img10} alt="icon" />
                                            </div><span class='abhimanyu'>Passport</span></a></li>
                                        <li class="nav-item flex-grow-0"><a class="nav-link d-flex align-items-center" data-toggle="tab" href="#national-card"><div class="nav-tabs-icon"><img src={img9} alt="icon" /><img src={img5} alt="icon" /></div><span class='abhimanyu'>ID Card</span></a></li>
                                        <li class="nav-item flex-grow-0"><a class="nav-link d-flex align-items-center" data-toggle="tab" href="#driver-licence"><div class="nav-tabs-icon"><img src={img7} alt="icon" /><img src={img11} alt="icon" /></div><span class='abhimanyu'>Driver License</span></a></li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="passport">
                                            <h5 class="font-bold deepak">To avoid delays verifying account, please make sure of the following: </h5><ul class="list-check"><li class='abhimanyu'>Chosen credential must not be expired.</li><li class='abhimanyu'>Document be in good condition and clearly visible.</li><li class='abhimanyu'>Please make sure there is no glare when picture is taken.</li></ul><div class="gaps-2x"></div><h5 class="font-mid ">Upload photo of Passport</h5><div class="row align-items-center"><div class="col-sm-8"><div class=""><div class="upload-zone1"><div /* class="dz-message" data-dz-message */><span class="">{/* Drag and drop file */}</span><span class="">{/* or */}</span>{/* <button class="btn btn-primary">SELECT</button> */}<br />{/* <input type="file" id="upload" hidden onChange={onchangeavater} /> */}
                                                <FileUploader
                                                    handleChange={onchangeavater}
                                                    name="file"
                                                    types={fileTypes}
                                                />
                                                <p class='abhimanyu'>{uploadfile ? `File name: ${uploadfile.name}` : "no files uploaded yet"}</p>
                                                {/* &nbsp;&nbsp;&nbsp;<label class='avater' for="upload">Upload File</label> */}
                                                {uploadfile !== '' ? <button class="btn btn-primary" onClick={resetImgepassport}>remove</button> : null}
                                                {/* <div>
                                                    {avater !== "" ? <img src={avater} width='150' height='150' /> : null}
                                                </div> */}

                                            </div></div></div></div><div class="col-sm-4 d-none d-sm-block"><div class="mx-md-4"><img src={img4} alt="vector" /></div></div></div></div>

                                        <div class="tab-pane fade" id="national-card">
                                            <h5 class="font-bold deepak">To avoid delays verifying account, please make sure of the following: </h5><ul class="list-check"><li class='abhimanyu'>Chosen credential must not be expired.</li><li class='abhimanyu'>Document be in good condition and clearly visible.</li><li class='abhimanyu'>Please make sure there is no glare when picture is taken.</li></ul><div class="gaps-2x"></div><h5 class="font-mid">Upload Photo of Identification</h5><div class="row align-items-center"><div class="col-sm-8"><div class="upload-box"><div class="upload-zone1"><div /* class="dz-message" data-dz-message */ ><span class="dz-message-text">{/* Drag and drop file */}</span><span class="dz-message-or">{/* or */}</span>{/* <button class="btn btn-primary">SELECT</button> */}<br />{/* <input type="file" id="upload1" hidden onChange={onchangeavater1} />
                                            &nbsp;&nbsp;&nbsp;<label class='avater' for="upload1">Upload File</label> */}
                                                <FileUploader
                                                    handleChange={onchangeavater1}
                                                    name="file"
                                                    types={fileTypes}
                                                />
                                                <p>{uploadfile1 ? `File name: ${uploadfile1.name}` : "no files uploaded yet"}</p>
                                                {uploadfile1 !== '' ? <button class="btn btn-primary" onClick={resetImageIDCard}>remove</button> : null}
                                                {/* <button onClick={resetImageIDCard}>Reset</button> */}
                                                {/* <div>
                                                {avater1 !== "" ? <img src={avater1} width='150' height='150' /> : null}
                                            </div> */}
                                            </div></div></div></div><div class="col-sm-4 d-none d-sm-block"><div class="mx-md-4"><img src={img3} alt="vector" /></div></div></div><div class="gaps-3x"></div><h5 class="font-mid">Upload backside of ID below</h5><div class="row align-items-center"><div class="col-sm-8"><div class="upload-box"><div class="upload-zone1"><div /* class="dz-message" data-dz-message */><span class="dz-message-text">{/* Drag and drop file */}</span><span class="dz-message-or">{/* or */}</span>{/* <button class="btn btn-primary">SELECT</button> */}<br />

                                                <FileUploader
                                                    handleChange={onchangeavater2}
                                                    name="file"
                                                    types={fileTypes}
                                                />
                                                <p>{uploadfile2 ? `File name: ${uploadfile2.name}` : "no files uploaded yet"}</p>
                                                {/* <button onClick={resetImageIDCard}>Reset</button> */}
                                                {uploadfile2 !== '' ? <button class="btn btn-primary" onClick={resetImageIDCard}>Reset</button> : null}
                                                {/* <div>
                                                {avater2 !== "" ? <img src={avater2} width='150' height='150' /> : null}
                                            </div> */}
                                            </div></div></div></div><div class="col-sm-4 d-none d-sm-block"><div class="mx-md-4"><img src={img2} alt="vector" /></div></div></div></div>

                                        <div class="tab-pane fade" id="driver-licence">
                                            <h5 class="font-bold deepak">To avoid delays verifying account, please make sure of the following: </h5><ul class="list-check"><li class='abhimanyu'>Chosen credential must not be expired.</li><li class='abhimanyu'>Document be in good condition and clearly visible.</li><li class='abhimanyu'>Please make sure there is no glare when picture is taken.</li></ul><div class="gaps-2x"></div><h5 class="font-mid">Upload Photo of Driver License</h5><div class="row align-items-center"><div class="col-sm-8"><div class="upload-box"><div class=""><div /* class="dz-message" data-dz-message */><span class="dz-message-text">{/* Drag and drop file */}</span><span class="dz-message-or">{/* or */}</span>{/* <button class="btn btn-primary">SELECT</button> */}<br />

                                                <FileUploader
                                                    handleChange={onchangeavater3}
                                                    name="file"
                                                    types={fileTypes}
                                                />
                                                <p>{uploadfile3 ? `File name: ${uploadfile3.name}` : "no files uploaded yet"}</p>
                                                {/* <button onClick={resetImageDriving}>Reset</button> */}
                                                {uploadfile3 !== '' ? <button class="btn btn-primary" onClick={resetImageDriving}>Reset</button> : null}
                                                <div>
                                                    {avater3 !== "" ? <img src={avater3} width='150' height='150' /> : null}
                                                </div>

                                            </div></div></div></div><div class="col-sm-4 d-none d-sm-block"><div class="mx-md-4"><img src={img1} alt="vector" /></div></div></div></div>
                                    </div>
                                </div></div>

                                <div class="form-step form-step-final"><div class="form-step-fields card-innr">

                                    <div class="input-item">
                                        <input class="input-checkbox input-checkbox-md" id="info-currect" type="checkbox" onChange={() => setDisable(!disable)} /><label for="info-currect">I hereby declare that all the information entered above is true.</label></div><div class="gaps-1x"></div><button onClick={onSubmit} class="btn btn-primary" disabled={disable}>SUBMIT</button></div></div></div></div></div></div></div>
                <Footer />


                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script></body>
        </div>
    )
}

export default Index
