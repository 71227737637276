import React, { useState, useEffect } from 'react'
import Helmet from "react-helmet"
import Menu from '../menu/index'
import Header from '../header/index'
import img1 from '../../images/logo-light-sm.png'
import axios from 'axios'
import Moment from 'react-moment'
import NumberFormat from 'react-number-format'
import { BASE_URL } from '../../useContext/walletContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../adminFooter/index'
import MambaNetwork from '../../images/MambaNetwork.pdf'


// import NumberFormat from 'react-number-format'
// import Moment from 'react-moment'
function Index() {
    const [inputvalue, setInputValue] = useState()
    const [inputvaluetoken, setInputValuetoken] = useState()
    const [getUSDvalue, setGetUSDvalue] = useState('')
    const [getMATIC, setGetMATIC] = useState(false)
    const [getETH, setGetETH] = useState(false)
    const [getUSD, setGetUSD] = useState(false)
    const [eth, setEth] = useState(false)
    const [maticinputvalue, setmaticinputvalue] = useState(false)
    const [getMATICUSDvalue, setGetMATICUSDvalue] = useState('')
    const [usertoken, setUsertoken] = useState('')
    const [userdetails, setUserdetails] = useState([])
    const [data, setData] = useState([])
    const [token, setToken] = useState([])
    const [newlive,setNewLive] = useState(false)
    const history = useNavigate()
    /* ******************ETH************************* */
    const usdToken = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue((e.target.value / 20000) / getUSDvalue)
    }
    const usdAmount = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getUSDvalue * 20000)
    }
    /* **************************END ETH******************* */
    /* ***************************MATIC ******************* */
    const maticinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue((e.target.value / 20000) / getMATICUSDvalue)
    }
    const mambamaticinput = (e) => {
      
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * getMATICUSDvalue * 20000)
    }
    /* *********************END MATIC***************************** */
    /* **********************USD ********************************* */
    const usdinput = (e) => {
        setInputValuetoken(e.target.value)
        setInputValue(e.target.value / 20000)
    }
    const mambusdnput = (e) => {
        setInputValue(e.target.value)
        setInputValuetoken(e.target.value * 20000)
    }

    /* ***********************END USD*****************************/

    const usdEthgetvalue = (e) => {
        setEth(false)
    }
    const getmaticinput = (e) => {
        setEth(true)
        setmaticinputvalue(true)
       
        getmaticusdpriceforinput()

    }
    const getusdinput = (e) => {
        setEth(true)
        setmaticinputvalue(false)
    }

    const getethinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {
           
            setGetUSDvalue(res?.data?.USD)
        
            // setEth(false)
          
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const getmaticusdpriceforinput = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD').then((res) => {
           
            setGetMATICUSDvalue(res?.data?.USD)
            // setEth(false)
            // console.log('matic')
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    useEffect(() => {
        // gettrancation()
        getusdprice()
        getethinput()
        // getusertoken()
    }, [])

    const getmaticusdprice = async () => {
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD').then((res) => {
         
            setGetMATICUSDvalue(res.data.USD)
            // console.log('matic')
            setGetUSD(true)
            setGetETH(false)
            setGetMATIC(true)
        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }
    const getusdprice = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {
            
            setGetETH(true)
            setGetUSD(true)
            setGetUSDvalue(res?.data?.USD)

        }).catch((error) => {
            console.log(error?.response?.data?.message)
        })
    }

    const getusd = () => {
        setGetETH(false)
        setGetUSD(false)
        setGetMATIC(false)
    }

    /* *********************userList************************************** */
    const getUserlist = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getadminuser`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            setUserdetails(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }

    /* *********************End userList************************************** */
    /* *********************Transaction List************************************** */
    const gettrancation = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/tsxndetailsforadmin`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
            
            setData(res?.data?.result.reverse())
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    /* *********************End Transaction List************************************** */
    /* *******************************get total mamba,ETH,MAtic,USD ************************** */
    const gettoken = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        axios.get(`${BASE_URL}/api/getadmintotalsoldtoken`, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
          
            setToken(res?.data?.result)
        }).catch((err) => {
            console.log(err?.response?.data?.message)
        })
    }
    /* *******************************get total mamba,ETH,MAtic,USD ************************** */
    useEffect(() => {
        getUserlist()
        gettrancation()
        gettoken()
    }, [])

    const goTransaction = () => {
        history('/Transcation')
    }
    const goUserList = () => {
        history('/UserList')
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <body class="page-user">
                <div class="topbar-wrap">
                    <div class="topbar is-sticky">
                        <div class="container">
                            <Menu />

                        </div>
                    </div>
                    <Header />

                </div>
                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="token-statistics card card-token height-auto">
                                    <div class="card-innr">
                                        <div class="token-balance token-balance-with-icon">
                                            <div class="token-balance-icon"><img src={img1} alt="logo" /></div>
                                            <div class="token-balance-text">
                                                <h6 class="card-sub-title">Total Token Sold:</h6>
                                                <span class="lead">
                                                    <NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.mambaToken === null ? 0 : token[0]?.mambaToken ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                    <span>&nbsp;MAMBA</span></span>
                                            </div>
                                        </div>
                                        <div class="token-balance token-balance-s2">
                                            <h6 class="card-sub-title">Total Contribution Received:</h6>
                                            <ul class="token-balance-list">
                                                <li class="token-balance-sub"><span class="lead">
                                                    <NumberFormat style={{ fontSize: '0.9rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.ethAmount === null ? 0 : parseFloat(token[0]?.ethAmount).toFixed(5) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                </span><span class="sub">ETH</span></li>
                                                <li class="token-balance-sub"><span class="lead">
                                                    <NumberFormat style={{ fontSize: '0.9rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.maticAmount}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} /></span><span class="sub">MATIC</span></li>
                                                <li class="token-balance-sub" style={{ paddingLeft: '5px' }}><span class="lead">
                                                    <NumberFormat style={{ fontSize: '0.9rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.usdAmount}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />
                                                </span><span class="sub">USD</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="token-information card card-full-height">
                                    <div class="row no-gutters height-100">
                                        <div class="col-md-6 text-center">
                                            {getETH ? <><div class={`token-info ${newlive ? "show":""}`}>
                                                <div class="gaps-2x"></div>
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {setNewLive(!newlive)}} >
                                                1 ETH
        </a>
        <div class={`dropdown-menu ${newlive ? "show":""}`} aria-labelledby="navbarDropdown" style={{background:"#050613"}}>
                                                
                                                    <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={getusdprice} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticusdprice} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusd} class='linkactive'>USD</a></li>
                                                    </ul>
                                                </div>
                                                <h1>= <NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getUSDvalue * 20000}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />  <span class="lead">MAMBA</span></h1>

                                                <h5 class="token-info-sub">1 ETH = <NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getUSDvalue}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />  USD</h5>
                                            </div> </> : <><div class="token-info">
                                                <div class="gaps-2x"></div>
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                1 {getMATIC ? "MATIC" : "USD"}
        </a>
                                                {/* <h1 class="token-info-head text-light toggle-tigger toggle-caret">1 {getMATIC ? "MATIC" : "USD"} </h1> */}
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown" style={{background:"#050613"}}>

                                                    <ul class="dropdown-list" style={{ cursor: 'pointer' }}>
                                                        <li><a onClick={getusdprice} class='linkactive'>ETH</a></li>
                                                        <li><a onClick={getmaticusdprice} class='linkactive'>MATIC</a></li>
                                                        <li><a onClick={getusd} class='linkactive'>USD</a></li>
                                                    </ul>
                                                </div>
                                                {getUSD ? <><h1>=<NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getMATICUSDvalue * 20000}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />   <span class="lead">MAMBA</span></h1></> : <><h1>=  20000 <span class="lead">MAMBA</span></h1></>}

                                                {getUSD ? <h5 class="token-info-sub">1 {getMATIC ? "MATIC" : "USD"}=<NumberFormat
                                                    thousandsGroupStyle="thousand"
                                                    // format="$#########0"
                                                    value={getMATICUSDvalue}
                                                    prefix=""
                                                    decimalSeparator="."
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    suffix={0} />   USD</h5> : null}
                                            </div> </>}
                                        </div>
                                        <div class="col-md-6">
                                            <div class="token-info bdr-tl">
                                                <div>
                                                    <ul class="token-info-list">
                                                        <li><span>Token Name:</span>MambaVerse</li>
                                                        <li><span>Token Symbol:</span>MAMBA</li>
                                                        <li><span>Token Supply:</span>200 Billion</li>
                                                        <li><span>Total Token Sold:</span>&nbsp;<NumberFormat style={{ fontSize: '1.2rem' }}
                                                        thousandsGroupStyle="thousand"
                                                        // format="$#########0"
                                                        value={token[0]?.mambaToken === null ? 0 : parseFloat(token[0]?.mambaToken).toFixed(2) ?? 0}
                                                        prefix=""
                                                        decimalSeparator="."
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                        allowNegative={true}
                                                        suffix={0} />&nbsp;MAMBA</li>
                                                    </ul>
                                                    {/* <a href={MambaNetwork} class="btn btn-primary" download><em class="fas fa-download mr-3"></em>Download Whitepaper</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div class="col-lg-8">
                                <div class="token-information card card-full-height">
                                    <div class="row no-gutters height-100">
                                        <div class="col-md-6 text-center">
                                            <div class="token-info">
                                                <img class="token-info-icon" src="images/logo-sm.png" alt="logo-sm" />
                                                <div class="gaps-2x"></div>
                                                <h1 class="token-info-head text-light">1 ETH = 1000 TWZ</h1>
                                                <h5 class="token-info-sub">1 ETH = 254.05 USD</h5>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="token-info bdr-tl">
                                                <div>
                                                    <ul class="token-info-list">
                                                        <li><span>Token Name:</span>MambaVerse</li>
                                                        <li><span>Ticket Symbol:</span>MAMBA</li>
                                                    </ul>
                                                    <a href="#" class="btn btn-primary"><em class="fas fa-download mr-3"></em>Download Whitepaper</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="col-lg-6">
                                <div class="card card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head has-aside pb-0">
                                            <h4 class="card-title">Recent Users</h4>
                                        </div>
                                        <table class="data-table user-list">
                                            <thead>
                                                <tr class="data-item data-head">
                                                    <th class="data-col dt-user">Name</th>
                                                    <th class="data-col dt-email">Email</th>
                                                    <th class="data-col dt-token">registration   Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userdetails.slice(0, 10).map((ele, index) => {
                                                    return (
                                                        <>
                                                            <tr class="data-item">
                                                                <td class="data-col dt-user">
                                                                    <div class="user-block">
                                                                        {/* <div class="user-photo">
                                                                            <img src="images/user-a.jpg" alt="" />
                                                                        </div> */}
                                                                        <div class="user-info"><span class="lead user-name">{ele.fullName}</span>{/* <span class="sub user-id">UD102001</span> */}</div>
                                                                    </div>
                                                                </td>
                                                                <td class="data-col dt-join"><span class="sub join-time">{ele.email}</span></td>
                                                                <td class="data-col dt-join"><span class="sub join-time"><Moment format="MM-DD-YYYY HH:mm" date={new Date(ele.createdAt)} /></span></td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}

                                            </tbody>
                                        </table><div class="card-opt text-right">
                                            <a onClick={goUserList} class="link ucap" style={{ cursor: 'pointer' }}>View ALL <em class="fas fa-angle-right ml-2"></em></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head has-aside pb-0">
                                            <h4 class="card-title">Recent Users</h4>
                                        </div>
                                        {data.length !== 0 ? <table class="table tnx-table">
                                            <thead>
                                                <tr>
                                                    <th>MAMBA</th>
                                                    <th>Date</th>
                                                    <th class="d-none d-sm-table-cell tnx-date">Amount</th>

                                                    <th class="tnx-type"><div class="tnx-type-text"></div> Transaction Type</th>
                                                </tr>

                                            </thead>

                                            <tbody>
                                                {data.slice(0, 10).map((ele, index) => {
                                                    const fromfirst = ele.transactionHash.substring(0, 4)
                                                    const fromlast = ele.transactionHash.substring(ele.transactionHash.length - 4)
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        {ele.transactionStats === 'Pending' && ele.transactionHash !== '' ? <div class="data-state data-state-progress"><span class="d-none">Progress</span></div> : ele.transactionStats === 'Accepted' ? <div class="data-state data-state-approved"><span class="d-none">Approved</span></div> : ele.transactionHash === '' ? <div class="data-state data-state-pending"><span class="d-none">Pending</span></div> : <div class="data-state data-state-canceled"><span class="d-none">Canceled</span></div>}<span class="lead"><NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            // format="$#########0"
                                                                            value={ele.mambaToken}
                                                                            prefix=""
                                                                            decimalSeparator="."
                                                                            displayType="text"
                                                                            type="text"
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            suffix={0} /> </span></div></td>

                                                                <td>
                                                                    <span><span class="lead"><Moment format="MM-DD-YYYY" date={new Date(ele.createdAt)} /></span></span></td>

                                                                <td class="d-none d-sm-table-cell tnx-date"><span class="sub sub-s2">{/* {fromfirst}...{fromlast} */}{ele.ethAmount != null ? ele.ethAmount : ele.maticAmount != null ? ele.maticAmount : ele.usdAmount}</span></td>

                                                                <td class="tnx-type"><span class="tnx-type-md badge badge-outline badge-success badge-md abhimanyu">Purchase</span><span class="tnx-type-sm badge badge-sq badge-outline badge-success badge-md">P</span></td>

                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                )}
                                            </tbody>

                                        </table> : <table class="table tnx-table">
                                            <thead>
                                                <tr>
                                                    <th>MAMBA</th>
                                                    <th>Date</th>
                                                    <th class="d-none d-sm-table-cell tnx-date">Transaction</th>
                                                    <th class="tnx-type"><div class="tnx-type-text"></div> Transaction Type</th>
                                                </tr>

                                            </thead>
                                            <td></td>
                                            <td></td>
                                            <td class='text-center' style={{ textAlign: 'center' }}><card >No Transactions Available
                                                {/* <div class="token-buy">
                                                    <a onClick={transaction} class="btn btn-primary">Buy Tokens</a>
                                                    </div> */}
                                            </card></td>
                                            <td></td>

                                        </table>}
                                        <div class="card-opt text-right">
                                            <a onClick={goTransaction} class="link ucap" style={{ cursor: 'pointer' }}>View ALL <em class="fas fa-angle-right ml-2"></em></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-6">
                                <div class="card card-timeline card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head has-aside">
                                            <h4 class="card-title">Notifications</h4>
                                        </div>
                                        <div class="timeline-wrap" id="timeline-notify">
                                            <div data-simplebar="init">
                                                <div class="timeline-innr">
                                                    <div class="timeline">
                                                        <div class="timeline-line"></div>
                                                        <div class="timeline-item">
                                                            <div class="timeline-time">09:30 AM</div>
                                                            <div class="timeline-content">
                                                                <p>Each member have a unique TWZ referral link to share with friends and family and receive a bonus - 15% of the value of their contribution.</p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item success">
                                                            <div class="timeline-time">12:27 AM</div>
                                                            <div class="timeline-content">
                                                                <div class="chat-users">
                                                                    <div class="chat-users-stack">
                                                                        <div class="chat-avatar circle"><img src="images/user-a.jpg" alt="" /></div>
                                                                        <div class="chat-avatar circle"><img src="images/user-b.jpg" alt="" /></div>
                                                                        <div class="chat-avatar circle"><img src="images/user-c.jpg" alt="" /></div>
                                                                    </div>
                                                                    <span>+14</span>
                                                                </div>
                                                                <span class="timeline-content-info">New User Added</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item secondary">
                                                            <div class="timeline-time">07:45 PM</div>
                                                            <div class="timeline-content"><a href="#" class="timeline-content-url">Now You can send payment directly to our address or you may pay online</a><span class="timeline-content-info">New Article on Blog</span></div>
                                                        </div>
                                                        <div class="timeline-item secondary">
                                                            <div class="timeline-time">07:45 PM</div>
                                                            <div class="timeline-content">
                                                                <p>500 TWZ referral bonues added</p>
                                                                <span class="timeline-content-info">into @Abc</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden success">
                                                            <div class="timeline-time">03:45 PM</div>
                                                            <div class="timeline-content">
                                                                <h5 class="timeline-content-title">Get bonus - 15% of the value of your contribution</h5>
                                                                <span class="timeline-content-info">Latest Offers</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden light">
                                                            <div class="timeline-time">02:30 PM</div>
                                                            <div class="timeline-content">
                                                                <p>Each member have a unique TWZ referral link to share with friends and family and receive a bonus - 15% of the value of their contribution.</p>
                                                                <span class="timeline-content-info">Announcements</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden light">
                                                            <div class="timeline-time">02:30 PM</div>
                                                            <div class="timeline-content">
                                                                <p>Each member have a unique TWZ referral link to share with friends and family and receive a bonus - 15% of the value of their contribution.</p>
                                                                <span class="timeline-content-info">Announcements</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden danger">
                                                            <div class="timeline-time">12:17 AM</div>
                                                            <div class="timeline-content">
                                                                <p>You can buy our TWZ tokens using ETH, BTC, LTC or USD.</p>
                                                                <span class="timeline-content-info">Announcements</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden primary">
                                                            <div class="timeline-time">09:31 AM</div>
                                                            <div class="timeline-content">
                                                                <div class="chat-users">
                                                                    <div class="chat-users-stack">
                                                                        <div class="chat-avatar circle"><img src="images/user-a.jpg" alt="" /></div>
                                                                        <div class="chat-avatar circle"><img src="images/user-c.jpg" alt="" /></div>
                                                                        <div class="chat-avatar circle"><img src="images/user-b.jpg" alt="" /></div>
                                                                        <div class="chat-avatar circle"><img src="images/user-d.jpg" alt="" /></div>
                                                                    </div>
                                                                    <span>+122</span>
                                                                </div>
                                                                <span class="timeline-content-info">New User Added</span>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item hidden warning">
                                                            <div class="timeline-time">08:57 AM</div>
                                                            <div class="timeline-content">
                                                                <p>Enter your amount, you would like to contribute and calculate the amount of token you will received. To get tokens please make a payment. You can send payment directly to our address or you may pay online. Once you paid, you will receive an email about the successfull deposit.</p>
                                                                <span class="timeline-content-info">Announcements</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="timeline-load"><a href="#" class="link load-timeline" data-target="timeline-notify" data-show="2">Load More</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="col-lg-6">
                                <div class="token-transaction card card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head has-aside">
                                            <h4 class="card-title">Transaction</h4>
                                            <div class="card-opt"><a href="transactions.html" class="link ucap">View ALL <em class="fas fa-angle-right ml-2"></em></a></div>
                                        </div>
                                        <table class="table tnx-table">
                                            <thead>
                                                <tr>
                                                    <th>TWZ Tokens</th>
                                                    <th>Amount</th>
                                                    <th class="d-none d-sm-table-cell tnx-date">Date</th>
                                                    <th class="tnx-type">
                                                        <div class="tnx-type-text"></div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="data-state data-state-pending"></div>
                                                            <span class="lead">18,750</span>
                                                        </div>
                                                    </td>
                                                    <td><span><span class="lead">3.543</span><span class="sub">ETH <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" data-original-title="1 ETH = 590.54 USD"></em></span></span></td>
                                                    <td class="d-none d-sm-table-cell tnx-date"><span class="sub sub-s2">2018-08-24 10:20 PM</span></td>
                                                    <td class="tnx-type"><span class="tnx-type-md badge badge-outline badge-success badge-md">Purchase</span><span class="tnx-type-sm badge badge-sq badge-outline badge-success badge-md">P</span></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="data-state data-state-progress"></div>
                                                            <span class="lead">8,052</span>
                                                        </div>
                                                    </td>
                                                    <td><span><span class="lead">0.165</span><span class="sub">BTC <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" data-original-title="1 BTC = 5450.54 USD"></em></span></span></td>
                                                    <td class="d-none d-sm-table-cell tnx-date"><span class="sub sub-s2">2018-08-24 10:20 PM</span></td>
                                                    <td class="tnx-type"><span class="tnx-type-md badge badge-outline badge-warning badge-md">Bonus</span><span class="tnx-type-sm badge badge-sq badge-outline badge-warning badge-md">B</span></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="data-state data-state-approved"></div>
                                                            <span class="lead">19,000</span>
                                                        </div>
                                                    </td>
                                                    <td><span><span class="lead">3.141</span><span class="sub">LTC <em class="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" data-original-title="1 LTC = 180.54 USD"></em></span></span></td>
                                                    <td class="d-none d-sm-table-cell tnx-date"><span class="sub sub-s2">2018-08-24 10:20 PM</span></td>
                                                    <td class="tnx-type"><span class="tnx-type-md badge badge-outline badge-warning badge-md">Bonus</span><span class="tnx-type-sm badge badge-sq badge-outline badge-warning badge-md">B</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="token-sale-graph card card-full-height">
                                    <div class="card-innr">
                                        <div class="card-head has-aside">
                                            <h4 class="card-title">Tokens Sale Graph</h4>
                                            <div class="card-opt">
                                                <a href="#" class="link ucap link-light toggle-tigger toggle-caret">7 Days</a>
                                                <div class="toggle-class dropdown-content">
                                                    <ul class="dropdown-list">
                                                        <li><a href="#">30 days</a></li>
                                                        <li><a href="#">1 years</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chart-tokensale">
                                            <canvas id="tknSale"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><Footer />

                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script><script src="assets/js/script49f7.js?ver=104"></script>
            </body>
        </div>
    )
}
export default Index