import React, { createContext, useState } from 'react'

const StoreContext = createContext()

const StoreProvider = (props) => {

    const [headerTitle, setHeaderTitle] = useState(false)

    return (
        <StoreContext.Provider value={{
            headerTitle,
            setHeaderTitle

        }} >
            {props.children}
        </StoreContext.Provider>
    )

}


export { StoreContext, StoreProvider }

export const BASE_URL = "https://api987api7.mambapayments.com"