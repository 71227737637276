import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import axios from 'axios'
import { BASE_URL } from '../../useContext/walletContext'


function Index() {
    const history = useNavigate()
    let location = useLocation();
    const [tsnxmanual, setTsnxmanual] = useState('')
    const [sendermanual, setSendermanual] = useState('')

  
    const orderID = location.pathname.split('/').pop()
 

    const transcationdetailsManualupdate = async () => {
        const sessiontoken = await window.sessionStorage.getItem('Token')

        const data = {
            transactionHash: tsnxmanual,
            senderID: sendermanual
        }
        axios.put(`${BASE_URL}/api/transactionmaunalupdate/${orderID}`, data, {
            headers: {
                userid: sessiontoken
            }
        }).then((res) => {
           
            NotificationManager.success(" Successfully payment")
            history('/Transactions')
            // setOrderID(res.data.result.orderID)
           
            // setTimeout(function () { handleShowmanual() }, 2000);
        }).catch((error) => {
            console.log(error?.response?.data?.message)
            // NotificationManager.error(error?.response?.data?.message ?? "Somethink Wrong")
        })
    }
    return (
        <div class='content-area card text-center'>
            <div class="card-innr">
                <div class="popup-body"><h4 class="popup-title">Confirm Your Payment</h4><p class="lead abhimanyu">Your Order no. <strong class='deepak'>OD{orderID}</strong> has been placed successfully. </p><p>The tokens will be send to your wallet only after your transaction gets confirmed and approved by our team.</p><p>To <strong>speed up verifcation</strong> process please enter <strong>your wallet address</strong> from where you’ll transferring your ethereum to our address.</p><div class="input-item input-with-label">
                    <label for="token-address" class="input-item-label deepak">Enter your wallet address</label>
                    <input class="input-bordered" onChange={(e) => setSendermanual(e.target.value)} />

                    <label for="token-address " class="input-item-label deepak  mt-2">Your Txn Hash
                    </label>
                    <input class="input-bordered" type='text' onChange={(e) => setTsnxmanual(e.target.value)} />

                </div>
                    <ul class="d-flex flex-wrap align-items-center guttar-30px">
                        <li><a href="#" /* data-dismiss="modal" data-toggle="modal" data-target="#pay-review" */ onClick={transcationdetailsManualupdate} class="btn btn-primary">Confirm Payment</a></li><li class="pdt-1x pdb-1x">
                            {/* <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#pay-online"
                        class="link link-primary">Make Online Payment</a> */}
                        </li></ul>
                    <div class="gaps-2x"></div><div class="gaps-1x d-none d-sm-block"></div>
                    {/* <div class="note note-plane note-light mgb-1x"><em class="fas fa-info-circle"></em><p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p></div><div class="note note-plane note-danger"><em class="fas fa-info-circle"></em><p>In case you send a different amount, number of MAMBAtokens will update accordingly.</p></div> */}
                </div>
            </div>
        </div>
    )
}

export default Index
