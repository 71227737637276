import React from 'react'
import Helmet from "react-helmet"

function Index() {

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <script src="assets/js/jquery.bundle49f7.js?ver=104"></script>
                <script src="assets/js/script49f7.js?ver=104"></script>
            </Helmet>
            <div class="token-sales card"><div class="card-innr"><div class="card-head"><h5 class="card-title card-title-sm">Initial Direct Offering</h5></div><div class="token-rate-wrap row"><div class="token-rate col-md-6 col-lg-12"><span class="card-sub-title abhimanyu">MAMBA Price</span><h5 class="font-mid abhimanyu">1 USD = <span>20,000 MAMBA</span></h5></div>
               
            </div>
               
            </div>
                <div class="sap"></div>
               
            </div>
        </div>
    )
}

export default Index
